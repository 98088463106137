import { TextField } from '@mui/material'
import { useState } from 'react'
import { containsContactInfo } from '../../utils'
import NextButton from './NextButton'

export default ({ comment, setComment, handleSubmit }) => {
  const [commentError, setCommentError] = useState(false)

  return (
    <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
      <div className='sm:w-1/2'>
        <p className='text-start mb-6'>Agregá algún comentario sobre el viaje</p>
        <TextField
          id='comment'
          multiline
          maxRows={4}
          onChange={evt => {
            if (containsContactInfo(evt.target.value)) {
              setCommentError(true)
            } else {
              setCommentError(false)
            }
            setComment(evt.target.value)
          }}
          placeholder='Ejemplo: Hay espacio para 2 valijas chicas y el horario es flexible.'
          sx={{ width: '100%' }}
          minRows={4}
          value={comment}
          variant='filled'
          InputProps={{
            disableUnderline: true,
            style: { borderRadius: 10, paddingTop: 10, paddingBottom: 10 }
          }}
          inputProps={{
            maxLength: 100,
            style: {
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500
            }
          }}
        />
        {commentError && (
          <p className='text-sm text-start text-[#ef4444] mt-3 ml-1'>
            No puede contener información de contacto
          </p>
        )}
        <NextButton onSubmit={handleSubmit} disabled={containsContactInfo(comment)} />
      </div>
    </div>
  )
}
