import Alert from '../../commons/Alert'
import apiConfig from '../../config/Api'
import { useFetchEditUser } from '../../hooks'
import SearchError from '../../commons/SearchError'
import { useAuth } from '../../hooks/'
import { Mixpanel } from '../../Mixpanel'
import { useEffect } from 'react'
import LoadingIndicator from '../../commons/LoadingIndicator'

const EditPicture = ({ visible, setVisible, picture, setProfileImg, setAvatarImage }) => {
  const { user } = useAuth()
  const { loading, response } = useFetchEditUser(`${apiConfig.uri}/users/me/picture`, {
    method: 'PATCH',
    body: JSON.stringify({
      image: picture,
    }),
  })

  useEffect(() => {
    if (!loading && response.ok) {
      response.json().then(data => setProfileImg(data.picture))
      Mixpanel.track('Foto de perfil editada correctamente', {
        'Usuario': user,
      })
    } else if (!loading && !response.ok) {
      if (setAvatarImage) setAvatarImage('')
      Mixpanel.track('Error al editar foto de perfil', {
        'Usuario': user,
        'Error': response.status
      })
    }
  }, [loading])

  return (
    <Alert
      visible={visible} onClose={() => setVisible(false)}
      hideButton={loading}
    >
      {
        loading ?
          <div className='flex flex-col items-center'>
            <LoadingIndicator />
          </div>
          :
          response.ok ?
            <div>Información cargada con éxito!</div>
            :
            <div className='mt-24'>
              <SearchError
                text='Error al cargar imagen, porfavor intenta nuevamente con otra. Ante cualquier duda contáctate con nosotros.'
              />
            </div>
      }
    </Alert>
  )
}

export default EditPicture