import { useEffect } from 'react'
import { uri } from '../../config/Api'
import { useAuthorizedFetch } from '../../hooks'

export default ({ setChannels, setRefetchChannels }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/users/me/channels`)

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setChannels(data)
        setRefetchChannels(false)
      })
    }
  }, [loading])

  return null
}