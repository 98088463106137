import { useState } from "react"
import { MultiSelect, Option } from "react-rainbow-components"
import {
  PetsOutlined,
  SmokingRoomsOutlined,
  MusicNoteOutlined,
  Bedtime,
  PeopleRounded,
  Girl
} from "@mui/icons-material"
import { useEffect } from "react"

const Preferences = ({ preferences, setPreferences, readOnly, gender, placeholder }) => {
  const [values, setValues] = useState(preferences?.map(preference => { return { name: preference, value: preference, label: label(preference) } }))

  useEffect(() => {
    if (values) {
      setPreferences(values.map(value => value.name))
    }
  }, [values])

  return (
    <MultiSelect
      placeholder={placeholder ? placeholder : 'Selecciona tus preferencias'}
      value={values}
      onChange={setValues}
      style={{ maxWidth: 400 }}
      variant='chip'
      showCheckbox
      readOnly={readOnly}
    >
      <Option value='pets' name='pets' label='Pet Friendly' icon={<PetsOutlined />} />
      <Option value='smoke' name='smoke' label='Fumar' icon={<SmokingRoomsOutlined />} />
      {/* <Option value='option3' name='option3' label='No Fumar' icon={<SmokeFreeOutlined/>} /> */}
      <Option value='music' name='music' label='Con Música' icon={<MusicNoteOutlined />} />
      {/* <Option value='option5' name='option5' label='Sin Música' icon={<MusicOffOutlined/>} /> */}
      <Option value='conversation' name='conversation' label='Charlar' icon={<PeopleRounded />} />
      <Option value='sleep' name='sleep' label='Dormir' icon={<Bedtime />} />
      {
        gender != 'Hombre' ?
          <Option value='girls' name='girls' label='Viajar con mujeres' icon={<Girl />} />
          :
          <></>
      }
    </MultiSelect>
  )
}

const label = (name) => {
  switch (name) {
    case 'pets':
      return 'Pet Friendly'
    case 'smoke':
      return 'Fumar'
    case 'music':
      return 'Con Música'
    case 'conversation':
      return 'Charlar'
    case 'sleep':
      return 'Dormir'

    default:
      return ''
  }
}

export default Preferences