import { useEffect, useState } from 'react'
import { useAuth, useLocation } from './hooks/'
import SearchLobby from './pages/SearchLobby'
import CreateLobby from './pages/CreateLobby'
import Register from './components/Register'
import auth0Config, { namespace } from './config/Auth0'
import { Mixpanel } from './Mixpanel'
import DrawerRef from './components/DrawerRef'
import ProfileInfo from './components/ProfileInfo'
import UserLobbies from './components/UserLobbies'
import ShowLobby from './components/ShowLobby'
import LoadingScreen from './components/LoadingScreen'
import Messages from './components/Messages'
import JoinSuccess from './components/JoinSuccess'
import Review from './components/Review'
import BusVerifyPayment from './components/BusVerifyPayment'
import UserTickets from './components/UserTickets'
import ShowBus from './components/ShowBus'
import NavigationBar from './components/NavigationBar'
import UserInfo from './components/UserInfo'

function App() {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth()
  const [alertValue, setAlertValue] = useState(false)
  const [navigation, setNavigation] = useState('SearchLobby')
  const [company, setCompany] = useState('')
  const [RegisterVisible, setRegisterVisible] = useState(false)
  const [profile, setProfile] = useState(false)
  const [trips, setTrips] = useState(false)
  const [tickets, setTickets] = useState(false)
  const [messages, setMessages] = useState(false)
  const [profileImg, setProfileImg] = useState('')
  const [disabledMenu, setDisabledMenu] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded') || localStorage.getItem('embedded')
  const showTickets = localStorage.getItem('purchased')
  const { pathname } = useLocation()
  const { route, id } = getRoute()
  const [userInfoId, setUserInfoId] = useState(id)

  useEffect(() => {
    Mixpanel.trackIp()
    if (params.get('navigation')) {
      setNavigation(params.get('navigation'))
    }
    if (params.get('company')) {
      setCompany(params.get('company'))
    }
    if (params.get('mixpanelId')) {
      Mixpanel.identify(params.get('mixpanelId'))
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      setProfileImg(user?.picture)
      if (!user?.[auth0Config.namespace + 'registered'] && !embedded) {
        setRegisterVisible(true)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (showTickets) {
      if (!embedded) {
        setTickets(true)
      }
      localStorage.removeItem('purchased')
    }
  }, [showTickets])

  useEffect(() => {
    if (isAuthenticated && user) {
      Mixpanel.alias(user?.sub, Mixpanel.getDistinctId())
      Mixpanel.people.set({
        _id_viatik: user?.sub,
        $first_name: user?.[namespace + 'first_name'],
        $last_name: user?.[namespace + 'last_name'],
        $email: user?.email
      })
      if (params.get('referedBy')) {
        Mixpanel.people.set({
          refered_by: params.get('referedBy'),
          refered_date: new Date().toISOString()
        })
      }
    }
  }, [isAuthenticated, user])

  useEffect(() => {
    if (!isAuthenticated) {
      if (route == 'login') {
        loginWithRedirect({
          appState: window.location.origin + window.location.search
        })
      }
      if (route == 'register') {
        loginWithRedirect({
          appState: window.location.origin + window.location.search,
          screen_hint: 'signup'
        })
      }
    }
  }, [isAuthenticated, route])

  useEffect(() => {
    if (!isAuthenticated && params.get('referedBy')) {
      Mixpanel.track('Referral', { Referrer: params.get('referedBy') })
    }
  }, [])

  useEffect(() => {
    if (route === 'messages') {
      setMessages(true)
    } else if (route === 'user') {
      setUserInfo(true)
    } else if (route === 'trips' || route === 'user-lobby') {
      setTrips(true)
    } else if (route === 'create') {
      setNavigation('CreateLobby')
    }
  }, [route])

  useEffect(() => {
    if (pathname?.includes('/messages')) {
      setMessages(true)
      setUserInfo(false)
    } else if (pathname === '/profile') {
      setProfile(true)
    } else if (pathname === '/trips') {
      setTrips(true)
    } else if (pathname === '/tickets') {
      setTickets(true)
    } else if (pathname?.includes('/user')) {
      setUserInfo(true)
      setUserInfoId(pathname.split('/')[2])
    } else if (pathname === '/create') {
      setNavigation('CreateLobby')
    } else if (pathname === '/') {
      setMessages(false)
      setProfile(false)
      setTrips(false)
      setTickets(false)
      setUserInfo(false)
    }
  }, [pathname])

  if (isLoading) return <LoadingScreen />

  return (
    <>
      {navigation === 'BusVerifyPayment' ? (
        <BusVerifyPayment />
      ) : navigation === 'JoinSuccess' ? (
        <div className='w-full h-full'>
          <JoinSuccess />
        </div>
      ) : (
        <div className='w-full h-full flex flex-col'>
          {!embedded && (
            <NavigationBar
              navigation={navigation}
              setProfile={setProfile}
              setAlertValue={setAlertValue}
              setTrips={setTrips}
              setTickets={setTickets}
              setMessages={setMessages}
              disabled={disabledMenu}
            />
          )}
          {!window.location.pathname.includes('messages') && (
            <div className='h-full w-full bg-white'>
              {navigation === 'SearchLobby' && !isLoading ? (
                <SearchLobby
                  initialValues={JSON.parse(params.get('searchData'))}
                  setDisabledMenu={setDisabledMenu}
                />
              ) : (
                (navigation === 'CreateLobby' || route === 'create') && (
                  <CreateLobby initialValues={JSON.parse(params.get('createData'))} />
                )
              )}
            </div>
          )}
          {((route == 'join' && !isAuthenticated) ||
            (route == 'join' &&
              isAuthenticated &&
              user?.[auth0Config.namespace + 'registered'])) && (
            <ShowLobby
              lobbyToShow={id}
              seats={params.get('seats') || 1}
              start={params.get('start')}
              end={params.get('end')}
            />
          )}
          {((route == 'join-bus' && !isAuthenticated) ||
            (route == 'join-bus' &&
              isAuthenticated &&
              user?.[auth0Config.namespace + 'registered'])) && (
            <ShowBus busToShow={id} seats={params.get('seats') || 1} />
          )}
          <DrawerRef
            profileImg={profileImg}
            navigation={navigation}
            setProfile={setProfile}
            alertValue={alertValue}
            setAlertValue={setAlertValue}
            setTrips={setTrips}
            setTickets={setTickets}
            setMessages={setMessages}
          />
          {RegisterVisible ? ( //Se hace de esta forma para que en el html aparezca arriba de todos los demas componentes y modales
            <Register
              visible={RegisterVisible}
              onSubmit={() => {
                setRegisterVisible(false)
              }}
              setProfileImg={setProfileImg}
            />
          ) : (
            <></>
          )}
          {profile && (
            <ProfileInfo visible={profile} setVisible={setProfile} setProfileImg={setProfileImg} />
          )}
          {trips && <UserLobbies visible={trips} setVisible={setTrips} initialLobby={id} />}
          {tickets && <UserTickets visible={tickets} setVisible={setTickets} />}
          {messages && (
            <Messages
              visible={messages}
              setVisible={setMessages}
              embedded={embedded}
              hideHeader={params.get('hide-header')}
              initialChannel={id}
            />
          )}
          {isAuthenticated && !embedded && <Review />}
          {userInfo && (
            <UserInfo
              user={userInfoId}
              visible={userInfo}
              onClose={() => {
                window.history.back()
                setUserInfo(false)
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

const getRoute = () => {
  const path = window.location.pathname
  const segments = path.split('/')

  const route = segments[1]
  const id = segments[2]

  return { route, id }
}

export default App
