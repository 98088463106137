import { Spinner, Bounce } from 'react-activity'
import 'react-activity/dist/Spinner.css'
import 'react-activity/dist/Bounce.css'

const LoadingIndicator = ({ color, size, style, type }) => {
  if (type === 'bounce') {
    return <Bounce color={color ?? '#d65944'} size={size ?? 24} style={style} />
  } else {
    return <Spinner color={color ?? '#d65944'} size={size ?? 24} style={style} />
  }
}

export default LoadingIndicator
