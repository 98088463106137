const styles = {
  fullScreen: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 0,
    },
    overlay: {
      zIndex: 40,
      backgroundColor: 'rgba(0,0,0,0)',
    }
  },
  alert: {
    content: {
      borderRadius: '1.5rem',
      maxHeight: 460,
      maxWidth: 600,
      margin: 'auto',
      paddingTop: '2.5rem',
      paddingLeft: '2rem'
    },
    overlay: {
      zIndex: 400,
      backgroundColor: 'rgba(0,0,0,0)',
    }
  }
}

export default styles