import { mdiBusStop } from "@mdi/js"
import Icon from "@mdi/react"
import { PersonRounded } from "@mui/icons-material"

export default ({ passengers, boardingStation }) => {
  return (
    <div className='flex flex-row justify-between w-full items-center px-6'>
      <div className='flex flex-row gap-2 items-center justify-center'>
        <Icon path={mdiBusStop} size={1.2} color='#d65944' />
        <p className='text-base'>{boardingStation}</p>
      </div>
      <div className='flex flex-row gap-2 items-center'>
        <PersonRounded sx={{ color: '#d65944' }} />
        <p className='text-base'>{passengers}</p>
      </div>
    </div>
  )
}