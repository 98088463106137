import { createTheme, ThemeProvider } from '@mui/material'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

const theme = createTheme({
  typography: {
    fontFamily: {
      Poppins: 'Poppins, sans-serif'
    },
    fontSize: 28
  }
})

const theme2 = createTheme({
  typography: {
    fontFamily: {
      Poppins: 'Poppins, sans-serif'
    },
    fontSize: 12
  }
})

const Spinner = ({ setNumber, number, text, label, min, max, step, style, color }) => {
  const [increaseDisabled, setIncreaseDisabled] = useState(false)
  const [decreaseDisabled, setDecreaseDisabled] = useState(false)

  useEffect(() => {
    if (number >= max) {
      setIncreaseDisabled(true)
    }
    if (number <= min) {
      setDecreaseDisabled(true)
    }
  }, [number])

  const increase = () => {
    if (number < max) {
      setNumber(number + step)
      setDecreaseDisabled(false)
    }
  }

  const decrease = () => {
    if (number > min) {
      setNumber(number - step)
      setIncreaseDisabled(false)
    }
  }

  return (
    <ThemeProvider theme={label ? theme2 : theme}>
      <div className='flex flex-row justify-between mt-4 items-center w-full'>
        <button
          disabled={decreaseDisabled}
          onClick={decrease}
          className={`flex items-center justify-center p-2 w-12 h-12 rounded-full ${
            decreaseDisabled ? 'bg-gray' : 'bg-primary'
          }`}
        >
          <FontAwesomeIcon icon={faMinus} className='text-white text-lg' />
        </button>
        <p style={{ ...style, color: color, fontSize: 40 }}>
          {text ?? ''} {number}
        </p>
        <button
          onClick={increase}
          disabled={increaseDisabled}
          className={`flex items-center justify-center p-2 w-12 h-12 rounded-full ${
            increaseDisabled ? 'bg-gray' : 'bg-primary'
          }`}
        >
          <FontAwesomeIcon icon={faPlus} className='text-white text-lg' />
        </button>
      </div>
    </ThemeProvider>
  )
}

export default Spinner
