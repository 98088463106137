import { useAuthorizedFetch } from '../../hooks'
import apiConfig from '../../config/Api'
import { Divider, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import JoinSuccess from '../../components/JoinSuccess'
import { useEffect } from 'react'
import { Mixpanel } from '../../Mixpanel'
import SearchError from '../SearchError'
import LoadingIndicator from '../LoadingIndicator'

const Join = ({ id, joinData, handleClose }) => {

  const { loading, response } = useAuthorizedFetch(`${apiConfig.uri}/lobbies/${id}/join`, {
    method: 'POST',
    body: JSON.stringify(joinData)
  });

  useEffect(() => {
    if (!loading) {
      if (response.ok) {
        Mixpanel.track('Unirse lobby exitoso', {
          'Lobby': id,
        })
      }
      else if (response.status == 409) {
        Mixpanel.track('Ya Unido previamente', {
          'Lobby': id,
        })
      } else {
        Mixpanel.track('Error al unirse', {
          'Lobby': id,
          'JoinInfo': joinData,
          'Error': response.status
        })
      }
    }
  }, [loading])

  return (
    <>
      {
        loading ?
          <div className='flex h-full items-center justify-center'>
            <LoadingIndicator />
          </div>
          :
          <>
            {
              response.ok ?
                <JoinSuccess free={true} />
                :
                (response.status == 409) ?
                  <div className='h-full'>
                    <div className='w-full justify-end flex pt-2'>
                      <IconButton onClick={handleClose} style={{ paddingTop: '0' }} sx={{ color: '#e65944' }} >
                        <Close />
                      </IconButton>
                    </div>
                    <div className='flex flex-col text-lg text-center pr-6 pl-6 justify-center h-4/5 overflow-auto' >
                      <h1>Ya te has unido a este viaje anteriormente. Puedes ver información del trayecto en el apartado "Mis Viajes".</h1>
                      <br />
                      <p>En caso de tener alguna consulta <a
                        className='text-light_coral'
                        href={`https://api.whatsapp.com/send/?phone=+59898415699&text=Hola,%20soy%20parte%20del%20trayecto%20@${id}%20y%20tengo%20la%20siguiente%20duda:%20`}
                        target='_blank'
                      >
                        escribinos
                      </a></p>
                    </div>
                  </div>
                  :
                  <div className='h-full'>
                    <div className='w-full justify-end flex pt-2'>
                      <IconButton onClick={handleClose} style={{ paddingTop: '0' }} sx={{ color: '#e65944' }} >
                        <Close />
                      </IconButton>
                    </div>
                    <Divider />
                    <div className='flex flex-col text-center pr-6 pl-6 justify-center h-4/5 overflow-auto'>
                      <SearchError />
                    </div>
                  </div>
            }
          </>
      }
    </>
  )
}

export default Join