import { useEffect, useState } from 'react'
import { useAuthorizedFetch } from '../../hooks'
import { useAuth0 } from '@auth0/auth0-react'
import { Mixpanel } from '../../Mixpanel'
import Modal from 'react-modal'
import { Divider, IconButton, Snackbar, TextField, ThemeProvider, createTheme } from '@mui/material'
import { ArrowBackIosNew, ChevronRight } from '@mui/icons-material'
import Button from '../../commons/Button'
import useStyles from './styles'
import { uri } from '../../config/Api'
import { DIAS, MESES } from '../../config/App'

export default ({ }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/users/reviews`)
  const { user } = useAuth0()
  const [usersToReview, setUsersToReview] = useState([])
  const [visibleIndex, setVisibleIndex] = useState(-1)

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setUsersToReview(data)
        setVisibleIndex(0)
      })
    }
  }, [response])

  return (
    <>
      {
        !loading && usersToReview &&
        usersToReview?.map((userToReview, index) => (
          <Review key={index} userToReview={userToReview} visibleIndex={visibleIndex} setVisibleIndex={setVisibleIndex} index={index} user={user} />
        ))
      }
    </>
  )
}

const Review = ({ userToReview, visibleIndex, setVisibleIndex, index, user }) => {
  const { styles } = useStyles()
  const [stars, setStars] = useState(0)
  const [comment, setComment] = useState('')
  const [step, setStep] = useState(0)
  const [postReviews, setPostReviews] = useState(false)
  const [emptyComment, setEmptyComment] = useState(false)

  const handleButtonPress = (userId, qualification) => {
    if (step === 0) {
      setStars(qualification)
      setStep(1)
    } else {
      if (comment != '') {
        Mixpanel.track('Review enviada', {
          'Estrellas': stars,
          'Comentario': comment,
          'Fecha': new Date().toISOString(),
          'Reviewer': user.sub,
          'Usuario': userId,
        })
        setPostReviews(true)
        setVisibleIndex(visibleIndex + 1)
      } else {
        setEmptyComment(true)
      }
    }
  }

  return (
    <>
      <Modal
        isOpen={visibleIndex === index}
        style={styles.modal}
      >
        {
          step === 0 ?
            <Stars
              handlePress={handleButtonPress}
              name={userToReview.name}
              date={userToReview.date}
              isCompanion={userToReview.companion}
            />
            :
            <Comment
              comment={comment}
              setComment={setComment}
              setStep={setStep}
              handleButtonPress={handleButtonPress}
              userId={userToReview.user_id}
              isCompanion={userToReview.companion}
              date={userToReview.date}
              name={userToReview.name}
            />
        }
        <Snackbar
          open={emptyComment}
          autoHideDuration={2000}
          onClose={() => setEmptyComment(false)}
          message='Debes incluir un comentario'
        />
      </Modal >
      {
        postReviews &&
        <PostReview review={{ qualification: stars, text: comment, lobby_id: userToReview.lobby_id }} userId={userToReview.user_id} />
      }
    </>
  )
}

const Stars = ({ handlePress, name, date, isCompanion }) => {
  const { styles } = useStyles()

  return (
    <div style={{ backgroundColor: '#d65944', height: '100%' }}>
      <div style={styles.starsStep}>
        {
          isCompanion ?
            <>
              <p style={styles.title}>¡Esperamos que hayas tenido un buen viaje {formatDateTitle(date)}!</p>
              <p style={styles.subtitle}>Calificá a {name}</p>
            </>
            :
            <>
              <p style={styles.title}>¡Esperamos que hayas tenido un buen viaje!</p>
              <p style={styles.subtitle}>Calificá tu viaje {formatDate(date)} con {name}</p>
            </>
        }
        <div style={styles.qualificationContainer}>
          {
            ['Excelente', 'Bueno', 'Correcto', 'Malo', 'Muy malo'].map((text, index) => (
              <>
                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <p
                    onClick={() => handlePress('', 5 - index)}
                    style={styles.qualification}
                  >
                    {text}
                  </p>
                  <ChevronRight style={{ color: '#fff' }} />
                </div>
                {index !== 4 && <Divider style={{ backgroundColor: '#fff', marginTop: 20, marginBottom: 20 }} />}
              </>
            ))
          }
        </div>
      </div>
    </div>
  )
}

const Comment = ({ comment, setComment, setStep, handleButtonPress, userId, isCompanion, name, date }) => {
  const { styles } = useStyles()

  const theme = createTheme({
    typography: {
      fontFamily: {
        Poppins: 'Poppins, sans-serif',
      }
    },
    shape: {
      borderRadius: 20,
    },
    // input
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            backgroundColor: '#f3f4f6',
            border: 'none',
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            paddingLeft: 30,
            paddingRight: 30,
          },
        }
      },
    }
  })

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '55%' }}>
        <IconButton onClick={() => setStep(0)} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <ArrowBackIosNew style={{ color: '#fff' }} />
        </IconButton>
        {
          isCompanion ?
            <>
              <p style={styles.title}>¡Esperamos que hayas tenido un buen viaje {formatDateTitle(date)}!</p>
              <p style={styles.subtitle}>Dejá un comentario a {name}</p>
            </>
            :
            <>
              <p style={styles.title}>¡Esperamos que hayas tenido un buen viaje!</p>
              <p style={styles.subtitle}>Dejá un comentario a {name} sobre tu viaje</p>
            </>
        }
        <ThemeProvider theme={theme}>
          <TextField
            id='comment'
            multiline
            maxRows={4}
            onChange={(evt) => setComment(evt.target.value)}
            placeholder='Escribí tu comentario'
            minRows={4}
            value={comment}
            inputProps={{
              maxLength: 180,
            }}
            autoFocus
          />
        </ThemeProvider>
      </div>
      <Button
        name={'Enviar'}
        onClick={() => handleButtonPress(userId)}
        style={styles.button}
      />
    </div >
  )
}

const PostReview = ({ review, userId }) => {
  const date = new Date().toISOString()
  const { loading, response } = useAuthorizedFetch(`${uri}/users/reviews/${userId}`, {
    method: 'POST',
    body: JSON.stringify({
      ...review,
      date,
    })
  })

  return <></>
}

const formatDate = (dateString) => {
  const date = Date.parse(dateString)
  const today = new Date().getTime()
  const diff = today - date
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days === 0) {
    return 'de hoy'
  } else if (days === 1) {
    return 'de ayer'
  } else if (days < 7) {
    return `del ${DIAS[new Date(dateString).getDay()]}`
  } else {
    return `del ${new Date(dateString).getDate() + ' de ' + MESES[new Date(dateString).getMonth()]}`
  }
}

const formatDateTitle = (dateString) => {
  const date = Date.parse(dateString)
  const today = new Date().getTime()
  const diff = today - date
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days === 0) {
    return 'hoy'
  } else if (days === 1) {
    return 'ayer'
  } else if (days < 7) {
    return `el ${DIAS[new Date(dateString).getDay()].trim()}`
  } else {
    return `el ${new Date(dateString).getDate() + ' de ' + MESES[new Date(dateString).getMonth()]}`
  }
}
