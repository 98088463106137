import { Mixpanel } from '../../Mixpanel'

const ScheduleButton = ({ setScheduleModal }) => {
  return (
    <p
      className='text-lg text-light_coral cursor-pointer'
      onClick={() => {
        Mixpanel.track('Botón buscar viaje especifico')
        setScheduleModal(true)
      }}
    >
      No encontré lo que buscaba
    </p>
  )
}

export default ScheduleButton