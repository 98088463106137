import Modal from 'react-modal'
import theme from './theme'
import { ThemeProvider, Radio, IconButton } from '@mui/material'
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'

const LeaveLobbyReasonScreen = ({ handleSubmit, setSelectedReason, selectedReason, onBack }) => {
  const reasons = [
    { key: 'change', name: 'Cambios en los detalles del viaje (horario, fecha, punto de encuentro)' },
    { key: 'plan', name: 'Cambio de planes, inconvenientes' },
    { key: 'meet', name: 'Problemas al coordinar encuentro' },
    { key: 'no_response', name: 'El conductor no responde' },
    { key: 'trust', name: 'Desconfianza' },
    { key: 'pets', name: 'No acepta mascotas' },
    { key: 'other', name: 'Otro' }
  ]

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 0,
    },
    overlay: {
      zIndex: 120,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  }

  return (
    <Modal
      isOpen={true}
      style={customStyles}
    >
      <div className='flex'>
        <IconButton onClick={onBack} sx={{ color: '#f25c45' }} >
          <ArrowBackIosNew />
        </IconButton>
      </div>
      <ThemeProvider theme={theme}>
        <div className='flex flex-col pr-3 pl-3 text-2xl mt-3 text-2xl overflow-auto'>
          <div className='text-primary text-center'>
            Antes de abandonar, ¿nos gustaría saber por que?
          </div>
          <div
            className='flex justify-center'
            style={{ height: 'calc(100vh - 150px)' }}
          >
            <div className='tall:flex tall:flex-col tall:gap-10 mt-5'>
              {
                reasons.map((x) => (
                  <div
                    onClick={() => setSelectedReason(x.key)}
                    className='flex flex-row mb-5 tall:mb-0 text-lg items-center pr-3'
                  >
                    <Radio
                      checked={selectedReason == x.key}
                      key={x.key}
                      value={x.key}
                      sx={{ '&.Mui-checked': { color: '#f25c45' } }}
                      size='large'
                    />
                    <p>{x.name}</p>
                  </div>
                ))
              }
            </div>
          </div>
          <div className='fixed bottom-5 right-5'>
            <IconButton onClick={handleSubmit} sx={{ backgroundColor: '#d65944', borderRadius: 20 }} size='large' disabled={!selectedReason} >
              <ArrowForwardIos sx={{ color: 'white' }} />
            </IconButton>
          </div>
        </div>
      </ThemeProvider>
    </Modal>
  )
}

export default LeaveLobbyReasonScreen