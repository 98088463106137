import Button from '../../commons/Button'
import SearchError from '../../commons/SearchError'

const RequestError = ({ height, onSubmit }) => {
  return (
    <div className='flex flex-col text-center justify-center items-center px-6' style={{ height: height }}>
      <SearchError />
      <div className='w-full bottom-5 absolute z-30 text-center'>
        <Button
          name='Aceptar'
          onClick={onSubmit}
          style={{ margin: 'auto', fontSize: 16 }}
        />
      </div>
    </div>
  )
}

export default RequestError