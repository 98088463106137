import { ArrowForwardIos } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export default ({ onSubmit, disabled }) => {
  return (
    <div className='fixed bottom-8 right-8'>
      <IconButton
        onClick={onSubmit}
        sx={{ backgroundColor: '#d65944', borderRadius: 20 }}
        size='large'
        disabled={disabled}
      >
        <ArrowForwardIos sx={{ color: 'white' }} />
      </IconButton>
    </div>
  )
}
