import { useEffect, useState } from "react"

const calculateDiagonal = (width, height) => {
  return Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2))
}

const useStyles = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    }
  }, [])

  return {
    styles: {
      modal: {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          paddingRight: '0.3rem',
          paddingLeft: '0.3rem',
          paddingTop: '1rem',
          paddingBottom: '2rem',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: '#d65944',
        },
        overlay: {
          zIndex: 110,
          backgroundColor: 'rgba(0,0,0,0)',
        }
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#d65944',
        height: '100%',
        justifyContent: 'space-between',
        paddingBottom: 20,
      },
      title: {
        fontSize: calculateDiagonal(width, height) * 0.028,
        color: '#fff',
        paddingLeft: 30,
        paddingRight: 30,
      },
      subtitle: {
        fontSize: calculateDiagonal(width, height) * 0.024,
        color: '#fff',
        paddingLeft: 30,
        paddingRight: 30,
      },
      starsStep: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#d65944',
        justifyContent: 'space-between',
        height: '90%',
        marginTop: 20,
      },
      qualificationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
      },
      qualification: {
        color: '#fff',
        fontSize: calculateDiagonal(width, height) * 0.024,
      },
      button: {
        backgroundColor: '#fff',
        borderRadius: 10,
        height: height * 0.08,
        fontSize: calculateDiagonal(width, height) * 0.03,
        color: '#d65944',
        marginLeft: 30,
        marginRight: 30,
      },
      commentInput: {
        backgroundColor: '#f3f4f6',
      }
    }
  }
}

export default useStyles