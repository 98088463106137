import { Mixpanel } from '../../Mixpanel'
import Button from '../../commons/Button'


const Pending = ({ title, message, onSubmit, height }) => {

  return (
    <div className='flex flex-col justify-between' style={{ height: height }}>
      <div />
      <div className='flex flex-col gap-9 items-center justify-center px-12'>
        <div className='flex bg-[#ffa15a] rounded-full w-24 h-24 items-center justify-center'>
          <img src={require('../../assets/img/pending.png')} alt='pendiente' className='w-14' />
        </div>
        <div className='flex flex-col gap-6 items-center justify-center'>
          <p className='text-2xl font-bold text-primary text-center'>{title}</p>
          <p className='text-lg text-center'>{message}</p>
        </div>
      </div>
      <div className='flex pb-10 justify-center items-center'>
        <Button
          name='Continuar'
          style={{ margin: 'auto', fontSize: 16 }}
          onClick={onSubmit}
        />
      </div>
    </div>
  )
}

export default Pending