import { Avatar } from '@mui/material'

const Company = ({ bus }) => {
  return (
    <div className='w-full flex gap-4 items-center text-2xl sm:justify-center mt-4 pl-6'>
      <Avatar alt={bus.Company_info.name} src={bus.Company_info.picture} sx={{ width: 100, height: 100 }} />
      <div className='flex flex-col justify-center gap-1 font-thin'>
        <p className='text-xl text-primary'>{bus.Company_info.name}</p>
        <p className='text-base text-[#9ca3af]'>{bus.Journey.type}</p>
      </div>
    </div>
  )
}

export default Company