import NextButton from './NextButton'
import TimePicker from '../../commons/TimePicker'

export default ({ time, setTime, handleSelect }) => {
  return (
    <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
      <div className='sm:w-1/2'>
        <p className='text-start mb-6'>¿A qué hora?</p>
        <TimePicker
          data={time}
          setData={setTime}
          style={{ fontSize: 26, alignText: 'center', justifyContent: 'center' }}
        />
        {time && <NextButton onSubmit={handleSelect} />}
      </div>
    </div>
  )
}
