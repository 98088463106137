const level = (level) => {
  if (level == 1) {
    return '😇 Inicial'
  } else if (level == 2) {
    return '🙂 Experimentado'
  } else if (level == 3) {
    return '😃 Avanzado'
  } else {
    return '😎 Experto'
  }
}

export default level
