import { Divider, TextField } from '@mui/material'
import Button from '../../commons/Button'
import Preferences from '../../commons/Preferences'
import ProfilePicture from '../../commons/ProfilePicture'
import { useState } from 'react'
import EditPicture from '../ProfileInfo/EditPicture'
import { containsContactInfo } from '../../utils'

const ThirdStep = ({
  picture,
  setPicture,
  onSubmit,
  setAboutMe,
  aboutMe,
  preferences,
  setPreferences,
  date,
  gender,
  setProfileImg,
}) => {
  const [pictureEdited, setPictureEdited] = useState(false)
  const age = (new Date().getTime() - date.getTime()) / (365 * 24 * 60 * 60 * 1000)
  const [biographyError, setBiographyError] = useState(false)

  const handleSubmit = () => {
    if (containsContactInfo(aboutMe)) {
      setBiographyError(true)
    } else {
      setBiographyError(false)
      onSubmit()
    }
  }

  return (
    <>
      <div className='text-center text-lg h-4/5 overflow-auto pr-4'>
        <div className='text-xl text-center'>Opcionales</div>
        <div className='text-1xl text-center font-bold'>3/3</div>
        <div className='text-base text-orange-400 mt-1'>
          Las personas que dispongan de su foto y completen estos campos aumentan en un 75% su
          probabilidad de conseguir viaje
        </div>
        <div className='mt-1 items-center'>
          <ProfilePicture
            src={picture}
            setImage={setPicture}
            setPictureEdited={setPictureEdited}
            text='SELECCIONAR FOTO'
          />
        </div>
        <div className='mt-1'>
          <p>Sobre mí</p>
          <TextField
            multiline
            maxRows={3}
            onChange={evt => {
              if (evt.target.value.length >= 180) {
                setAboutMe(aboutMe)
              } else {
                setAboutMe(evt.target.value)
              }
            }}
            placeholder={
              age < 25
                ? 'Ejemplo: Soy estudiante de medicina, me gusta el fútbol y viajar'
                : age >= 25 && age < 40
                  ? 'Ejemplo: Trabajo en una empresa de tecnología, me gusta el rock y el fútbol'
                  : gender == 'Hombre'
                    ? 'Ejemplo: Soy padre de familia, viajo para trabajar y visitar a mis hijos'
                    : 'Ejemplo: Trabajo en una empresa de tecnología, viajo para visitar a mi familia'
            }
            style={{ width: 250 }}
            minRows={3}
            value={aboutMe}
          />
          {biographyError && <p className='text-xs text-[#ef4444] px-3 pt-1'>No puede contener información de contacto</p>}
        </div>
        <div className='mt-4 mb-4 items-center flex flex-col justify-center'>
          <p>Preferencias al viajar</p>
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            gender={gender}
          />
        </div>
      </div>
      <div className='flex flex-col pr-4'>
        <Divider />
        <Button
          onClick={handleSubmit}
          name='Confirmar'
          style={{
            marginTop: '10px',
            zIndex: 300,
          }}
        />
      </div>
      {
        pictureEdited ?
          <EditPicture
            visible={pictureEdited}
            setVisible={setPictureEdited}
            picture={picture}
            setAvatarImage={setPicture}
            setProfileImg={setProfileImg}
          />
          :
          <></>
      }
    </>
  )
}

export default ThirdStep
