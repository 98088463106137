import { MESES } from '../../config/App'

const styleDate = (dateString) => {
  const date = new Date(dateString)
  return (
    `Miembro desde ${MESES[date.getMonth() - 1]} ${date.getFullYear()}`
  )
}

export default styleDate
