import toDate from './toDate'

export default (lobbies, buses) => {
  const currentDate = new Date()
  const lobbiesAndBusesByDate = {}
  const lobbiesByDate = {}
  const busesByDate = {}

  const addToDateMap = (map, key, value) => {
    if (!map[key]) {
      map[key] = []
    }
    map[key].push(value)
  }

  for (const lobby of lobbies) {
    const departureTime = toDate(lobby.Journey.departure_time)
    if (currentDate.getTime() <= departureTime.getTime()) {
      const dateKey = lobby.Journey.departure_time.substring(0, 10)
      addToDateMap(lobbiesAndBusesByDate, dateKey, lobby)
      addToDateMap(lobbiesByDate, dateKey, lobby)
    }
  }

  for (const bus of buses) {
    const departureTime = toDate(bus.Journey.departure_time)
    if (currentDate.getTime() <= departureTime.getTime()) {
      const dateKey = bus.Journey.departure_time.substring(0, 10)
      addToDateMap(lobbiesAndBusesByDate, dateKey, bus)
      addToDateMap(busesByDate, dateKey, bus)
    }
  }

  return { lobbiesAndBusesByDate, lobbiesByDate, busesByDate }
};