import { useEffect, useState } from 'react'
import { useAuth, useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import { MenuItem, TextField, ThemeProvider, createTheme } from '@mui/material'
import Button from '../../commons/Button'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { muiInputTheme } from '../../utils'
import { namespace } from '../../config/Auth0'
import { Mixpanel } from '../../Mixpanel'
import SearchError from '../../commons/SearchError'

const PassengerDetails = ({ busInfo, bus, seats, setPassengerDetails, passengerDetails, setStep, setTransaction, setSelectedSeats }) => {
  const { user } = useAuth()
  const [idNumber, setIdNumber] = useState('')
  const [boardingStation, setBoardingStation] = useState(defaultBoardingStation(busInfo, passengerDetails))
  const [showIdError, setShowIdError] = useState(false)
  const [showBoardingError, setShowBoardingError] = useState(false)

  const { loading, response } = useAuthorizedFetch(`${uri}/buses/seats/${bus.id}`, {
    method: 'POST',
    body: JSON.stringify({
      seats: parseInt(seats),
      type: bus.Journey.type,
      qr_company_name: bus.Company_info.qr_company_name,
    })
  })

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setTransaction(data.transaction)
        setSelectedSeats(data.seats.map(s => s.seat))
      })
    }
  }, [loading])

  useEffect(() => {
    if (user) {
      setIdNumber(user[namespace + 'id_number'])
    }
  }, [user])

  const handleSubmit = () => {
    if (idNumber && formatIdNumber(idNumber).length > 6 && boardingStation) {
      Mixpanel.track('Botón PassengerDetails', {
        'Name': user[namespace + 'first_name'] + ' ' + user[namespace + 'last_name'],
        'Id Number': idNumber,
        'Boarding Station': boardingStation,
        'Bus Id': bus.id,
      })
      setShowIdError(false)
      setShowBoardingError(false)
      setPassengerDetails({
        full_name: user[namespace + 'first_name'] + ' ' + user[namespace + 'last_name'],
        id_number: formatIdNumber(idNumber),
        boarding_station: boardingStation,
      })
      setStep(2)
    } else {
      if (!idNumber || formatIdNumber(idNumber).length < 6) {
        setShowIdError(true)
      } else {
        setShowIdError(false)
      }
      if (!boardingStation) {
        setShowBoardingError(true)
      } else {
        setShowBoardingError(false)
      }
    }
  }

  return (
    <>
      {
        loading ?
          <div className='h-full flex justify-center items-center'>
            <LoadingIndicator />
          </div>
          :
          response.ok ?
            <ThemeProvider theme={muiInputTheme}>
              <div className='h-full overflow-auto flex-col flex justify-between px-6 sm:items-center'>
                <Inputs
                  idNumber={idNumber}
                  setIdNumber={setIdNumber}
                  boardingStation={boardingStation}
                  setBoardingStation={setBoardingStation}
                  boardingStations={busInfo.boarding_stations}
                  showIdError={showIdError}
                  showBoardingError={showBoardingError}
                />
                <Button
                  name={'Confirmar'}
                  style={{ marginTop: '10px', marginBottom: '20px', zIndex: 300 }}
                  onClick={handleSubmit}
                />
              </div>
            </ThemeProvider>
            :
            <SearchError />
      }
    </>
  )
}

const Inputs = ({ idNumber, setIdNumber, boardingStation, setBoardingStation, boardingStations, showIdError, showBoardingError }) => {
  const { user } = useAuth()

  return (
    <div className='flex flex-col gap-6'>
      <p className='text-center text-xl text-primary'>Necesitamos la siguiente información</p>
      {
        !user?.[namespace + 'id_number'] &&
        <div className='flex flex-col mt-2 pr-4 gap-2'>
          <p className='text-lg text-primary'>Nro. documento</p>
          <TextField
            value={idNumber}
            placeholder='Nro. documento'
            size='small'
            onChange={evt => setIdNumber(evt.target.value)}
            type='number'
          />
          {showIdError && <p className='text-sm text-[#ef4444]'>Nro. de documento invalido</p>}
        </div>
      }
      <div className='flex flex-col mt-2 pr-4 gap-2'>
        <p className='text-lg text-primary'>Levante</p>
        <TextField
          placeholder='Levante'
          select
          value={boardingStation}
          onChange={evt => setBoardingStation(evt.target.value)}
          size='small'
        >
          {boardingStations.map(x => (
            <MenuItem key={x.id} value={x}>
              {x.name}
            </MenuItem>
          ))}
        </TextField>
        {showBoardingError && <p className='text-sm text-[#ef4444]'>Seleccione una terminal de embarque</p>}
      </div>
    </div>
  )
}

const defaultBoardingStation = (busInfo, passengerDetails) => {
  if (passengerDetails.boarding_station) {
    return busInfo.boarding_stations.find(x => x.id === passengerDetails.boarding_station.id)
  }
  if (busInfo.default_boarding_station) {
    return busInfo.boarding_stations.find(x => x.id === busInfo.default_boarding_station.id)
  }
  return ''
}

const formatIdNumber = (idNumber) => {
  var numericString = ""

  for (var i = 0; i < idNumber.length; i++) {
    var currentChar = idNumber[i]
    if (!isNaN(currentChar)) {
      numericString += currentChar
    }
  }

  return numericString
}

export default PassengerDetails