import { uri } from '../../../../config/Api'
import { useAuthorizedFetch } from '../../../../hooks'
import Alert from '../../../../commons/Alert'
import SearchError from '../../../../commons/SearchError'
import { useEffect } from 'react'
import { Mixpanel } from '../../../../Mixpanel'
import LoadingIndicator from '../../../../commons/LoadingIndicator'

const EditLobby = ({ visible, setVisible, data, lobby }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/lobbies/${lobby._id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      seats_available: data.seats,
      price: data.price,
      comment: data.comment,
      Journey: {
        origin: lobby.Journey.origin.coordinates.toString(),
        destination: lobby.Journey.destination.coordinates.toString(),
        departure_time: data.newTime,
      }
    }),
  })

  useEffect(() => {
    if (response.ok) {
      Mixpanel.track('Lobby Editada con Exito', {
        'Cambios': data,
        'Lobby': lobby
      })
      lobby.seats_available = data.seats
      lobby.price = data.price
      lobby.Journey.departure_time = data.newTime
      lobby.Journey.estimated_time = data.estimatedTime
      lobby.comment = data.comment
    } else if (response.ok == false) {
      Mixpanel.track('Error al editar Lobby', {
        'Cambios': data,
        'Lobby': lobby
      })
    }
  }, [loading])

  return (
    <Alert
      visible={visible} onClose={() => setVisible(false)}
      hideButton={loading}
    >
      {
        loading ?
          <div className='flex flex-col items-center'>
            <LoadingIndicator />
          </div>
          :
          response.ok ?
            <div>Información cargada con éxito!</div>
            :
            <SearchError />
      }
    </Alert>
  )
}

export default EditLobby