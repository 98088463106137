const colors = {
  PRIMARY: '#D65944',
  LIGHT_GRAY: '#F3F4F6',
  LIGHT_CORAL: '#F25C45',
  BLACK: '#212427',
  GRAY: '#9CA3AF',
}

const styles = {
  container: {
    alignItems: 'center',
    justifyItems: 'center',
    paddingTop: 30,
    height: '100%',
    overflow: 'auto',
  },
  firstSubcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    color: colors.PRIMARY,
    marginTop: 10,
    textAlign: 'center',
    fontWeight: '400',
  },
  nameSubcontainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginTop: 5
  },
  phoneContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  infoText: {
    fontSize: 26,
    marginBottom: 10,
    fontWeight: '400',
  },
  biographyText: {
    fontSize: 20,
    color: colors.GRAY,
    fontWeight: '400',
  },
  noBiographyText: {
    fontSize: 20,
    marginBottom: 10,
    color: colors.GRAY,
    textAlign: 'center',
    fontWeight: '400',
  },
  biographyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
    paddingLeft: 20,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  likesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 30,
    cursor: 'pointer',
  },
  likesSubcontainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  likesText: {
    marginLeft: 5,
    fontSize: 20,
    fontWeight: '400',
    color: '#9ca3af',
  },
  nameText: {
    fontSize: 22,
    marginRight: 5,
    fontWeight: '400',
  },
  ageText: {
    fontSize: 18,
    fontWeight: '400',
  },
  divider: {
    backgroundColor: colors.LIGHT_GRAY,
    height: 7,
    width: '100%',
    marginTop: 25,
    marginBottom: 25,
  },
  secondSubcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  levelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  levelText: {
    fontSize: 24,
    fontWeight: '400',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: '400',
    color: colors.GRAY,
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
    paddingLeft: 20,
  },
  extraInfoText: {
    fontSize: 20,
    fontWeight: '400',
    color: colors.GRAY,
    marginBottom: 20,
  },
  preferencesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    paddingRight: 30,
    paddingLeft: 30,
    marginTop: 20,
    justifyContent: 'center',
  },
  preferences: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 10,
    marginTop: 10,
  },
  preferencesText: {
    fontSize: 15,
    marginBottom: 10,
    color: colors.GRAY,
  },
  invertButton: {
    width: '100%',
    boxSizing: 'border-box',
  }
}

export default styles
