import { Room, Flag, MoreVert, StarsRounded, StarRateRounded } from '@mui/icons-material'
import Avatar from '../Avatar'
import { Avatar as MuiAvatar } from '@mui/material'

const Locations = ({ originName, destinationName, style, ownerPicture, ownerName, full, bus, verified, type, stars }) => {
  return (
    <div
      className={`flex flex-col justify-around text-left w-full ${full && 'opacity-50'}`}
    >
      <div className='flex gap-2 h-[33%]' >
        <Room sx={style} />
        <div className='text-[1.1rem]'>{originName}</div>
      </div>
      <div className='flex gap-2 h-[33%]' >
        <Flag sx={style} />
        <div className='text-[1.1rem]'>{destinationName}</div>
      </div>
      <div className='flex gap-2 h-[34%] items-center text-[1.3rem]'>
        {
          bus ?
            <MuiAvatar
              src={ownerPicture}
              sx={{ width: '50px', height: '50px' }}
            />
            :
            <Avatar
              src={ownerPicture}
              sx={{ width: '50px', height: '50px' }}
              verified={verified}
              badgeSize={20}
            />
        }
        <div className='flex flex-col'>
          <p className='text-[1.1rem] ml-1'>{ownerName}</p>
          {
            bus ?
              <p className='text-[0.9rem] text-[#9ca3af] ml-1'>{type}</p>
              :
              stars > 0 &&
              <div className='flex gap-[0.2rem]'>
                <StarRateRounded sx={{ color: '#9ca3af' }} />
                <p className='text-[0.9rem] text-[#9ca3af]'>{stars?.toFixed(1)}</p>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Locations