export default ({ departureTime, arrivalTime, origin, destination }) => {
  return (
    <div className='flex flex-row justify-between w-full items-center px-6'>
      <div className='flex flex-col items-start flex-1'>
        <p className='text-lg'>{departureTime}</p>
        <p className='text-sm font-thin'>{origin}</p>
      </div>
      <div className='flex flex-col items-start flex-1 ml-4'>
        <p className='text-lg'>{arrivalTime}</p>
        <p className='text-sm font-thin'>{destination}</p>
      </div>
    </div>
  )
}