import { AccessTimeFilled } from '@mui/icons-material'
import { getCurrency } from '../../utils'

const Info = ({ time, price, full, discount, currency }) => {

  return (
    <div className='flex flex-col justify-around items-end text-[1.2rem]' >
      {
        full ?
          <div className='flex gap-2 h-[100%] text-[1.1rem]'>
            <p className='font-black text-[1.4rem]'>Lleno</p>
          </div>
          :
          <>
            <div className='flex gap-2 h-[33%]' >
              <p className='text-[1.1rem]'>{time.substring(11, 16)}</p>
              <AccessTimeFilled fontSize='medium' style={{ color: '#c65944' }} />
            </div>
            <div className='flex flex-row gap-2 h-[33%] text-[1.1rem]' >
              {discount > 0 && <p className='text-sm text-[#9ca3af] line-through'>{getCurrency(currency)}{price}</p>}
              <p>{price - discount}</p>
              <p className='text-[#c65944] font-black text-[1.1rem]'>{getCurrency(currency)}</p>
            </div>
            <div className='h-[34%]' />
          </>
      }
    </div>
  )
}
export default Info