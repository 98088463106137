module.exports = {
  website: process.env.REACT_APP_WEBSITE_URL,
  url: process.env.REACT_APP_URL,
  MESES: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  DIAS: ['Domingo ', 'Lunes ', 'Martes ', 'Miércoles ', 'Jueves ', 'Viernes ', 'Sábado '],
  MINUTE: 1000 * 60,
  CHAT_ADMIN_PROFILE: {
    user_id: 'admin',
    name: 'Soporte Viatik - Natalia',
    picture: 'https://viatik-profiles.s3.sa-east-1.amazonaws.com/natalia/profile.png'
  }
}
