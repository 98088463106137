import { TextField } from '@mui/material'


const EditProfilePhone = ({ phoneNumber, setPhoneNumber }) => {
  return (
    <div className='flex flex-col text-lg gap-2'>
      <p className='text-primary text-2xl font-thin text-xl'>Teléfono</p>
      <TextField
        size='small'
        value={phoneNumber}
        onChange={e => setPhoneNumber(e.target.value)}
      />
    </div>
  )
}

export default EditProfilePhone
