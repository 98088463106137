import Profile from '../Profile'
import { Mixpanel } from '../../Mixpanel'
import { useAuth } from '../../hooks'
import auth0Config from '../../config/Auth0'
import { Drawer } from '@mui/material'

const DrawerRef = ({
  profileImg,
  setProfile,
  setAlertValue,
  alertValue,
  setTrips,
  setTickets,
  setMessages,
  navigation
}) => {
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth()
  return (
    <Drawer
      anchor='right'
      open={alertValue}
      onClose={() => {
        setAlertValue(false)
      }}
    >
      {isAuthenticated && !isLoading ? (
        <>
          <Profile
            name={user?.[auth0Config.namespace + 'first_name']}
            img={profileImg}
            setAlertValue={setAlertValue}
            isAuthenticated={isAuthenticated}
            user={user}
          />
          <div className='h-full flex flex-col justify-between px-5 mt-5'>
            <div className='flex flex-col items-center px-10 gap-4'>
              <div
                className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
                onClick={() => {
                  Mixpanel.track('Boton Mi Perfil')
                  setAlertValue(false)
                  window.history.pushState({}, '', '/profile')
                  setProfile(true)
                }}
              >
                Perfil
              </div>
              <div
                className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
                onClick={() => {
                  Mixpanel.track('Boton Mis Viajes')
                  setAlertValue(false)
                  window.history.pushState({}, '', '/trips')
                  setTrips(true)
                }}
              >
                Mis Viajes
              </div>
              <div
                className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
                onClick={() => {
                  Mixpanel.track('Boton Mis Pasajes')
                  setAlertValue(false)
                  window.history.pushState({}, '', '/tickets')
                  setTickets(true)
                }}
              >
                Mis Pasajes
              </div>
              <div
                className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
                onClick={() => {
                  Mixpanel.track('Boton Mensajes')
                  setAlertValue(false)
                  window.history.pushState({}, '', '/messages')
                  setMessages(true)
                }}
              >
                Mensajes
              </div>
              <div
                className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
                onClick={() => {
                  Mixpanel.track('Boton ir a Crear Viaje')
                  window.location.href =
                    window.location.origin + `/${navigation === 'SearchLobby' && 'create'}`
                }}
              >
                {navigation === 'SearchLobby' ? 'Publicar Viaje' : 'Buscar Viaje'}
              </div>
            </div>
            <div
              className='text-base text-red-500 cursor-pointer hover:text-red-400 mb-4 text-center'
              onClick={() => {
                Mixpanel.track('Boton Cerrar Sesion')
                logout({ returnTo: window.origin })
              }}
            >
              Cerrar Sesión
            </div>
          </div>
        </>
      ) : (
        <>
          <Profile setAlertValue={setAlertValue} />
          <div className='flex flex-col items-center gap-4 px-10 mt-5'>
            <div
              className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
              onClick={() => {
                Mixpanel.track('Boton Iniciar de sesion')
                loginWithRedirect({
                  appState: window.location.href
                })
              }}
            >
              Iniciar Sesión
            </div>
            <div
              className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
              onClick={() => {
                Mixpanel.track('Boton Registrarse')
                loginWithRedirect({
                  appState: window.location.href,
                  screen_hint: 'signup'
                })
              }}
            >
              Registrarme
            </div>
            <div
              className='text-primary font-bold hover:text-light_coral text-base cursor-pointer'
              onClick={() => {
                Mixpanel.track('Boton ir a Crear Viaje')
                window.location.href =
                  window.location.origin + `/${navigation === 'SearchLobby' && 'create'}`
              }}
            >
              {navigation === 'SearchLobby' ? 'Publicar Viaje' : 'Buscar Viaje'}
            </div>
          </div>
        </>
      )}
    </Drawer>
  )
}

export default DrawerRef
