export default ({ images }) => {
  return (
    <div className='min-w-12 max-w-12 min-h-12 max-h-12 overflow-hidden flex flex-row items-center justify-center rounded-full'>
      {images.length == 1 ? (
        <img className='w-12 h-12 object-cover ' src={images[0]} />
      ) : images.length == 2 ? (
        <>
          <div className='w-1/2 overflow-hidden'>
            <img className='left-0 w-12 h-12 object-cover' src={images[0]} />
          </div>
          <div className='w-1/2 overflow-hidden'>
            <img className='right-6 w-12 h-12 object-cover' src={images[1]} />
          </div>
        </>
      ) : images.length == 3 ? (
        <>
          <div className='w-6 overflow-hidden'>
            <img className='left-0 w-12 h-12 object-cover' src={images[0]} />
          </div>
          <div className='w-6 overflow-hidden'>
            <img className='w-6 h-6 object-cover' src={images[1]} />
            <img className='w-6 h-6 object-cover' src={images[2]} />
          </div>
        </>
      ) : (
        <>
          <div>
            <img className='w-6 h-6 object-cover' src={images[0]} />
            <img className='w-6 h-6 object-cover' src={images[1]} />
          </div>
          <div>
            <img className='w-6 h-6 object-cover' src={images[2]} />
            <img className='w-6 h-6 object-cover' src={images[3]} />
          </div>
        </>
      )}
    </div>
  )
}
