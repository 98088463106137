import Button from '../../../../commons/Button'
import Modal from 'react-modal'
import { IconButton } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { leave } from '../../../../content/lobby.js'

const LeaveLobbyScreen = ({ setLeaveLobbyAlert, leaveLobbyAlert, setLeaveLobby, leaveLobby, free }) => {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingRight: '0.3rem',
      paddingLeft: '0.3rem',
      paddingTop: '1rem',
      paddingBottom: '2rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: '#d65944',
      borderRadius: 0,
      borderWidth: 0,
    },
    overlay: {
      zIndex: 110,
      backgroundColor: 'rgba(0,0,0,0)',
    }
  }

  return (
    <Modal
      isOpen={leaveLobbyAlert}
      style={customStyles}
    >
      <div className='flex flex-col h-full w-full justify-between items-between' >
        <div className='pl-3'>
          <IconButton
            onClick={() => setLeaveLobbyAlert(false)}
            sx={{ color: '#fff' }}
            disabled={leaveLobby}
          >
            <ArrowBackIos />
          </IconButton>
        </div>
        <div className='pr-[2.5rem] pl-[2.5rem] sm:flex sm:flex-col sm:gap-5 sm:items-center sm:justify-center'>
          <img src={require('../../../../assets/img/alertLogo.png')} className='w-20 h-20 tall:w-28 tall:h-28 mb-6' />
          <h1 className='text-[#fff] text-xl medium:text-2xl mb-3 tall:text-4xl'>¿Estás seguro que quieres abandonar el viaje?</h1>
          <p className='text-[#fff] text-base medium:text-lg mb-3 tall:text-2xl tracking-wide'>Abandonar implica liberar tu lugar en el viaje.</p>
          <p className='text-[#fff] text-base medium:text-lg tall:text-2xl tracking-wide'>{free ? leave.free : leave.paid_for}</p>
        </div>
        <div className='h-[5%] medium:h-[15%] tall:h-[25%]' />
        <div className='flex flex-col pr-6 pl-6 sm:items-center'>
          <Button
            name={'Abandonar'}
            style={{ backgroundColor: '#fff', color: '#d65944', marginTop: 5 }}
            onClick={() => setLeaveLobby(true)}
            disabled={leaveLobby}
          />
        </div>
      </div>
    </Modal >
  )
}

export default LeaveLobbyScreen