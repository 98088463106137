import { DatePicker, Application } from 'react-rainbow-components'
import { Icon } from '@mui/material'
import rainbowTheme from '../../ProfileInfo/styles/rainbowTheme'


const EditProfileBirthday = ({ edit, selectedDate, setSelectedDate }) => {
  return (
    <div className='flex flex-col text-lg gap-2'>
      <p className='text-primary text-2xl font-thin text-xl'>Fecha de nacimiento</p>
      <Application theme={rainbowTheme}>
        <DatePicker
          icon={<Icon></Icon>}
          readOnly={!edit}
          value={selectedDate}
          maxDate={
            new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)
          }
          onChange={setSelectedDate}
          label='Fecha de nacimiento'
          formatStyle='medium'
          placeholder={'Fecha de nacimiento'}
          hideLabel
          selectionType='single'
        />
      </Application>
    </div>
  )
}

export default EditProfileBirthday
