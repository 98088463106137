import { Divider } from '@mui/material'
import { isoDateToWeekday } from '../../utils'
import FirstRow from './FirstRow'
import SecondRow from './SecondRow'
import ThirdRow from './ThirdRow'
import { useState } from 'react'

const UserBusCard = ({ bus, onClick }) => {
  const [isPressed, setIsPressed] = useState(false)
  const date = isoDateToWeekday(bus.Journey.departure_time)
  const departureTime = bus.Journey.departure_time.split('T')[1].slice(0, 5)
  const arrivalTime = bus.Journey.arrival_time.split('T')[1].slice(0, 5)

  const handleMouseDown = () => {
    setIsPressed(true)
  }

  const handleMouseUp = () => {
    setIsPressed(false)
  }

  const handleClick = () => {
    onClick(bus)
  }

  return (
    <div
      className={`flex flex-col text-lg h-60 mb-7 mx-4 pb-4 pt-3 sm:mx-20 md:mx-40 lg:mx-80 cursor-pointer rounded-xl shadow-lg ${isPressed && 'opacity-50'}`}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => setIsPressed(false)}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
    >
      <FirstRow picture={bus.Company_info.picture} date={date} />
      <SecondRow departureTime={departureTime} arrivalTime={arrivalTime} origin={bus.Journey.origin} destination={bus.Journey.destination} />
      <Divider sx={{ borderStyle: 'dashed', borderBottomWidth: 2, borderColor: '#9ca3af', marginTop: 3, marginBottom: 3 }} />
      <ThirdRow passengers={bus.passengers.length} boardingStation={bus.Journey.boarding_station.name} />
    </div>
  )
}

export default UserBusCard
