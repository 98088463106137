import { useEffect, useState } from 'react'
import Price from './Price'
import Seats from './Seats'
import Stops from './Stops'
import Return from './Return'
import LobbyInfo from '../../commons/LobbyInfo'
import { useAuth, useAuthorizedFetch, useFetch } from '../../hooks/'
import apiConfig from '../../config/Api'
import PaymentMethod from './PaymentMethod'
import Location from './Location'
import DatePicker from './Date'
import Time from './Time'
import BackButton from '../../commons/BackButton'
import Comment from './Comment'
import { toDate } from '../../utils'
import { Mixpanel } from '../../Mixpanel'
import Snackbar from '../../commons/Snackbar'

const CreationSteps = ({ initialValues }) => {
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded')
  const [navigation, navigate] = useState('origin')
  const [origin, setOrigin] = useState(initialValues?.origin)
  const [destination, setDestination] = useState(initialValues?.destination)
  const [originName, setOriginName] = useState(initialValues?.origin_name)
  const [destinationName, setDestinationName] = useState(initialValues?.destination_name)
  const [departureDate, setDepartureDate] = useState(
    initialValues ? toDate(initialValues.departure_time) : new Date()
  )
  const [departureTime, setDepartureTime] = useState('09:00')
  const [comment, setComment] = useState('')
  const [seats, setSeats] = useState(3)
  const [currency, setCurrency] = useState('UYU')
  const [price, setPrice] = useState()
  const [stops, setStops] = useState([])
  const [lobbyInfo, setLobbyInfo] = useState(false)
  const [lobby, setLobby] = useState()
  const [returnLobby, setReturnLobby] = useState()
  const [selectedReturnDate, setSelectedReturnDate] = useState()
  const [selectedReturnTime, setSelectedReturnTime] = useState('18:00')
  const [returnSeats, setReturnSeats] = useState(1)
  const [returnLobbyStep, setReturnLobbyStep] = useState(false)
  const [createReturnLobby, setCreateReturnLobby] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [lastCreatedLobbies, setLastCreatedLobbies] = useState([])
  const [lastCreatedLobby, setLastCreatedLobby] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const { user } = useAuth()
  const { data } = useFetch(`${apiConfig.uri}/users/id/${user.sub}`)
  const { loading, response } = useAuthorizedFetch(`${apiConfig.uri}/users/me/lobbies/last-created`)

  const handleClose = () => {
    if (embedded) {
      window.ReactNativeWebView?.postMessage('!creationSteps')
    } else {
      window.location.href = '/'
    }
  }

  const handleCreateLobby = () => {
    const date = departureDate.toISOString()
    const departureDateTime =
      date.substr(0, date.indexOf('T') + 1) + departureTime + date.substr(date.indexOf('T') + 6)

    const formattedOrigin = origin.lat + ', ' + origin.lng
    const formattedDestination = destination.lat + ', ' + destination.lng
    setLobby({
      seats_available: seats,
      Owner_info: {
        picture: data.picture,
        first_name: data.first_name,
        id: user.sub,
        verified: data.verified,
        stars: data.stars,
        reviews: data.reviews,
        car: data.car
      },
      Journey: {
        origin: formattedOrigin,
        destination: formattedDestination,
        origin_name: originName,
        destination_name: destinationName,
        departure_time: departureDateTime,
        stops
      },
      price,
      currency,
      payment: paymentMethod,
      comment,
      companions: []
    })
    if (createReturnLobby) {
      let returnDate = selectedReturnDate.toISOString()
      const returnDateTime =
        returnDate.substr(0, returnDate.indexOf('T') + 1) +
        selectedReturnTime +
        returnDate.substr(returnDate.indexOf('T') + 6)
      setReturnLobby({
        seats_available: returnSeats,
        Owner_info: {
          picture: data.picture,
          first_name: data.first_name,
          id: user.sub,
          verified: data.verified
        },
        Journey: {
          origin: formattedDestination,
          destination: formattedOrigin,
          origin_name: destinationName,
          destination_name: originName,
          departure_time: returnDateTime,
          stops: stops
        },
        price,
        currency,
        payment: paymentMethod,
        comment: '',
        companions: []
      })
    } else {
      setReturnLobby()
    }
    setLobbyInfo(true)
  }

  const handleLastCreatedLobby = lobby => {
    const formattedStops = lobby.Journey.stops.map(stop => {
      const location = stop.location.coordinates[0] + ', ' + stop.location.coordinates[1]
      return { location, location_name: stop.location_name }
    })

    const time = lobby.Journey.departure_time.substr(11, 5)
    const [hours, minutes] = time.split(':').map(Number)

    const roundedMinutes = Math.ceil(minutes / 5) * 5

    setOrigin({
      lat: lobby.Journey.origin.coordinates[0],
      lng: lobby.Journey.origin.coordinates[1]
    })
    setDestination({
      lat: lobby.Journey.destination.coordinates[0],
      lng: lobby.Journey.destination.coordinates[1]
    })
    setOriginName(lobby.Journey.origin_name)
    setDestinationName(lobby.Journey.destination_name)
    setStops(formattedStops)
    setDepartureTime(
      hours.toString().padStart(2, '0') + ':' + roundedMinutes.toString().padStart(2, '0')
    )
    setPrice(lobby.price)
    setCurrency(lobby.currency)
    setSeats(lobby.seats_available)
    setComment(lobby.comment)
    setPaymentMethod(lobby.payment)

    setLastCreatedLobby(true)
    Mixpanel.track('Repeat lobby', {
      Lobby: lobby
    })
    navigate('date')
  }

  useEffect(() => {
    if (initialValues) {
      navigate('stops')
    }
    if (embedded) {
      window.ReactNativeWebView?.postMessage('creationSteps')
    }
  }, [])

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setLastCreatedLobbies(data)
      })
    }
  }, [loading])

  return (
    <>
      <div className='w-full h-screen absolute top-0 z-30 text-center bg-white py-3'>
        {navigation == 'origin' ? (
          <>
            <BackButton handleBack={handleClose} />
            <Location
              key={'origin'}
              type={'origin'}
              handleSelect={() => {
                Mixpanel.track('Origen seleccionado', {
                  Origen: originName.formatted_address
                })
                navigate('destination')
                setPrice()
              }}
              setLocation={setOrigin}
              locationName={originName}
              setLocationName={setOriginName}
              lastCreatedLobbies={lastCreatedLobbies}
              loadingLastCreatedLobbies={loading}
              handleLastCreatedLobby={handleLastCreatedLobby}
            />
          </>
        ) : navigation == 'destination' ? (
          <>
            <BackButton handleBack={() => navigate('origin')} />
            <Location
              key={'destination'}
              type={'destination'}
              handleSelect={locationName => {
                if (
                  originName.locality === locationName.locality &&
                  !originName.formatted_address.includes('LATU') &&
                  !locationName.formatted_address.includes('LATU')
                ) {
                  setErrorMessage('No puedes publicar viajes urbanos dentro de la misma localidad')
                  Mixpanel.track('Error Misma Localidad', {
                    Origen: originName,
                    Destino: locationName
                  })
                } else {
                  Mixpanel.track('Destino seleccionado', {
                    Destino: locationName.formatted_address
                  })
                  navigate('stops')
                  setPrice()
                }
              }}
              setLocation={setDestination}
              locationName={destinationName}
              setLocationName={setDestinationName}
            />
          </>
        ) : navigation == 'stops' ? (
          <Stops
            handleSubmit={() => {
              Mixpanel.track('Paradas seleccionadas', {
                Paradas: stops
              })
              navigate('date')
            }}
            stops={stops}
            setStops={setStops}
            origin={originName}
            destination={destinationName}
            navigate={navigate}
          />
        ) : navigation == 'date' ? (
          <>
            <BackButton
              handleBack={() => {
                if (lastCreatedLobby) {
                  setLastCreatedLobby(false)
                  setOrigin()
                  setDestination()
                  setOriginName()
                  setDestinationName()
                  setStops([])
                  navigate('origin')
                } else {
                  navigate('stops')
                }
              }}
            />
            <DatePicker
              date={departureDate}
              setDate={setDepartureDate}
              handleSelect={() => {
                Mixpanel.track('Fecha seleccionada', {
                  Fecha: departureDate
                })
                navigate('time')
              }}
            />
          </>
        ) : navigation == 'time' ? (
          <>
            <BackButton handleBack={() => navigate('date')} />
            <Time
              time={departureTime}
              setTime={setDepartureTime}
              handleSelect={() => {
                Mixpanel.track('Hora seleccionada', {
                  Hora: departureTime
                })
                if (lastCreatedLobby) {
                  navigate('comment')
                } else {
                  navigate('seats')
                }
              }}
            />
          </>
        ) : navigation == 'seats' ? (
          <>
            <Seats
              seats={seats}
              setSeats={setSeats}
              setReturnSeats={setReturnSeats}
              comment={comment}
              setComment={setComment}
              handleSubmit={() => {
                Mixpanel.track('Asientos seleccionados', {
                  Asientos: seats
                })
                navigate('price')
              }}
              handleBack={() => navigate('time')}
            />
          </>
        ) : navigation == 'price' ? (
          <>
            <BackButton handleBack={() => navigate('seats')} />
            <Price
              setPrice={setPrice}
              price={price}
              setCurrency={setCurrency}
              currency={currency}
              handleSubmit={() => {
                Mixpanel.track('Precio seleccionado', {
                  Precio: price,
                  Moneda: currency
                })
                navigate('return')
              }}
              dataToFetch={{
                origin: origin.lat + ', ' + origin.lng,
                destination: destination.lat + ', ' + destination.lng,
                origin_country_code: originName.country_code,
                destination_country_code: destinationName.country_code
              }}
            />
          </>
        ) : navigation == 'return' ? (
          <Return
            handleSubmit={returnLobby => {
              navigate('comment')
              setCreateReturnLobby(returnLobby)
            }}
            selectedReturnDate={selectedReturnDate}
            selectedReturnTime={selectedReturnTime}
            setSelectedReturnDate={setSelectedReturnDate}
            setSelectedReturnTime={setSelectedReturnTime}
            returnSeats={returnSeats}
            setReturnSeats={setReturnSeats}
            departureDate={departureDate}
            onBack={() => navigate('price')}
            step={returnLobbyStep}
            setStep={setReturnLobbyStep}
          />
        ) : navigation == 'comment' ? (
          <>
            <BackButton handleBack={() => navigate('return')} />
            <Comment
              comment={comment}
              setComment={setComment}
              handleSubmit={() => {
                Mixpanel.track('Comentario seleccionado', {
                  Comentario: comment
                })
                if (price == 0 || currency !== 'UYU' || lastCreatedLobby) {
                  handleCreateLobby()
                } else {
                  navigate('payment')
                }
              }}
            />
          </>
        ) : (
          navigation == 'payment' && (
            <>
              <BackButton handleBack={() => navigate('comment')} />
              <PaymentMethod
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                handleSubmit={() => {
                  Mixpanel.track('Metodo de pago seleccionado', {
                    Metodo: paymentMethod
                  })
                  handleCreateLobby()
                }}
              />
            </>
          )
        )}
        {lobbyInfo && (
          <LobbyInfo
            visible={lobbyInfo}
            setVisible={setLobbyInfo}
            lobby={lobby}
            returnLobby={returnLobby}
            type='create'
          />
        )}
        <Snackbar message={errorMessage} setMessage={setErrorMessage} type='error' />
      </div>
    </>
  )
}

export default CreationSteps
