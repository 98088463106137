import { faBus, faCar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tabs as MuiTabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'

const Tabs = ({ selectedTab, handleChange, lobbiesAndBusesLength, lobbiesLength, busesLength }) => {
  const classes = useStyles()

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <MuiTabs
        classes={{ indicator: classes.customIndicator, flexContainer: classes.customFlexContainer }}
        value={selectedTab}
        onChange={handleChange}
      >
        <Tab
          classes={{ selected: classes.customTab }}
          icon={
            <div className='flex flex-row gap-1'>
              <FontAwesomeIcon icon={faBus} />
              <FontAwesomeIcon icon={faCar} />
            </div>
          }
          label={<p>{lobbiesAndBusesLength}</p>}
          value={'lobbiesAndBuses'}
        />
        <Tab
          classes={{ selected: classes.customTab }}
          icon={<FontAwesomeIcon icon={faCar} />}
          label={<p>{lobbiesLength}</p>}
          value={'lobbies'}
        />
        <Tab
          classes={{ selected: classes.customTab }}
          icon={<FontAwesomeIcon icon={faBus} />}
          label={<p>{busesLength}</p>}
          value={'buses'}
        />
      </MuiTabs>
    </Box>
  )
}

const useStyles = makeStyles({
  customTab: {
    '&.Mui-selected': {
      color: '#d65944',
    },
  },
  customIndicator: {
    backgroundColor: '#d65944',
  },
  customFlexContainer: {
    justifyContent: 'space-around',
  },
})

export default Tabs