import { ArrowForwardIos, InfoOutlined } from '@mui/icons-material'
import { Mixpanel } from '../../Mixpanel'

export default ({ embedded, selectedChannel, userId }) => {
  const { lobby_id: lobbyId, type, journey, users } = selectedChannel || {}
  const role = users?.find(user => user.user_id === userId)?.role

  const handleClick = () => {
    if (embedded) {
      if (type == 'direct-message') {
        if (journey) {
          window.ReactNativeWebView?.postMessage(
            `go_to_lobby_${lobbyId}_${journey.start}_${journey.end}`
          )
        } else {
          window.ReactNativeWebView?.postMessage(`go-to-lobby-${lobbyId}`)
        }
      } else {
        window.ReactNativeWebView?.postMessage(`go-to-user-lobby-${lobbyId}`)
      }
    } else {
      Mixpanel.track('Botón Chat Ir al viaje', {
        Lobby: lobbyId
      })
      if (journey) {
        window.location.href = `${window.location.origin}/join/${lobbyId}?start=${journey.start}&end=${journey.end}`
      } else {
        window.location.href = `${window.location.origin}/trips/${lobbyId}`
      }
    }
  }

  if (!lobbyId) return null

  return (
    <div className='w-full flex flex-row'>
      <div className='w-1/3 hidden md:inline-block' />
      <div
        className='flex flex-row items-center justify-between py-4 px-6 w-full md:w-2/3 self-end cursor-pointer'
        onClick={handleClick}
      >
        <div className='flex flex-row gap-4'>
          <InfoOutlined sx={{ color: '#d65944' }} />
          <p className='text-primary text-base'>
            {role === 'companion' && type === 'direct-message' ? 'Reservar viaje' : 'Ir al viaje'}
          </p>
        </div>
        <ArrowForwardIos sx={{ color: '#d65944', fontSize: 16 }} />
      </div>
    </div>
  )
}
