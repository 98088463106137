import Genders from "../../Genders"
import { PhoneIphoneRounded, VerifiedUser } from "@mui/icons-material"
import styles from "../../UserInfo/styles"

const ProfileView = ({ firstName, lastName, gender, age, phoneNumber, verified }) => {
  return (
    <div style={styles.nameContainer}>
      <div style={styles.nameSubcontainer}>
        <p style={styles.nameText}>{firstName} {lastName}</p>
        {
          verified &&
          <VerifiedUser sx={{ color: '#d65944', fontSize: 30 }} />
        }
      </div>
      <p style={styles.ageText}>{age} años</p>
      {
        phoneNumber &&
        <div style={styles.phoneContainer} >
          <PhoneIphoneRounded sx={{ color: '#d65944', fontSize: 20 }} />
          <p style={styles.ageText}>{phoneNumber}</p>
        </div>
      }
    </div>)
}

export default ProfileView
