import { IconButton } from "@mui/material"
import { Mixpanel } from "../../../Mixpanel"
import { EditOutlined, Close, ArrowBackIos } from "@mui/icons-material"
import handleClose from "../../ProfileInfo/events/handleClose"


const EditProfileButton = ({ user, edit, setEdit, disabled, setVisible }) => {
  return (
    <div className='flex justify-between'>
      <IconButton onClick={() => handleClose(user, setVisible)} style={{ paddingTop: 10 }} sx={{ color: '#d65944' }} >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        onClick={() => {
          Mixpanel.track('Boton editar Perfil', {
            'Usuario': user,
          })
          setEdit(true)
        }}
        disabled={edit}
        style={{ paddingTop: 10 }}
        sx={{ color: '#d65944' }}
      >
        <EditOutlined fontSize='large' />
      </IconButton>
    </div>
  )
}

export default EditProfileButton



