import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/'
import { useAuthorizedFetch } from '../../hooks'
import Modal from 'react-modal'
import { uri } from '../../config/Api'
import SearchError from '../../commons/SearchError'
import { IconButton } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'
import LoadingIndicator from '../../commons/LoadingIndicator'
import styles from './styles'
import UserBusCard from '../UserBusCard'
import BusInfo from '../../commons/BusInfo'
import { Mixpanel } from '../../Mixpanel'

const UserTickets = ({ visible, setVisible }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/users/me/buses`)
  const [buses, setBuses] = useState([])
  const [selectedBus, setSelectedBus] = useState()

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setBuses(data)
      })
    }
  }, [loading])

  const handleSelectBus = (bus) => {
    Mixpanel.track('Click User Bus Card', {
      'Bus': bus,
    })
    setSelectedBus(bus)
  }

  return (
    <>
      <Modal
        isOpen={visible}
        style={styles}
      >
        <div className='flex flex-row justify-between items-center pt-2 pl-2'>
          <IconButton
            onClick={() => {
              window.history.pushState({}, '', '/')
              setVisible(false)
            }}
            sx={{ color: '#f25c45', paddingTop: 0, paddingBottom: 0 }} >
            <ArrowBackIosNew />
          </IconButton>
          <div className='flex text-2xl text-primary'>Mis Pasajes</div>
          <div className='w-8' />
        </div>
        <>
          {
            loading ?
              <div className='h-full flex justify-center items-center'>
                <LoadingIndicator />
              </div>
              :
              response.ok ?
                <>
                  <div className='h-full overflow-auto'>
                    {
                      buses.length == 0 ?
                        <div className='flex flex-col items-center justify-center h-full'>
                          <img src={require('../../assets/img/buses.png')} alt='empty' className='w-80' />
                          <p className='text-center text-gray-500 text-lg'>Empezá a viajar y verás tus pasajes aquí</p>
                        </div>
                        :
                        <>
                          {
                            buses?.map(bus => (
                              <UserBusCard bus={bus} onClick={handleSelectBus} />
                            ))
                          }
                        </>
                    }
                  </div>
                </>
                :
                <SearchError />
          }
        </>
      </Modal>
      {
        selectedBus &&
        <BusInfo setSelectedBus={setSelectedBus} bus={selectedBus} />
      }
    </>
  )
}

export default UserTickets
