const stylePublished = (published) => {
  if (published == 0) {
    return 'No ha publicado viajes como conductor'
  } else if (published == 1) {
    return '1 viaje publicado'
  } else {
    return `${published} viajes publicados`
  }
}

export default stylePublished
