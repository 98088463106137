import { useEffect, useRef, useState } from 'react'
import { useAuthorizedFetch, useFetch } from '../../hooks'
import { Mixpanel } from '../../Mixpanel'
import BusSeatsSelector from '../BusSeatsSelector'
import Button from '../../commons/Button'
import LoadingIndicator from '../../commons/LoadingIndicator'
import Alert from '../../commons/Alert'
import Lottie from 'lottie-react'
import { uri } from '../../config/Api'
import styles from './styles'

export default ({ seats, setSeats, busId, transaction, setStep, price }) => {
  const { loading, data, responseOk } = useFetch(`${uri}/buses/seats/${busId}`)
  const seatsToSelect = seats.length
  const [busSeats, setBusSeats] = useState()
  const [firstFloor, setFirstFloor] = useState()
  const [secondFloor, setSecondFloor] = useState()
  const [selectedSeats, setSelectedSeats] = useState(seats)
  const [buttonHeight, setButtonHeight] = useState(116)
  const [changeAlert, setChangeAlert] = useState(false)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (responseOk) {
      setBusSeats(data.seats_map)
      setFirstFloor(data.first_floor)
      setSecondFloor(data.second_floor)
    }
  }, [loading])

  const handleSubmit = () => {
    if (JSON.stringify(selectedSeats) != JSON.stringify(seats)) {
      Mixpanel.track('Confirmar cambio de asientos', {
        'Asientos Anteriores': seats,
        'Asientos Nuevos': selectedSeats,
      })
      setSelectedSeats([...selectedSeats].sort((a, b) => a - b))
      setChangeAlert(true)
    } else {
      Mixpanel.track('Confirmar asientos', {
        'Asientos': selectedSeats,
      })
      setStep(3)
    }
  }

  useEffect(() => {
    const element = buttonRef.current
    if (element) {
      setButtonHeight(element.clientHeight)
    }
  }, [buttonRef, selectedSeats])

  return (
    <>
      {
        loading ?
          <div className='w-full h-full flex justify-center items-center'>
            <LoadingIndicator color={'#d65944'} />
          </div>
          :
          firstFloor &&
          <>
            <div className='w-full flex flex-col mt-4 gap-8 px-8 overflow-auto'>
              <p className='text-2xl text-center'>Seleccioná {seatsToSelect > 1 ? 'tus asientos' : 'tu asiento'}</p>
              <div style={{ ...styles.selectorContainer, marginBottom: buttonHeight + 30 }}>
                <BusSeatsSelector
                  selectedSeats={selectedSeats}
                  setSelectedSeats={setSelectedSeats}
                  seatsToSelect={seatsToSelect}
                  firstFloorMap={firstFloor}
                  secondFloorMap={secondFloor}
                  disabled={changeAlert}
                />
              </div>
            </div >
            <div style={styles.seatsButton} ref={buttonRef}>
              {
                busSeats?.find(s => selectedSeats.includes(s.number) && s.differential_rate > 0) &&
                <>
                  <p style={{ fontSize: 12, color: '#9ca3af', marginBottom: 15, textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>Algunos de los asientos que seleccionaste son cama, cuentan con un costo adicional.</p>
                  <p style={{ fontSize: 12, color: '#f25c45', marginBottom: 15, textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>Precio diferencial: ${price + price * (busSeats?.find(s => selectedSeats.includes(s.number) && s.differential_rate > 0).differential_rate / 100)}</p>
                </>
              }
              {
                !changeAlert ?
                  <Button
                    onClick={handleSubmit}
                    name={'Confirmar'}
                    disabled={selectedSeats.length != seatsToSelect}
                  />
                  :
                  <ChangeRequest transaction={transaction} seats={selectedSeats} setAlert={setChangeAlert} setSeats={setSeats} setStep={setStep} />
              }
            </div>
          </>
      }
    </>
  )
}

const ChangeRequest = ({ transaction, seats, setAlert, setSeats, setStep }) => {
  const [spinner, setSpinner] = useState(true)
  const { loading, response } = useAuthorizedFetch(`${uri}/buses/seats/${transaction}`, {
    method: 'PATCH',
    body: JSON.stringify({
      seats,
    })
  })

  useEffect(() => {
    if (!loading && response.ok) {
      Mixpanel.track('Cambio de asientos exitoso')
      setSpinner(false)
      setTimeout(() => {
        setSeats(seats)
        setStep(3)
      }, 2000)
    } else {
      Mixpanel.track('Cambio de asientos fallido')
    }
  }, [loading])

  return (
    <>
      {
        spinner &&
        <LoadingIndicator size={30} color={'#f25c45'} />
      }
      {
        !loading && response.ok ?
          <Lottie
            animationData={require('../../assets/animations/success.json')}
            autoPlay
            loop={false}
            style={{ width: 60, height: 60 }}
          />
          :
          !loading &&
          <Alert
            onSubmit={() => setAlert(false)}
          >
            <p className='text-xl'>¡Ups!</p>
            <p className='text-base'>Hubo un error. Por favor, intentá de nuevo o seleccioná {seats.length > 1 ? 'otros asientos' : 'otro asiento'}.</p>
          </Alert>

      }
    </>
  )
}