import { useState, useEffect } from 'react'
import { useAuthorizedFetch } from '../../hooks'
import apiConfig from '../../config/Api'
import { useAuth } from '../../hooks'
import { Mixpanel } from '../../Mixpanel'
import LoadingIndicator from '../../commons/LoadingIndicator'
import Lottie from 'lottie-react'
import Alert from '../../commons/Alert'
import SearchError from '../../commons/SearchError'


const ScheduleAlert = ({ searchData }) => {
  const { isAuthenticated, user } = useAuth()
  const [error, setError] = useState(false)
  const origin = searchData.origin.lat + ', ' + searchData.origin.lng
  const destination = searchData.destination.lat + ', ' + searchData.destination.lng
  const { loading, response } = useAuthorizedFetch(`${apiConfig.uri}/companions`, {
    method: 'POST',
    body: JSON.stringify({
      origin_name: searchData.originName.formatted_address,
      destination_name: searchData.destinationName.formatted_address,
      origin: origin,
      destination: destination,
      departure_time: searchData.selectedDate,
      seats: searchData.seats
    })
  })

  useEffect(() => {
    if (response.ok) {
      Mixpanel.track('Agendado Correctamente')
    } else {
      setError(true)
      Mixpanel.track('Agendar Error', {
        'Origin': searchData.originName,
        'Destination': searchData.destinationName,
        'Fecha': searchData.selectedDate,
        'Autenticado': isAuthenticated,
        'Usuario': user,
        'Error': response.status,
      })
    }
  }, [loading])

  const handleErrorClose = () => {
    setError(false)
  }

  return (
    <div className='flex justify-center my-3'>
      {
        loading ?
          <div className='flex flex-col items-center'>
            <LoadingIndicator size={30} />
          </div>
          :
          response.ok ?
            <Lottie
              animationData={require('../../assets/animations/success.json')}
              autoplay
              loop={false}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              style={{ width: 60, height: 60 }}
            />
            :
            <Alert onClose={handleErrorClose} visible={error}>
              <SearchError />
            </Alert>
      }
    </div>
  )
}

export default ScheduleAlert