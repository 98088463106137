import Preferences from '../../../commons/Preferences/index.js'

const EditProfilePreferences = ({ preferences, setPreferences, edit, gender }) => {
  return (
    <div className='flex flex-col text-lg gap-2'>
      <p className='text-primary text-2xl font-thin text-xl'>Preferencias al viajar</p>
      <Preferences
        preferences={preferences}
        setPreferences={setPreferences}
        readOnly={!edit}
        gender={gender}
      />
    </div>
  )
}

export default EditProfilePreferences
