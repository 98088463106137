import { ArrowBackIosNew } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useEffect } from 'react'

export default ({ setVisible, visible, children, handleClose, onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [])

  return (
    <>
      {visible && (
        <div className='w-full h-full absolute z-30 bg-white'>
          <div className='flex'>
            <IconButton onClick={handleClose} sx={{ color: '#f25c45' }}>
              <ArrowBackIosNew />
            </IconButton>
          </div>
          {children}
        </div>
      )}
    </>
  )
}
