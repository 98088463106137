import { ArrowBackIosNew, AttachMoney, PriceCheckOutlined, PublishedWithChangesRounded, RepeatOneOutlined, VerifiedUserOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Categories from './Categories.json'


const PaySafeScreen = ({ setVisible, type }) => {
  return (
    <>
      <div className='flex'>
        <IconButton onClick={() => setVisible(false)} sx={{ color: '#f25c45' }} >
          <ArrowBackIosNew />
        </IconButton>
      </div>
      <div className='flex flex-col overflow-auto pl-6 gap-7 mt-3 pr-6'>
        <p className='text-[1.5rem] text-bold'>{type == 'bus' ? 'Información del pago' : 'Garantizamos:'}</p>
        {
          type == 'bus' ?
            Object.values(Categories.bus).map((item, index) => <Category key={index} {...item} />)
            :
            Object.values(Categories.carpool).map((item, index) => <Category key={index} {...item} />)
        }
      </div>
    </>
  )
}

const Category = ({ icon, title, text }) => {
  return (
    <div className='flex flex-row gap-3 items-center mb-3'>
      {getIcon(icon)}
      <div className='flex flex-col gap-1'>
        <p className='text-[1.3rem] text-bold'>{title}</p>
        <p className='text-[1rem] font-thin'>{text}</p>
      </div>
    </div>
  )
}

const getIcon = (icon) => {
  switch (icon) {
    case 'attach-money':
      return <AttachMoney fontSize='large' sx={{ color: '#9ca3af' }} />
    case 'repeat-one':
      return <RepeatOneOutlined fontSize='large' sx={{ color: '#9ca3af' }} />
    case 'check':
      return <PriceCheckOutlined fontSize='large' sx={{ color: '#9ca3af' }} />
    case 'shield':
      return <VerifiedUserOutlined fontSize='large' sx={{ color: '#9ca3af' }} />
    case 'published-with-changes':
      return <PublishedWithChangesRounded fontSize='large' sx={{ color: '#9ca3af' }} />
    default:
      return <></>
  }
}

export default PaySafeScreen