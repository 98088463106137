import mixpanel from 'mixpanel-browser'
const params = new URLSearchParams(window.location.search)
const embedded = params.get('embedded') || localStorage.getItem('embedded')

if (embedded) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_APPMOBILE_TOKEN)
} else {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
}

// let env_check = process.env.NODE_ENV === 'production';
let env_check = true

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id)
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id)
  },
  getDistinctId: () => {
    if (env_check) mixpanel.get_distinct_id()
  },
  trackIp: () => {
    if (env_check) {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          Mixpanel.track('Ip', {
            ipAddress: data.ip,
            ipData: data
          })
        })
        .catch(() => {
          Mixpanel.track('Ip', {
            ipAddress: 'error al obtenerla'
          })
        })
    }
  },
  track: (name, props) => {
    if (env_check) {
      if (embedded) {
        mixpanel.track('Embedded:' + name, props)
      } else {
        mixpanel.track('App:' + name, props)
      }
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props)
    }
  }
}

export let Mixpanel = actions;