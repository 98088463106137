import Alert from '../../commons/Alert'
import apiConfig from '../../config/Api'
import { useFetchEditUser } from '../../hooks'
import SearchError from '../../commons/SearchError'
import { useAuth } from '../../hooks/'
import { Mixpanel } from '../../Mixpanel'
import { useEffect } from 'react'
import LoadingIndicator from '../../commons/LoadingIndicator'

const EditProfile = ({ userInfo, visible, setVisible }) => {
  var { user } = useAuth()

  useEffect(() => {
    if (userInfo.selectedDate) {
      userInfo.selectedDate = userInfo.selectedDate.toISOString()
    }
  }, [userInfo])

  const { loading, response } = useFetchEditUser(
    `${apiConfig.uri}/users/me`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        phone_number: userInfo.phoneNumber,
        birthdate: userInfo.selectedDate,
        gender: userInfo.gender,
        biography: userInfo.aboutMe,
        preferences: userInfo.preferences,
      }),
    }
  )

  useEffect(() => {
    if (response.ok) {
      Mixpanel.track('Perfil editado correctamente', {
        'Usuario': user,
      })
    } else if (response.ok == false) {
      Mixpanel.track('Error al editar perfil', {
        'Usuario': user,
        'Error': response.status
      })
    }
  }, [loading])

  return (
    <Alert
      visible={visible} onClose={() => setVisible(false)}
      hideButton={loading}
    >
      {
        loading ?
          <div className='h-full flex justify-center items-center'>
            <LoadingIndicator />
          </div>
          :
          response.ok ?
            <div>Información cargada con éxito!</div>
            :
            <SearchError />
      }
    </Alert>
  )
}

export default EditProfile
