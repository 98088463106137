import LoadingIndicator from '../../commons/LoadingIndicator'

const LoadingScreen = () => { //Show principal color background with viatik logo and loading animation
  return (
    <div
      style={{ backgroundColor: '#e55c42' }}
      className='w-screen h-screen flex justify-center items-center'
    >
      <div className='flex flex-col justify-center items-center'>
        <img
          src={require('../../assets/img/logo.png')}
          width='140px'
          height='43px'
        />
        <LoadingIndicator
          style={{ marginTop: -25 }}
          size={25}
          color='white'
        />
      </div>
      <div
      >

      </div>
    </div>
  )
}

export default LoadingScreen
