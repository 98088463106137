import styles from './styles'
import { PetsOutlined, SmokingRoomsOutlined, MusicNoteOutlined, Bedtime, Girl, PeopleRounded } from '@mui/icons-material'

const colors = {
  PRIMARY: '#D65944',
  LIGHT_GRAY: '#F3F4F6',
  LIGHT_CORAL: '#F25C45',
  BLACK: '#212427',
  GRAY: '#9CA3AF',
}

const Preferences = ({ preferences }) => {
  return (
    <>
      {
        preferences?.length > 0 &&
        <div style={styles.preferencesContainer}>
          {
            preferences?.map((preferenceName) => icons(preferenceName))
          }
        </div>
      }
    </>
  )
}

const icons = (name) => {
  switch (name) {
    case 'pets':
      return (
        <>
          {
            <div style={styles.preferences}>
              <PetsOutlined fontSize='medium' sx={{ color: colors.PRIMARY }} />
              <p style={styles.preferencesText}>Pet Friendly</p>
            </div>
          }
        </>
      )
    case 'smoke':
      return (
        <>
          {
            <div style={styles.preferences}>
              <SmokingRoomsOutlined fontSize='medium' sx={{ color: colors.PRIMARY }} />
              <p style={styles.preferencesText}>Fumar</p>
            </div>
          }
        </>
      )
    case 'music':
      return (
        <>
          {
            <div style={styles.preferences}>
              <MusicNoteOutlined fontSize='medium' sx={{ color: colors.PRIMARY }} />
              <p style={styles.preferencesText}>Con Música</p>
            </div>
          }
        </>
      )
    case 'conversation':
      return (
        <>
          {
            <div style={styles.preferences}>
              <PeopleRounded fontSize='medium' sx={{ color: colors.PRIMARY }} />
              <p style={styles.preferencesText}>Charlar</p>
            </div>
          }
        </>
      )
    case 'sleep':
      return (
        <>
          {
            <div style={styles.preferences}>
              <Bedtime fontSize='medium' sx={{ color: colors.PRIMARY }} />
              <p style={styles.preferencesText}>Dormir</p>
            </div>
          }
        </>
      )
    case 'girls':
      return (
        <>
          {
            <div style={styles.preferences}>
              <Girl fontSize='medium' sx={{ color: colors.PRIMARY }} />
              <p style={styles.preferencesText}>Viajar con mujeres</p>
            </div>
          }
        </>
      )
    default:
      return ''
  }
}

export default Preferences