import { ThemeProvider, Divider } from '@mui/material'
import theme from './theme'
import { ChevronRightRounded } from '@mui/icons-material'
import { Application, Calendar } from 'react-rainbow-components'
import TimePicker from '../../commons/TimePicker'
import { Mixpanel } from '../../Mixpanel'
import NextButton from './NextButton'
import BackButton from '../../commons/BackButton'
import Spinner from '../../commons/Spinner'

const Return = ({
  handleSubmit,
  departureDate,
  selectedReturnDate,
  selectedReturnTime,
  setSelectedReturnTime,
  setSelectedReturnDate,
  returnSeats,
  setReturnSeats,
  onBack,
  step,
  setStep
}) => {
  const rainbowTheme = {
    rainbow: {
      palette: {
        brand: '#f25c45'
      }
    }
  }

  const handleBack = () => {
    if (step == 0) {
      onBack()
    } else {
      setStep(step - 1)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <BackButton handleBack={handleBack} />
      <div className='flex flex-col px-6 mt-6 overflow-auto sm:items-center text-start'>
        {step == 0 ? (
          <>
            <div className='text-2xl text-start mb-12'>¿Planeás hacer un viaje de vuelta?</div>
            <div className='flex h-full flex-col justify-center'>
              <div className='flex flex-col gap-8'>
                <div
                  onClick={() => {
                    Mixpanel.track('Viaje de vuelta - Si')
                    setStep(1)
                  }}
                  className='flex flex-row w-full justify-between items-center cursor-pointer'
                >
                  <p className='text-light_coral'>Publicar viaje de retorno</p>
                  <ChevronRightRounded className='text-gray' />
                </div>
                <Divider />
                <div
                  onClick={() => {
                    Mixpanel.track('Viaje de vuelta - No')
                    handleSubmit(false)
                  }}
                  className='flex flex-row w-full justify-between items-center cursor-pointer'
                >
                  <p>No publicar viaje de retorno</p>
                  <ChevronRightRounded className='text-gray' />
                </div>
              </div>
            </div>
          </>
        ) : step == 1 ? (
          <>
            <div className='text-2xl text-start mb-12'>Elige la fecha de regreso</div>
            <Application theme={rainbowTheme}>
              <Calendar
                minDate={toDate(departureDate)}
                value={selectedReturnDate}
                onChange={date => {
                  setSelectedReturnDate(date)
                  setStep(2)
                }}
              />
            </Application>
            <NextButton onSubmit={() => setStep(2)} disabled={!selectedReturnDate} />
          </>
        ) : step == 2 ? (
          <>
            <div className='text-2xl text-start mb-6'>Hora de tu regreso</div>
            <TimePicker
              data={selectedReturnTime}
              setData={setSelectedReturnTime}
              style={{ fontSize: 40, alignText: 'center', justifyContent: 'center' }}
            />
            <NextButton onSubmit={() => setStep(3)} />
          </>
        ) : (
          step === 3 && (
            <>
              <div className='text-2xl text-start mb-12'>Asientos disponibles en tu regreso</div>
              <Spinner
                setNumber={setReturnSeats}
                number={returnSeats}
                label='Asientos'
                min={1}
                max={4}
                step={1}
              />
              <NextButton onSubmit={() => handleSubmit(true)} />
            </>
          )
        )}
      </div>
    </ThemeProvider>
  )
}

const toDate = dateString => {
  const date = new Date(dateString)
  return new Date(date.getTime() + 3 * 1000 * 60 * 60)
}

export default Return
