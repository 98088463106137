import { useEffect, useState } from 'react'
import { Mixpanel } from '../../Mixpanel'
import SearchError from '../../commons/SearchError'
import LoadingIndicator from '../../commons/LoadingIndicator'

export default ({ lobbiesRequest, busesRequest, handleSuccess, children }) => {
  const { loading: loadingLobbies, responseOk: responseOkLobbies, data: dataLobbies } = lobbiesRequest
  const { loading: loadingBuses, responseOk: responseOkBuses, response: responseBuses } = busesRequest
  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    if (!loadingLobbies && !loadingBuses) {
      if (responseOkLobbies && responseOkBuses) {
        responseBuses.json().then((dataBuses) => {
          handleSuccess(dataLobbies, dataBuses)
        })
      } else if (responseOkLobbies) {
        handleSuccess(dataLobbies, [])
      }
      else {
        Mixpanel.track('Request error', { 'Error': dataLobbies })
      }
      setTimeout(() => {
        setDataReady(true)
      }, 1000)
    }
  }, [loadingLobbies, loadingBuses])

  if (responseOkLobbies && dataReady) return children

  return (
    <>
      {
        loadingLobbies || loadingBuses || !dataReady ?
          <div className='flex h-[80%] w-full justify-center items-center'>
            <LoadingIndicator />
          </div>
          :
          <SearchError />
      }
    </>
  )
}