import { useState, useEffect } from 'react'
import SearchForm from '../components/SearchForm'
import Button from '../commons/Button'
import { useAuth } from '../hooks/'
import { Mixpanel } from '../Mixpanel'
import SearchResults from '../components/SearchResults'
import DownloadAppBanner from '../components/DownloadAppBanner'
import Snackbar from '../commons/Snackbar'

const SearchLobby = ({ initialValues, setDisabledMenu }) => {
  const [searchData, setSearchData] = useState({})
  const [showLobbies, setShowLobbies] = useState(false)
  const [missingParameters, setMissingParameters] = useState()
  const params = new URLSearchParams(window.location.search)
  const { isAuthenticated, user } = useAuth()

  const handleSubmit = () => {
    if (
      initialValues || (
        searchData.origin &&
        searchData.destination &&
        searchData.selectedDate
      )
    ) {
      setShowLobbies(true)
    } else if (searchData.origin) {
      setMissingParameters('Por favor, introduzca un lugar de destino')
    } else if (searchData.destination) {
      setMissingParameters('Por favor, introduzca un lugar de salida')
    } else {
      setMissingParameters('Por favor, introduzca un lugar de salida y un destino')
    }
  }
  useEffect(() => {
    if (initialValues && !params.get('showLobby')) {
      Mixpanel.track('Redirec a buscar', {
        'Origin': { coordinates: [initialValues.origin.lat, initialValues.origin.lng] },
        'Destination': { coordinates: [initialValues.destination.lat, initialValues.destination.lng] },
        'Origin Name': initialValues.originName,
        'Destination Name': initialValues.destinationName,
        'Date': initialValues.selectedDate,
        'Usuario': user
      })
      handleSubmit()
    }
  }, [])

  return (
    <div className='flex flex-col w-full h-full justify-between flex-grow'>
      <SearchForm
        setSearchData={setSearchData}
        selection='seats'
        initialValues={initialValues}
        dateType='single'
      />
      <div className='flex flex-col items-center md:justify-center md:flex-row w-full px-6 pt-10 pb-48 md:py-0 overflow-auto gap-6'>
        <div className='flex flex-col items-center justify-center text-center gap-3'>
          <img src={require('../assets/img/search.png')} className='w-80' />
          <p className='text-[#9ca3af] text-base px-6'>Buscá tu viaje y pagá de forma segura a través de Viatik</p>
        </div>
        <div className='flex flex-col items-center justify-center text-center gap-3'>
          <img src={require('../assets/img/imagen_chat.png')} className='w-80' />
          <p className='text-[#9ca3af] text-base px-6'>Conversá con los otros viajeros y coordiná el punto de encuentro</p>
        </div>
        <div className='flex flex-col items-center justify-center text-center gap-3'>
          <img src={require('../assets/img/trip.png')} className='w-80' />
          <p className='text-[#9ca3af] text-base px-6'>Disfrutá de un viaje más económico, sustentable y entretenido</p>
        </div>
      </div>
      {
        showLobbies ?
          <div className='w-full h-full absolute z-30 text-center flex flex-col justify-between mt-0'>
            <SearchResults
              visible={showLobbies}
              setVisible={setShowLobbies}
              searchData={searchData}
              initialValues={initialValues}
              setDisabledMenu={setDisabledMenu}
            />
          </div>
          :
          <div className='w-full text-center absolute bottom-0 z-30 md:relative'>
            <Button
              visible={showLobbies}
              name={'Buscar'}
              style={{ marginBottom: '17px' }}
              onClick={() => {
                Mixpanel.track('Boton de buscar', {
                  'Origin': { coordinates: [searchData.origin?.lat, searchData.origin?.lng] },
                  'Destination': { coordinates: [searchData.destination?.lat, searchData.destination?.lng] },
                  'Origin Name': searchData.originName,
                  'Destination Name': searchData.destinationName,
                  'Date': searchData.selectedDate,
                  'Usuario': user
                })
                if (params.get('lobbyId')) {
                  if (!searchData?.origin) {
                    window.location.href = `${window.location.origin}/?navigation=SearchLobby&searchData=${JSON.stringify(initialValues)}`
                  } else {
                    window.location.href = `${window.location.origin}/?navigation=SearchLobby&searchData=${JSON.stringify(searchData)}`
                  }
                }
                handleSubmit()
              }}
            />
            <DownloadAppBanner />
          </div>
      }
      <Snackbar
        message={missingParameters}
        setMessage={setMissingParameters}
        type='warning'
      />
    </div >
  )
}

export default SearchLobby
