import { useState, useEffect } from 'react'

const useFetch = (url, fetchInfo) => {
  const [loading, setLoading] = useState(true)
  const [responseOk, setResponseOk] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    setData([])
    setResponseOk(false)
    const fetchData = async () => {
      fetch(url, {
        ...fetchInfo
      })
        .then(response => response.json())
        .then(data => {
          setResponseOk(true)
          setData(data)
          setLoading(false)
        })
        .catch(e => {
          setData(e)
          setLoading(false)
        })
    }

    fetchData()
  }, [url])

  return { loading, data, responseOk }
}

export default useFetch
