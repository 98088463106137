import { Badge, Input } from '@mui/material'
import Avatar from '../Avatar'
import compress from 'compress-base64'
import { Mixpanel } from '../../Mixpanel'
import { AddPhotoAlternate } from '@mui/icons-material'

const ProfilePicture = ({ src, firstName, setImage, setPictureEdited, text }) => {
  return (
    <div className='flex flex-col justify-center px-10'>
      <label className='flex flex-col items-center hover:bg-gray-100 cursor-pointer rounded-xl px-3 pt-1'>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<AddPhotoAlternate fontSize='large' sx={{ color: '#d65944' }} />}
        >
          <Avatar alt={firstName} src={src} sx={{ width: 130, height: 130 }} />
        </Badge>
        <Input
          style={{ display: 'none', cursor: 'pointer' }}
          name='Cambiar Foto'
          type='file'
          onClick={() => Mixpanel.track('Boton cambiar Imagen')}
          onChange={e => {
            const file = e.target.files[0]
            if (file.type.includes('image')) {
              const reader = new FileReader()
              reader.onload = e => {
                compress(e.target.result, {
                  width: 200,
                  max: 200,
                  min: 0,
                  quality: 0.2
                })
                  .then(data => {
                    setImage(data)
                    setPictureEdited(true)
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }
              reader.readAsDataURL(file)
            }
          }}
        />
      </label>
    </div>
  )
}

export default ProfilePicture
