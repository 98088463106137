import { TextField, Radio, FormControl, RadioGroup, FormControlLabel } from "@mui/material"

const EditProfileGender = ({ genderSelection, setGenderSelection, setGender }) => {
  return (
    <div className='flex flex-col text-lg gap-2 items-center'>
      <p className='text-primary text-2xl font-thin text-xl'>Género</p>
      <FormControl>
        <RadioGroup
          row
          value={genderSelection}
          onChange={evt => {
            setGender(evt.target.value)
            setGenderSelection(evt.target.value)
          }}
        >
          <FormControlLabel
            value='Mujer'
            control={<Radio />}
            label='Mujer'
          />
          <FormControlLabel
            value='Hombre'
            control={<Radio />}
            label='Hombre'
          />
          <FormControlLabel
            value='Otro'
            control={<Radio />}
            label='Otro'
          />
        </RadioGroup>
      </FormControl>
      {
        genderSelection === 'Otro' &&
        <div>
          <TextField
            label='Genero (opcional)'
            size='small'
            variant='standard'
            onChange={e => {
              setGender(e.target.value)
              if (e.target.value == '') {
                setGender('Otro')
              }
            }}
          />
        </div>
      }
    </div>
  )
}

export default EditProfileGender
