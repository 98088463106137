import Modal from 'react-modal'
import styles from './styles'
import JoinMode from './JoinMode'
import ViewMode from './ViewMode'

const BusInfo = ({ setSelectedBus, bus, seats, join }) => {

  return (
    <Modal isOpen={true} style={styles} >
      {
        join ?
          <JoinMode bus={bus} seats={seats} setSelectedBus={setSelectedBus} />
          :
          <ViewMode bus={bus} setSelectedBus={setSelectedBus} />
      }
    </Modal>
  )
}

export default BusInfo