import { Alert, Snackbar } from '@mui/material'
import { useEffect } from 'react'


export default ({ message, setMessage, type }) => {
    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage()
            }, 2000)
        }
    }, [message])

    return (
        <Snackbar
            open={message}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={() => setMessage()}
                severity={type}
                variant='filled'
                sx={{ width: '100%' }}
            >
                {message}                   
            </Alert>
        </Snackbar>
    )
}
