import { Avatar, Badge } from '@mui/material'
import VerifiedBadge from './VerifiedBadge'

export default ({ alt, src, sx, props, verified, badgeSize }) => {

  return (
    <>
      {
        verified ?
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<VerifiedBadge size={badgeSize} />}
          >
            <Avatar
              alt={alt}
              src={src?.includes('data') ? src : src + `?${(new Date()).getTime()}`}
              sx={{ ...sx, borderWidth: badgeSize / 10, borderColor: '#f25c45', borderStyle: 'solid' }}
              {...props}
            />
          </Badge >
          :
          <Avatar
            alt={alt}
            src={src?.includes('data') ? src : src + `?${(new Date()).getTime()}`}
            sx={sx}
            {...props}
          />
      }
    </>
  )

}