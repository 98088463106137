export default (channels) => {
  return channels?.sort((a, b) => {
    const lastMessageA = a.messages[a.messages.length - 1]
    const lastMessageB = b.messages[b.messages.length - 1]
    if (lastMessageA.date > lastMessageB.date) {
      return -1
    }
    if (lastMessageA.date < lastMessageB.date) {
      return 1
    }
    return 0
  })
}