import { useFetch } from '../../hooks'
import { uri } from '../../config/Api'
import { useState, useEffect, useInsertionEffect } from 'react'
import { Mixpanel } from '../../Mixpanel'
import styles from './styles'
import { IconButton } from '@mui/material'
import { ArrowBackIos, StarOutlined } from '@mui/icons-material'
import Avatar from '../../commons/Avatar'
import UserInfo from '../UserInfo'
import LoadingIndicator from '../../commons/LoadingIndicator'
import Modal from 'react-modal'
import customStyles from './customStyles'

const UserReviews = ({ user, onClose, userInfo }) => {
  const { responseOk, data, loading } = useFetch(`${uri}/users/reviews/${user}`)
  const [userReviews, setUserReviews] = useState([])
  const [showUserInfo, setShowUserInfo] = useState(false)
  const [reviewerId, setReviewerId] = useState('')

  useInsertionEffect(() => {
    Mixpanel.track('UserReviews', {
      'Perfil de Usuario': user,
    })
  })

  useEffect(() => {
    if (responseOk) {
      setUserReviews(data)
    }
  }, [loading])

  const handleShowUserInfo = (reviewerId) => {
    setReviewerId(reviewerId)
    setShowUserInfo(true)
  }

  return (
    <>
      <Modal
        isOpen={true}
        style={customStyles}
      >
        {
          loading ?
            <div className='flex h-full justify-center items-center'>
              <LoadingIndicator />
            </div>
            :
            <>
              <div>
                <IconButton style={{ paddingTop: 10 }} onClick={onClose} sx={{ color: '#e65944' }}>
                  <ArrowBackIos />
                </IconButton>
              </div>
              <div style={styles.container}>
                <div style={styles.firstSubcontainer}>
                  <p style={styles.title}>Opiniones</p>
                  <div style={styles.starsContainer}>
                    <StarOutlined sx={{ color: '#9ca3af', fontSize: 24 }} />
                    <p style={styles.star}>{userInfo?.stars?.toFixed(1)} - {userInfo?.reviews} opiniones</p>
                  </div>
                  {
                    userReviews?.reviews?.map((review, index) => (
                      <Review
                        key={index}
                        id={review.reviewer.user_id}
                        picture={review.reviewer.picture}
                        name={review.reviewer.name}
                        qualification={review.qualification}
                        text={review.text}
                        date={review.date}
                        handleShowUserInfo={handleShowUserInfo}
                      />
                    ))
                  }
                  <SectionDivider />
                </div>
              </div>
            </>
        }
      </Modal>
      {
        showUserInfo &&
        <UserInfo visible={showUserInfo} user={reviewerId} onClose={() => setShowUserInfo(false)} />
      }
    </>
  )
}

const Review = ({ id, picture, name, qualification, text, date, handleShowUserInfo }) => {

  return (
    <>
      <SectionDivider />
      <div style={styles.opinionContainer}>
        <div style={styles.opinionHeader} onClick={() => handleShowUserInfo(id)}>
          <Avatar
            alt={name}
            src={picture}
            sx={{ width: 50, height: 50 }}
          />
          <p style={styles.opinionName}>{name}</p>
        </div>
        <div style={styles.opinionTextContainer}>
          <p style={styles.opinion}>{starsToText(qualification)}</p>
          <p style={styles.opinionText}>{text}</p>
          <p style={styles.opinionDate}>{formateDate(date)}</p>
        </div>
      </div>
    </>
  )
}

const SectionDivider = () => {
  return (
    <div style={styles.divider} />
  )
}

const formateDate = (dateString) => {
  const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
  const date = toDate(dateString)
  const day = date.getDate()
  const month = months[date.getMonth()]
  return `${day}/${month}`
}

const toDate = (dateString) => {
  if (!dateString) {
    return new Date()
  }
  const date = new Date(dateString)
  return new Date(date.getTime() + 3 * 1000 * 60 * 60)
}

const starsToText = (stars) => {
  const roundedStars = Math.round(stars)
  if (roundedStars === 1) return 'Muy malo'
  if (roundedStars === 2) return 'Malo'
  if (roundedStars === 3) return 'Correcto'
  if (roundedStars === 4) return 'Bueno'
  if (roundedStars === 5) return 'Excelente'
}



export default UserReviews