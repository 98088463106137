import Avatar from '../../commons/Avatar'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { CHAT_ADMIN_PROFILE } from '../../config/App'
import Button from '../../commons/Button'
import { Mixpanel } from '../../Mixpanel'

export default ({
  channelId,
  owner,
  sender,
  message,
  firstFromSender,
  lastFromSender,
  openUserInfo,
  embedded
}) => {
  const time = new Date(message.date).toLocaleTimeString('es-UY', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })

  return (
    <div
      className={`flex gap-2 items-end ${
        owner ? 'flex-row-reverse md:ml-10' : 'flex-row md:mr-10'
      } ${lastFromSender && 'mb-2'}`}
    >
      <div
        onClick={openUserInfo}
        className={`${message.user_id !== CHAT_ADMIN_PROFILE.user_id && 'cursor-pointer'}`}
      >
        <Avatar
          src={sender?.picture}
          alt={sender?.name[0]}
          sx={[{ width: 35, height: 35 }, !lastFromSender && { visibility: 'hidden' }]}
        />
      </div>
      <div className={bubbleClassName(owner, lastFromSender)}>
        {firstFromSender && (
          <p
            className={`text-base ${
              message.user_id !== CHAT_ADMIN_PROFILE.user_id && 'cursor-pointer'
            }`}
            onClick={openUserInfo}
          >
            {sender?.name}
          </p>
        )}
        {message.image && (
          <img
            src={message.image}
            alt='Imagen'
            className='w-full object-cover rounded-md max-w-[400px]'
          />
        )}
        <div className='flex flex-row w-full items-end justify-end gap-4'>
          {message.text && formatText(message.text)}
          {!message.button_text && (
            <p className={`text-xs font-thin ${owner ? 'text-white' : 'text-gray'}`}>{time}</p>
          )}
        </div>
        {message.button_text && (
          <BroadcastButton channelId={channelId} message={message} embedded={embedded} />
        )}
        {message.button_text && (
          <p className={`text-xs font-thin self-end ${owner ? 'text-white' : 'text-gray'}`}>
            {time}
          </p>
        )}
      </div>
      <div className='h-full flex items-end mb-1'>
        {message.not_sent && <LoadingIndicator size={12} />}
      </div>
    </div>
  )
}

const bubbleClassName = (owner, lastFromSender) => {
  let className = 'flex flex-col gap-3 py-2 px-3 rounded-t-xl'
  if (owner) {
    className += ' rounded-bl-xl bg-light_coral text-white'
  } else {
    className += ' rounded-br-xl bg-white text-black'
  }
  if (!lastFromSender) {
    className += ' rounded-b-xl'
  }

  return className
}

const formatText = text => {
  let formattedText = text
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
    .replace(/\*(.*?)\*/g, '<em>$1</em>') // Italic
    .replace(/\n/g, '&nbsp;<br>') // Newline handling

  return (
    <p
      className='text-sm whitespace-pre-wrap'
      dangerouslySetInnerHTML={{ __html: formattedText }}
    />
  )
}

const BroadcastButton = ({ channelId, message, embedded }) => {
  const { button_text, button_url } = message

  const handleClick = () => {
    Mixpanel.track('Click Boton Chat', {
      Chat: channelId,
      Texto: button_text,
      Url: button_url
    })
    if (embedded) {
      window.open(button_url)
    } else {
      if (button_url.includes('viatik://')) {
        window.location.href = button_url.replace('viatik://', window.location.origin)
      } else {
        window.open(button_url)
      }
    }
  }

  return (
    <div className='flex w-full items-center justify-center my-2'>
      <Button name={button_text} onClick={handleClick} style={{ fontSize: '16px' }} />
    </div>
  )
}
