export default (string) => {
  const phoneRegex = /[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4}/g
  const uyPhoneRegex = /(\+598|598|0)?(9[0-9]\d{6}\b)/
  const phoneNumberPattern = /\+?(\d[\d-\s]{6,}\d)/
  const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/gi

  if (phoneRegex.test(string) || emailRegex.test(string) || uyPhoneRegex.test(string) || phoneNumberPattern.test(string)) {
    return true
  }

  return false
}