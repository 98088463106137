import { useAuthorizedFetch } from '../../hooks'
import apiConfig from '../../config/Api'
import SearchError from '../SearchError'
import Button from '../Button'
import { useEffect, useState } from 'react'
import { Mixpanel } from '../../Mixpanel'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import Modal from 'react-modal'
import LoadingIndicator from '../LoadingIndicator'

const Create = ({ lobby, handleClose, returnLobby }) => {
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded') || localStorage.getItem('embedded')
  const { loading, response } = useAuthorizedFetch(`${apiConfig.uri}/lobbies`, {
    method: 'POST',
    body: JSON.stringify(lobby)
  })

  useEffect(() => {
    if (!loading) {
      if (response.ok) {
        const origin = lobby.Journey.origin_name.formatted_address
        const destination = lobby.Journey.destination_name.formatted_address
        const date = lobby.Journey.departure_time
        window.ReactNativeWebView?.postMessage(`created_${origin}_${destination}_${date}`)
        if (returnLobby) {
          Mixpanel.track('Viaje de vuelta creado', {
            Lobby: lobby
          })
        } else {
          Mixpanel.track('Viaje creado', {
            Lobby: lobby
          })
        }
      } else {
        if (returnLobby) {
          Mixpanel.track('Error al crear viaje de vuelta', {
            Lobby: lobby,
            Error: response.status
          })
        } else {
          Mixpanel.track('Error creando viaje', {
            Lobby: lobby,
            Error: response.status
          })
        }
      }
    }
  }, [loading])

  useEffect(() => {
    window.ReactNativeWebView?.postMessage('creating')
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingRight: '0.3rem',
      paddingLeft: '0.3rem',
      paddingTop: '3rem',
      paddingBottom: '2rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: '#d65944',
      borderRadius: 0,
      borderWidth: 0,
      border: 'none'
    },
    overlay: {
      zIndex: 40,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }

  return (
    <>
      {returnLobby ? (
        <></>
      ) : (
        <div className='w-full h-full absolute top-0 bg-primary px-2 text-start py-[3rem]'>
          {loading ? (
            <div className='flex h-full items-center justify-center'>
              <LoadingIndicator color='#fff' />
            </div>
          ) : (
            <>
              {response.ok ? (
                <div className='flex flex-col h-full justify-between items-between'>
                  <div className='pr-[2.5rem] pl-[2.5rem] sm:flex sm:flex-col sm:gap-5 sm:items-center sm:justify-center sm:px-[8rem]'>
                    <img
                      src={require('../../assets/img/checkmarkLogo.png')}
                      className='w-20 h-20 tall:w-28 tall:h-28 mb-6'
                    />
                    <h1 className='text-[#fff] text-2xl medium:text-3xl mb-3 tall:text-5xl'>
                      ¡Viaje publicado!
                    </h1>
                    <p className='text-[#fff] text-base medium:text-lg mb-3 tall:text-2xl tracking-wide sm:text-center'>
                      {text(lobby.price, lobby.currency)}
                    </p>
                  </div>
                  <div className='h-[5%] medium:h-[15%] tall:h-[25%]' />
                  <div className='flex flex-col pr-6 pl-6 sm:items-center'>
                    <Button
                      name={'Finalizar'}
                      style={{ backgroundColor: '#fff', color: '#d65944', marginTop: 5 }}
                      onClick={() => {
                        if (embedded) {
                          window.ReactNativeWebView?.postMessage('restart-create')
                        } else {
                          window.location.href = window.location.origin
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className='flex flex-col h-full justify-between'>
                  <div className='flex flex-col text-center pr-6 pl-6 justify-center h-4/5 overflow-auto text-[#fff]'>
                    <SearchError linkcolor={'#fff'} />
                  </div>
                  <div className='flex flex-col pr-6 pl-6 sm:items-center'>
                    <Button
                      name={'Volver a intentar'}
                      style={{ backgroundColor: '#fff', color: '#d65944', marginTop: 5 }}
                      onClick={() => {
                        window.ReactNativeWebView?.postMessage('creation-error')
                        handleClose()
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

const text = (price, currency) => {
  if (price > 0 && currency === 'UYU') {
    return 'Luego de que tu viaje finalice correctamente, tendrás que entrar a la app y marcar como completado el viaje. Recibirás el pago de los acompañantes que hayan participado en un plazo de 48 horas hábiles.'
  } else {
    return 'Si un acompañante se une, se creará un chat para que puedan coordinar los detalles del viaje.'
  }
}

export default Create
