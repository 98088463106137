import { useField } from 'formik'


const ValidationError = ({ ...props }) => {
    const spanStyle = {
        display: 'flex',
        justifyContent: 'start',
        fontWeight: 600,
        color: 'rgba(255, 130, 92)',
        height: '1.3rem',
        padding: '0 0.675rem',
    }

	const [field, meta] = useField(props)
	return (
		<span style={spanStyle} erros={field.name}>
			{meta.touched ? meta.error : ''}
		</span>
	)
}

export default ValidationError