import { useEffect, useRef, useState } from 'react'
import { useAuth, useAuthorizedFetch, useLocation } from '../../hooks'
import { socket_uri, uri } from '../../config/Api'
import Modal from 'react-modal'
import customStyles from './styles'
import ChannelList from '../ChannelList'
import ChannelNotSelected from '../ChannelNotSelected'
import Channel from '../Channel'
import { io } from 'socket.io-client'
import { Mixpanel } from '../../Mixpanel'
import { sortChannels } from '../../utils'
import RefetchChannels from './RefetchChannels'
import GoToLobby from './GoToLobby'
import Header from './Header'
import UserInfo from '../UserInfo'
import Snackbar from '../../commons/Snackbar'

const Messages = ({ visible, setVisible, embedded, hideHeader, initialChannel }) => {
  const { user } = useAuth()
  const [userId, setUserId] = useState(user?.sub)
  const [width, setWidth] = useState(window.innerWidth)
  const [channels, setChannels] = useState()
  const [selectedChannel, setSelectedChannel] = useState()
  const [connect, setConnect] = useState(false)
  const [socketDisconnected, setSocketDisconnected] = useState(false)
  const [firstConnection, setFirstConnection] = useState(false)
  const [refetchChannels, setRefetchChannels] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [userInfo, setUserInfo] = useState()
  const socket = useRef()
  const { pathname } = useLocation()
  const { loading, response } = useAuthorizedFetch(`${uri}/users/me/channels/connect`, {
    method: 'POST'
  })
  const { loading: loadingChannels, response: responseChannels } = useAuthorizedFetch(
    `${uri}/users/me/channels`
  )

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        if (data.connect) {
          Mixpanel.track('Chat conectado')
          socket.current = io(`${socket_uri}?authentication=${data.token}`, {
            reconnection: true,
            reconnectionDelay: 5000,
            reconnectionAttempts: Infinity
          })
          setConnect(true)
        } else {
          Mixpanel.track('Chat no conectado')
          setChannels([])
        }
      })
    } else if (response.ok === false) {
      Mixpanel.track('Error al conectar el chat', {
        Error: response
      })
      setChannels([])
    }
  }, [loading])

  useEffect(() => {
    if (connect && responseChannels.ok) {
      responseChannels.json().then(data => {
        Mixpanel.track('Chats obtenidos', {
          Chats: data
        })
        setChannels(data)
        if (initialChannel) {
          setSelectedChannel(data.find(channel => channel._id === initialChannel))
        }
      })
    } else if (responseChannels.ok === false) {
      Mixpanel.track('Error al obtener los chats', {
        Error: responseChannels
      })
      setChannels([])
    }
  }, [loadingChannels, connect])

  useEffect(() => {
    if (window.ReactNativeWebView?.postMessage) {
      if (selectedChannel) {
        window.ReactNativeWebView?.postMessage('channel-selected')
      } else {
        window.ReactNativeWebView?.postMessage('channel-not-selected')
      }
    }
  }, [selectedChannel])

  useEffect(() => {
    if (socket.current && channels && channels.length > 0) {
      socket.current.on('receive', data => receiveMessage(data))

      socket.current.on('deleted', data => deleteMessage(data))

      socket.current.on('disconnect', () => {
        setSocketDisconnected(true)
      })

      socket.current.on('connect', () => {
        if (firstConnection) {
          if (selectedChannel) {
            const channel = channels.find(channel => channel._id === selectedChannel._id)
            if (channel) {
              setChannels(
                changeChannels(
                  {
                    _id: selectedChannel._id,
                    message: channel.messages.map(message => {
                      return {
                        ...message,
                        not_sent: false
                      }
                    })
                  },
                  channels
                )
              )
            }
          }
        } else {
          setFirstConnection(true)
        }
        setRefetchChannels(true)
        setSocketDisconnected(false)
      })
    }

    return () => {
      if (socket.current) {
        socket.current.off('receive')
        socket.current.off('disconnect')
        socket.current.off('connect')
      }
    }
  }, [socket, channels, socketDisconnected])

  const receiveMessage = data => {
    const channel = channels.find(channel => channel._id === data.channel_id)
    if (channel) {
      setChannels(
        changeChannels(
          {
            _id: channel._id,
            messages: [...channel.messages, data]
          },
          channels
        )
      )
      if (window.ReactNativeWebView?.postMessage)
        window.ReactNativeWebView?.postMessage('new-message')
    } else {
      setRefetchChannels(true)
    }
  }

  const deleteMessage = data => {
    const channel = channels.find(channel => channel._id === data.channel_id)
    if (channel) {
      const updatedMessages = channel.messages.map(m => {
        if (m.id === data.message_id) {
          return {
            ...m,
            text: 'Mensaje eliminado por administrador'
          }
        }
        return m
      })
      setChannels(
        changeChannels(
          {
            _id: channel._id,
            messages: updatedMessages
          },
          channels
        )
      )
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))

    return () => window.removeEventListener('resize', () => setWidth(window.innerWidth))
  }, [])

  useEffect(() => {
    if (user) {
      setUserId(user.sub)
    }
  }, [user])

  useEffect(() => {
    if (pathname === '/messages') {
      setSelectedChannel()
    } else {
      const channel = channels?.find(channel => channel._id === pathname?.split('/')[2])
      setSelectedChannel(channel)
      setUserInfo(null)
    }
  }, [pathname])

  const handleUserInfoClose = () => {
    setUserInfo(null)
    window.history.pushState({}, '', `/messages/${selectedChannel?._id}` + window.location.search)
  }

  return (
    <>
      <Modal isOpen={visible} style={customStyles}>
        {width >= 768 ? (
          <div className='flex flex-col w-full h-full gap-2 hidden md:inline-block'>
            {!hideHeader && (
              <Header
                setUserInfo={setUserInfo}
                device='desktop'
                setVisible={setVisible}
                selectedChannel={selectedChannel}
                embedded={embedded}
                userId={userId}
              />
            )}
            {selectedChannel && (
              <GoToLobby selectedChannel={selectedChannel} embedded={embedded} userId={userId} />
            )}
            <div className='flex flex-row h-full'>
              <ChannelList
                setSelectedChannel={setSelectedChannel}
                selectedChannel={selectedChannel}
                channels={channels}
                user={userId}
              />
              {selectedChannel ? (
                <Channel
                  selectedChannel={selectedChannel}
                  selectedChannelId={selectedChannel?._id}
                  socket={socket}
                  setChannels={setChannels}
                  channels={channels}
                  user={userId}
                  socketDisconnected={socketDisconnected}
                  refetchChannels={refetchChannels}
                  setErrorMessage={setErrorMessage}
                  setUserInfo={setUserInfo}
                  embedded={embedded}
                />
              ) : (
                <ChannelNotSelected />
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col w-full h-full md:hidden'>
            {!hideHeader && (
              <Header
                setUserInfo={setUserInfo}
                device='mobile'
                selectedChannel={selectedChannel}
                setSelectedChannel={setSelectedChannel}
                setVisible={setVisible}
                embedded={embedded}
                userId={userId}
              />
            )}
            {selectedChannel && (
              <GoToLobby selectedChannel={selectedChannel} embedded={embedded} userId={userId} />
            )}
            {selectedChannel ? (
              <Channel
                selectedChannel={selectedChannel}
                selectedChannelId={selectedChannel?._id}
                socket={socket}
                setChannels={setChannels}
                channels={channels}
                user={userId}
                socketDisconnected={socketDisconnected}
                refetchChannels={refetchChannels}
                setErrorMessage={setErrorMessage}
                setUserInfo={setUserInfo}
                embedded={embedded}
              />
            ) : (
              <ChannelList
                setSelectedChannel={setSelectedChannel}
                selectedChannel={selectedChannel}
                channels={channels}
                user={userId}
              />
            )}
          </div>
        )}
        {refetchChannels && (
          <RefetchChannels setChannels={setChannels} setRefetchChannels={setRefetchChannels} />
        )}
        <Snackbar message={errorMessage} setMessage={setErrorMessage} type='error' />
      </Modal>
      {userInfo && <UserInfo visible={userInfo} user={userInfo} onClose={handleUserInfoClose} />}
    </>
  )
}

export default Messages

const changeChannels = (change, channels) => {
  const newChannels = channels.map(channel => {
    if (channel._id === change._id) {
      return {
        ...channel,
        ...change
      }
    } else {
      return channel
    }
  })
  return sortChannels(newChannels)
}
