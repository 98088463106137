import { Divider } from '@mui/material'
import { styles } from './styles'
import { StarRateRounded } from '@mui/icons-material'
import { useState } from 'react'

export default ({ setSelectedSeats, seatsToSelect, firstFloorMap, secondFloorMap, disabled, selectedSeats }) => {
  const firstFloorLayout = createSeatLayout(firstFloorMap)
  const secondFloorLayout = createSeatLayout(secondFloorMap)
  const [originalSeats, setOriginalSeats] = useState(selectedSeats)

  const handlePress = (number, available) => {
    if (!disabled && (available || originalSeats.includes(number))) {
      if (selectedSeats.includes(number)) {
        setSelectedSeats(selectedSeats.filter(seat => seat !== number))
      } else {
        if (selectedSeats.length < seatsToSelect) {
          setSelectedSeats([...selectedSeats, number])
        } else {
          setSelectedSeats(selectedSeats.slice(1).concat(number))
        }
      }
    }
  }

  const seatProps = (seat) => ({
    number: seat.number,
    available: seat.available || originalSeats.includes(seat.number),
    selected: selectedSeats.includes(seat.number),
    handlePress: () => handlePress(seat.number, seat.available),
    differentialRate: seat.differential_rate,
  })

  return (
    <div className='flex flex-col sm:flex-row sm:gap-10'>
      <div>
        {secondFloorLayout.length > 0 && <p style={{ fontSize: 22, paddingTop: 20, paddingBottom: 10, textAlign: 'center' }}>Primer Piso</p>}
        <div style={styles.layout} >
          <div style={{ ...styles.driver, marginBottom: 10 }}>
            <img src={require('../../assets/icons/steering-wheel.png')} className='w-8 h-8' />
          </div>
          <Divider sx={{ marginBottom: 2 }} />
          {firstFloorLayout.map((row, rowIndex) => (
            <div style={styles.seatRow}>
              {row.map((seat, colIndex) => {
                if (colIndex % 2 === 0) {
                  return (
                    <div style={styles.seatPair}>
                      {seat ? <Seat {...seatProps(seat)} /> : <div style={styles.emptySeat} />}
                      {row[colIndex + 1] ? <Seat {...seatProps(row[colIndex + 1])} /> : <div style={styles.emptySeat} />}
                    </div>
                  )
                }
              })}
            </div>
          ))}
        </div>
      </div>
      {
        secondFloorLayout.length > 0 &&
        <div>
          <p style={{ fontSize: 22, paddingTop: 20, paddingBottom: 10, textAlign: 'center' }}>Segundo Piso</p>
          <div style={{ ...styles.layout, paddingTop: 10 }} >
            {secondFloorLayout.map((row, rowIndex) => (
              <div style={styles.seatRow}>
                {row.map((seat, colIndex) => {
                  if (colIndex % 2 === 0) {
                    return (
                      <div style={styles.seatPair}>
                        {seat ? <Seat {...seatProps(seat)} /> : <div style={styles.emptySeat} />}
                        {row[colIndex + 1] ? <Seat {...seatProps(row[colIndex + 1])} /> : <div style={styles.emptySeat} />}
                      </div>
                    )
                  }
                })}
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

const Seat = ({ number, selected, handlePress, available, differentialRate }) => {

  return (
    <div style={selected ? styles.selectedSeat : !available ? styles.occupiedSeat : styles.seat} onClick={handlePress} >
      {
        differentialRate > 0 ?
          <div style={styles.containerDifferential}>
            <StarRateRounded sx={{ fontSize: 46, color: selected ? '#fff' : '#9ca3af' }} />
            <p style={selected ? styles.selectedSeatNumberDifferential : styles.seatNumberDifferential}>{number}</p>
          </div>
          :
          <p style={selected ? styles.selectedSeatNumber : styles.seatNumber}>{number}</p>
      }
    </div>
  )
}

const createSeatLayout = (seatsMap) => {
  if (!seatsMap) return []
  const rows = Math.max(...seatsMap.map(seat => parseInt(seat.x)))
  const columns = 4
  const seatLayout = Array.from({ length: rows }, () => Array(columns).fill(null))

  seatsMap.forEach(seat => {
    const x = parseInt(seat.x) - 1
    let y = parseInt(seat.y)

    if (y === 2) {
      return
    }

    if (y > 2) {
      y = y - 1
    }

    if (x < rows && y < columns) seatLayout[x][y] = seat
  })

  return seatLayout
}