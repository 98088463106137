import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import SendData from './SendData'
import { Mixpanel } from '../../Mixpanel'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import { IconButton } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'
import { useAuth } from '../../hooks/'
import { namespace } from '../../config/Auth0'

const Register = ({ visible, onSubmit, setProfileImg }) => {
  const { isAuthenticated, user } = useAuth()
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedDate, setSelectedDate] = useState(
    new Date((new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000))
  )
  const [gender, setGender] = useState('Mujer')
  const [picture, setPicture] = useState(user?.picture)
  const [aboutMe, setAboutMe] = useState('')
  const [preferences, setPreferences] = useState([])
  const [step, setStep] = useState(1)
  const [sendData, setSendData] = useState(false)

  let initialValues = {
    name: name,
    last_name: lastName,
    phone_number: phoneNumber,
  }

  useEffect(() => {
    if (isAuthenticated && user[namespace + 'phone_number']) {
      initialValues.name = user[namespace + 'first_name']
      setName(user[namespace + 'first_name'])
      initialValues.last_name = user[namespace + 'last_name']
      setLastName(user[namespace + 'last_name'])
      initialValues.phone_number = user[namespace + 'phone_number']
      setPhoneNumber(user[namespace + 'phone_number'])
    } else if (isAuthenticated) {
      if (user.given_name) {
        initialValues.name = user.given_name
        setName(user.given_name)
      }
      if (user.family_name) {
        initialValues.last_name = user.family_name
        setLastName(user.family_name)
      }
    }
  }, [])

  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      margin: 'auto',
      paddingLeft: '1rem',
      paddingRight: '0',
      paddingTop: '1.6rem',
      maxHeight: 540,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: 600,
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  }

  const handleSubmit = () => {
    setSendData(true)
    Mixpanel.track('Boton Confirmar registro', {
      'Nombre': name,
      'Imagen': user.picture,
      'Biografia': aboutMe,
      'Preferencias': preferences,
      'Usuario': user
    })
  }

  return (
    <Modal isOpen={visible} style={customStyles} contentLabel='Register'>
      {sendData ? (
        <SendData
          data={{ name, lastName, phoneNumber, selectedDate, gender, aboutMe, preferences }}
          onSubmit={onSubmit}
          onError={() => setSendData(false)}
        />
      ) : step === 1 ? (
        <FirstStep
          initialValues={initialValues}
          name={name}
          lastName={lastName}
          phoneNumber={phoneNumber}
          setName={setName}
          setLastName={setLastName}
          setPhoneNumber={setPhoneNumber}
          onSubmit={() => {
            if (phoneNumber.substring(0, 4) === '+598' && phoneNumber.charAt(4) == '0') {
              setPhoneNumber(phoneNumber.substring(0, 4) + phoneNumber.substring(5))
            } else {
              setPhoneNumber(phoneNumber)
            }
            setStep(2)
          }}
        />
      ) : step === 2 ? (
        <>
          <div>
            <IconButton onClick={() => setStep(1)}>
              <ArrowBackIosNew />
            </IconButton>
          </div>
          <SecondStep
            setStep={setStep}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            gender={gender}
            setGender={setGender}
          />
        </>
      ) : (
        <>
          <div>
            <IconButton onClick={() => setStep(2)}>
              <ArrowBackIosNew />
            </IconButton>
          </div>
          <ThirdStep
            picture={picture}
            setPicture={setPicture}
            aboutMe={aboutMe}
            setAboutMe={setAboutMe}
            onSubmit={handleSubmit}
            preferences={preferences}
            setPreferences={setPreferences}
            date={new Date(selectedDate)}
            gender={gender}
            setProfileImg={setProfileImg}
          />
        </>
      )}
    </Modal>
  )
}

export default Register
