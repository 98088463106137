import { useState, useEffect } from 'react'

export default () => {
  const [pathname, setPathname] = useState()

  useEffect(() => {
    const handleRouteChange = () => {
      setPathname()
      setTimeout(() => {
        setPathname(window.location.pathname)
      }, 100)
    }

    window.addEventListener('popstate', handleRouteChange)

    return () => {
      window.removeEventListener('popstate', handleRouteChange)
    }
  }, [])

  return { pathname }
}
