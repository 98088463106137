import addDays from './addDays'
import getToday from './getToday'

export default (date) => {
  const today = new Date().toLocaleDateString()
  const yesterday = new Date(addDays(getToday(), -1)).toLocaleDateString()
  const inputDate = new Date(date).toLocaleDateString()

  if (inputDate === today) {
    return 'Hoy'
  } else if (inputDate === yesterday) {
    return 'Ayer'
  } else {
    const month = new Date(date).toLocaleString('default', { month: 'short' })
    const day = new Date(date).getDate()
    return `${month} ${day}`
  }
}