import { Application, DatePicker } from 'react-rainbow-components'
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  Divider,
} from '@mui/material'
import Button from '../../commons/Button'
import { useState } from 'react'
import { Mixpanel } from '../../Mixpanel'

const SecondStep = ({
  setStep,
  selectedDate,
  setSelectedDate,
  gender,
  setGender,
}) => {
  const [selection, setSelection] = useState(gender != 'Mujer' && gender != 'Hombre' ? 'Otro' : gender)
  const maxDate = new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)

  const rainbowTheme = {
    rainbow: {
      palette: {
        brand: '#f25c45',
      }
    },
  }

  return (
    <>
      <div className='text-center text-lg h-4/5 pr-4'>
        <div className='text-xl text-center'>
          Necesitamos los siguientes datos para continuar
        </div>
        <div className='text-1xl text-center font-bold'>2/3</div>
        <div className='mt-5'>
          <p>Fecha de nacimiento</p>
          <Application theme={rainbowTheme}>
            <DatePicker
              value={selectedDate}
              maxDate={maxDate}
              onChange={setSelectedDate}
              label='Fecha de nacimiento'
              formatStyle='medium'
              placeholder={'Seleccionar fecha'}
              hideLabel
              selectionType='single'
            />
          </Application>
        </div>
        <div className='flex flex-col mt-5 justify-center items-center'>
          <p>Género</p>
          <FormControl>
            <RadioGroup
              row
              value={selection}
              onChange={evt => {
                setGender(evt.target.value)
                setSelection(evt.target.value)
              }}
            >
              <FormControlLabel
                value='Mujer'
                control={<Radio />}
                label='Mujer'
              />
              <FormControlLabel
                value='Hombre'
                control={<Radio />}
                label='Hombre'
              />
              <FormControlLabel
                value='Otro'
                control={<Radio />}
                label='Otro'
              />
            </RadioGroup>
          </FormControl>
          {selection === 'Otro' ? (
            <TextField
              label='Genero (opcional)'
              size='small'
              variant='standard'
              value={gender == 'Otro' ? '' : gender}
              onChange={(e) => {
                setGender(e.target.value)
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='flex flex-col mt-10 pr-4'>
        <Divider />
        <Button
          onClick={() => {
            Mixpanel.track('Boton registro 2do paso', {
              'Nacimiento': selectedDate.toISOString(),
              'Genero': gender
            })
            if (gender == '') {
              setGender('Otro')
            }
            setStep(3)
          }}
          name='Siguiente'
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            zIndex: 300,
          }}
        />
      </div>
    </>
  )
}

export default SecondStep
