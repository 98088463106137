import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import Button from '../../commons/Button'
import { Mixpanel } from '../../Mixpanel'
import { calculateAspectRatio, getParamsFromUrl } from '../../utils'

const JoinSuccess = ({ free }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  const carHorn = new Audio(require('../../assets/audio/car_horn.mp3'))

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    carHorn.volume = 0.1
    carHorn.play().catch(error => {
      document.addEventListener('click', () => {
        carHorn.play()
      }, { once: true })
    })
  }, [])

  const handleSubmit = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('embedded')) {
      window.ReactNativeWebView?.postMessage('restart-join')
    }
    else {
      if (!free) {
        const paramsObject = getParamsFromUrl(window.location.href)
        Mixpanel.track('Unirse lobby exitoso', {
          ...paramsObject,
        })
      }
      window.location.href = window.location.origin + '/trips'
    }
  }

  return (
    <div style={calculateAspectRatio(width, height) <= 0.7 ? { backgroundColor: '#f8ece9', height: height } : { backgroundColor: '#fff', height: height, paddingTop: 80 }} >
      <div className='text-center'>
        {
          calculateAspectRatio(width, height) <= 0.7 ?
            <Lottie
              animationData={require('../../assets/animations/joined_animation.json')}
              autoplay
              loop
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              style={{ height: '100%' }}
            />
            :
            <div className='flex justify-center'>
              <img src={require('../../assets/img/joined_lobby.png')} className='w-120 h-60' />
            </div>
        }
        <div className='flex flex-col px-6 gap-6'>
          <p className='text-2xl font-bold text-primary text-center'>¡Te has unido al viaje!</p>
          <p className='text-lg text-center'>Puedes coordinar el viaje en el apartado "Mensajes" y ver información de tu viaje en el apartado "Mis Viajes"</p>
        </div>
      </div>
      <div className='w-full bottom-5 absolute z-30 text-center'>
        <Button
          name='Continuar'
          onClick={handleSubmit}
          style={{ margin: 'auto', fontSize: 16 }}
        />
      </div>
    </div>
  )
}

export default JoinSuccess