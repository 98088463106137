import { website } from '../../config/App'
import { useAuth } from '../../hooks'
import { Mixpanel } from '../../Mixpanel'

export default ({
  setProfile,
  setAlertValue,
  setTrips,
  setTickets,
  setMessages,
  navigation,
  disabled
}) => {
  const { isAuthenticated, loginWithRedirect } = useAuth()

  return (
    <nav className={`w-full bg-white ${disabled && 'opacity-50'}`}>
      <div className='flex flex-row justify-between items-center px-6 w-full'>
        <div className='flex items-center mt-3 mb-1'>
          <img
            src={require('../../assets/img/orange-logo.png')}
            alt='logo'
            width={100}
            height={50}
            className={`mr-8 ${!disabled && 'cursor-pointer'}`}
            onClick={() => {
              if (disabled) return
              Mixpanel.track('Boton Logo')
              window.location.href = website
            }}
          />
          {!isAuthenticated ? (
            <>
              <TabButton
                name='Iniciar sesión'
                onClick={() => {
                  Mixpanel.track('Boton Iniciar de sesion')
                  loginWithRedirect({
                    appState: window.location.href
                  })
                }}
                disabled={disabled}
              />
              <TabButton
                name='Registrarme'
                onClick={() => {
                  Mixpanel.track('Boton Iniciar de sesion')
                  loginWithRedirect({
                    appState: window.location.href,
                    screen_hint: 'signup'
                  })
                }}
                disabled={disabled}
              />
            </>
          ) : (
            <AuthenticatedButtons
              setProfile={setProfile}
              setTrips={setTrips}
              setTickets={setTickets}
              setMessages={setMessages}
              disabled={disabled}
            />
          )}
        </div>
        <div className='flex items-center mt-2'>
          <TabButton
            name={navigation === 'SearchLobby' ? 'Publicar viaje' : 'Buscar viaje'}
            onClick={() => {
              Mixpanel.track('Boton ir a Crear Viaje')
              window.location.href =
                window.location.origin + `/${navigation === 'SearchLobby' && 'create'}`
            }}
            disabled={disabled}
          />
        </div>
        <div className='flex items-center mt-2 md:hidden'>
          <button
            disabled={disabled}
            onClick={() => setAlertValue(true)}
            data-collapse-toggle='navbar-default'
            type='button'
            className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-gray-200'
            aria-controls='navbar-default'
            aria-expanded='false'
          >
            <svg
              className='w-8 h-8'
              aria-hidden='true'
              fill='#d65944'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </nav>
  )
}

const AuthenticatedButtons = ({ setProfile, setTrips, setTickets, setMessages, disabled }) => {
  return (
    <>
      <TabButton
        name='Perfil'
        onClick={() => {
          Mixpanel.track('Boton Mi Perfil')
          window.history.pushState({}, '', '/profile')
          setProfile(true)
        }}
        disabled={disabled}
      />
      <TabButton
        name='Mis viajes'
        onClick={() => {
          Mixpanel.track('Boton Mis Viajes')
          window.history.pushState({}, '', '/trips')
          setTrips(true)
        }}
        disabled={disabled}
      />
      <TabButton
        name='Mis pasajes'
        onClick={() => {
          Mixpanel.track('Boton Mis Pasajes')
          window.history.pushState({}, '', '/tickets')
          setTickets(true)
        }}
        disabled={disabled}
      />
      <TabButton
        name='Mensajes'
        onClick={() => {
          Mixpanel.track('Boton Mensajes')
          window.history.pushState({}, '', '/messages')
          setMessages(true)
        }}
        disabled={disabled}
      />
    </>
  )
}

const TabButton = ({ name, onClick, disabled }) => {
  return (
    <div
      onClick={() => {
        if (disabled) return
        onClick()
      }}
      className={`py-2 pr-4 pl-3 text-primary font-bold md:inline-block hidden text-base ${
        !disabled && 'cursor-pointer hover:text-light_coral'
      }`}
    >
      {name}
    </div>
  )
}
