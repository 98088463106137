import { useAuth } from '../'
import { useState, useEffect } from 'react'
import auth0Config from '../../config/Auth0'

const useAuthorizedFetch = (url, userInfo) => {
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState([])
  const { getAccessTokenSilently } = useAuth()

  const fetchData = async (token) => {
    fetch(url, {
      ...userInfo,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + token,
      }
    })
      .then(response => {
        getAccessTokenSilently({ ignoreCache: true }) //Para que actualice los campos de auth0
        setResponse(response)
        setLoading(false)
      })
      .catch(err => {
        setResponse(err)
        setLoading(false)
      })
  }
  useEffect(() => {
    getAccessTokenSilently({
      audience: auth0Config.audience,
    })
      .then(token => fetchData(token))
      .catch(e => {
        setResponse('Error de autenticacion')
        setLoading(false)
      })
  }, [])
  return { loading, response }
}

export default useAuthorizedFetch
