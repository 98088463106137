const SearchError = ({ text, linkcolor }) => {
  let linkColor = linkcolor ?? '#f25c45'

  return (
    <div className='m-auto flex flex-col justify-center items-center text-xl px-6 gap-4' >
      <p>¡Ups!</p>
      <p>{text || 'Ha ocurrido un error, porfavor revisá tu conexión e intentá nuevamente.'}</p>
      <p>Si el error persiste contactate con nosotros.</p>
      <div className={`flex-col flex gap-2 text-[${linkColor}]`} >
        <a
          href='https://api.whatsapp.com/send/?phone=+59898415699&text=Hola,%20tuve%20un%20problema%20usando%20la%20app'
          target='_blank'>
          WhatsApp
        </a>
      </div>
    </div>
  )
}

export default SearchError