import { useEffect, useState } from 'react'
import { containsContactInfo, muiInputTheme } from '../../utils'
import { Mixpanel } from '../../Mixpanel'
import { IconButton, TextField, ThemeProvider } from '@mui/material'
import Button from '../../commons/Button'
import { ArrowBackIosNew } from '@mui/icons-material'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import LoadingIndicator from '../../commons/LoadingIndicator'
import Snackbar from '../../commons/Snackbar'

const AskDriver = ({ lobby, start, end, driver, goBack }) => {
  const [message, setMesssage] = useState('')
  const [sendMessage, setSendMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [error, setError] = useState()

  const handlePress = () => {
    if (message === '') {
      setErrorMessage('El mensaje no puede estar vacío')
    } else if (containsContactInfo(message)) {
      Mixpanel.track('Intento de enviar info de contacto', {
        'Mensaje': message,
      })
      setErrorMessage('El mensaje no puede contener información de contacto')
    } else {
      Mixpanel.track('Boton Enviar Pregunta a Conductor', {
        'Lobby': lobby,
        'Mensaje': message,
      })
      setSendMessage(true)
    }
  }

  const handleClose = () => {
    goBack()
  }

  return (
    <>
      <div>
        <IconButton onClick={handleClose} style={{ paddingTop: 10, paddingLeft: 10 }} sx={{ color: '#f25c45' }} >
          <ArrowBackIosNew />
        </IconButton>
      </div>
      <div className='flex flex-col h-full justify-between items-center overflow-auto py-4 w-full'>
        <div className='flex flex-col gap-2 items-center w-full'>
          <p className='text-xl'>Hacele una pregunta a</p>
          <p className='text-xl text-primary mb-3'>{driver.first_name}</p>
          <ThemeProvider theme={muiInputTheme}>
            <TextField
              placeholder={`Ej: Hola ${driver.first_name}! ¿Puedo llevar a mi perro?`}
              multiline
              size='large'
              value={message}
              minRows={3}
              onChange={evt => {
                if (evt.target.value.length >= 100) {
                  setMesssage(message)
                } else {
                  setMesssage(evt.target.value)
                }
              }}
              sx={{ width: '70%' }}
            />
          </ThemeProvider>
        </div>
        {
          sendMessage ?
            <div className='flex mb-4'>
              <SendMessage message={message} lobby={lobby} start={start} end={end} setError={setError} setSendMessage={setSendMessage} />
            </div>
            :
            <Button
              name={'Enviar'}
              onClick={handlePress}
              disabled={message.trim().length === 0}
            />
        }
        <Snackbar
          message={errorMessage}
          setMessage={setErrorMessage}
          type='warning'
        />
        <Snackbar
          message={error}
          setMessage={setError}
          type='error'
        />
      </div>
    </>
  )
}

export default AskDriver

const SendMessage = ({ message, lobby, start, end, setError, setSendMessage }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/lobbies/ask/${lobby}`, {
    method: 'POST',
    body: JSON.stringify({
      text: message.trim(),
      start,
      end,
    })
  })

  useEffect(() => {
    if (response.ok) {
      Mixpanel.track('Pregunta Enviada con Exito')
      response.json().then(data => {
        setSendMessage(false)
        window.location.href = window.location.origin + '/messages/' + data.channel._id
      })
    } else if (response.ok === false) {
      Mixpanel.track('Error al Enviar Pregunta')
      setError('Error al enviar pregunta, intente nuevamente')
      setSendMessage(false)
    }
  }, [loading])

  return (
    <LoadingIndicator />
  )
}