'use client'
import { useState } from 'react'
import Modal from 'react-modal'
import modalStyles from './modalStyles'
import Picker from 'react-mobile-picker'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export default ({ time, setTime, open, setOpen }) => {
  const [selectedHour, setSelectedHour] = useState({ value: time.split(':')[0] })
  const [selectedMinutes, setSelectedMinutes] = useState({ value: time.split(':')[1] })
  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'))
  const minutes = Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, '0'))

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    setTime(`${selectedHour.value}:${selectedMinutes.value}`)
    setOpen(false)
  }

  const handleIncreaseHour = () => {
    const hour = parseInt(selectedHour.value)
    if (hour === 23) {
      setSelectedHour({ value: '00' })
    } else {
      setSelectedHour({ value: (hour + 1).toString().padStart(2, '0') })
    }
  }

  const handleDecreaseHour = () => {
    const hour = parseInt(selectedHour.value)
    if (hour === 0) {
      setSelectedHour({ value: '23' })
    } else {
      setSelectedHour({ value: (hour - 1).toString().padStart(2, '0') })
    }
  }

  const handleIncreaseMinutes = () => {
    let minute = parseInt(selectedMinutes.value)
    if (minute === 55) {
      setSelectedMinutes({ value: '00' })
    } else {
      setSelectedMinutes({ value: (minute + 5).toString().padStart(2, '0') })
    }
  }

  const handleDecreaseMinutes = () => {
    let minute = parseInt(selectedMinutes.value)
    if (minute === 0) {
      setSelectedMinutes({ value: '55' })
    } else {
      setSelectedMinutes({ value: (minute - 5).toString().padStart(2, '0') })
    }
  }

  return (
    <Modal style={modalStyles} isOpen={open} onRequestClose={handleClose}>
      <div className='flex flex-row justify-between w-full'>
        <div className='flex flex-col justify-center'>
          <Button onClick={handleDecreaseHour} type='up' />
          <Picker
            className='text-[31px] bg-light_gray w-[80px] rounded-[12px]'
            height={90}
            itemHeight={40}
            value={selectedHour}
            onChange={setSelectedHour}
          >
            <Picker.Column key={'value'} name='value'>
              {hours.map((hour, index) => (
                <Picker.Item key={index} value={hour} selected={hour === selectedHour}>
                  {hour}
                </Picker.Item>
              ))}
            </Picker.Column>
          </Picker>
          <Button onClick={handleIncreaseHour} type='down' />
        </div>
        <div className='flex flex-col justify-center items-center gap-6'>
          <div className='w-4 h-4 rounded-full bg-light_gray' />
          <div className='w-4 h-4 rounded-full bg-light_gray' />
        </div>
        <div className='flex flex-col justify-center'>
          <Button onClick={handleDecreaseMinutes} type='up' />
          <Picker
            className='text-[31px] bg-light_gray w-[80px] rounded-[12px]'
            height={90}
            itemHeight={40}
            value={selectedMinutes}
            onChange={setSelectedMinutes}
          >
            <Picker.Column key={'value'} name='value'>
              {minutes.map((minute, index) => (
                <Picker.Item key={index} value={minute}>
                  {minute}
                </Picker.Item>
              ))}
            </Picker.Column>
          </Picker>
          <Button onClick={handleIncreaseMinutes} type='down' />
        </div>
      </div>
      <div className='flex flex-row w-full items-center justify-end gap-6 text-sm'>
        <div className='cursor-pointer' onClick={handleClose}>
          Cancelar
        </div>
        <div
          onClick={handleSubmit}
          className={`text-base rounded-full text-white px-3 py-2 text-center cursor-pointer bg-primary`}
        >
          Confirmar
        </div>
      </div>
    </Modal>
  )
}

const Button = ({ onClick, type }) => {
  return (
    <IconButton onClick={onClick}>
      {type === 'up' ? (
        <KeyboardArrowUp fontSize='large' />
      ) : (
        <KeyboardArrowDown fontSize='large' />
      )}
    </IconButton>
  )
}
