import { ArrowBackIosNew, People } from '@mui/icons-material'
import { useAuth, useFetch } from '../../hooks'
import { uri } from '../../config/Api'
import LoadingIndicator from '../LoadingIndicator'
import Trip from '../../components/Trip'
import { getCurrency, toDate } from '../../utils'
import { DIAS, MESES, MINUTE } from '../../config/App'
import Button from '../Button'
import Company from './Company'
import { Mixpanel } from '../../Mixpanel'
import { useEffect, useState } from 'react'
import PassengerDetails from '../../components/PassengerDetails'
import SearchError from '../SearchError'
import { IconButton } from '@mui/material'
import VerifyBus from '../../components/VerifyBus'
import BusSeatSelectionScreen from '../../components/BusSeatSelectionScreen'

const JoinMode = ({ bus, seats, setSelectedBus }) => {
  const { loading: loadingAuth, isAuthenticated, loginWithRedirect } = useAuth()
  const params = new URLSearchParams(window.location.search)
  const [step, setStep] = useState(0)
  const [passengerDetails, setPassengerDetails] = useState({})
  const [transaction, setTransaction] = useState()
  const [timeoutRef, setTimeoutRef] = useState()
  const [selectedSeats, setSelectedSeats] = useState([])
  const { loading, responseOk, data } = useFetch(`${uri}/buses/${bus.id}`)
  const departureDateTime = toDate(bus.Journey.departure_time)
  const departureDate = DIAS[departureDateTime.getDay()] + departureDateTime.getDate() + ' de ' + MESES[departureDateTime.getMonth()]
  const departureTime = bus.Journey.departure_time?.substr(bus.Journey.departure_time?.indexOf('T') + 1, 5)
  const arrivalTime = bus.Journey.arrival_time?.substr(bus.Journey.arrival_time?.indexOf('T') + 1, 5)
  const supportUrl = `https://api.whatsapp.com/send/?phone=+59898415699&text=Hola, tengo una consulta sobre el viaje ${bus.Journey.origin} - ${bus.Journey.destination} del ${departureDate} a las ${departureTime}hs`

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('join') && responseOk) {
      setStep(1)
    }
  }, [responseOk])

  const handleSubmit = async () => {
    if (isAuthenticated) {
      Mixpanel.track('Boton Unirse Bus', {
        'Bus': bus,
      })
      setStep(1)
      setTimeoutRef(setTimeout(() => {
        Mixpanel.track('Sesión Bus Expirada')
        alert('Tu sesión ha expirado. Por favor intenta nuevamente')
        setStep(0)
      }, 10 * MINUTE))
    } else {
      Mixpanel.track('Boton Unirse Bus No Autenticado', {
        'Bus': bus,
      })
      const url = `${window.location.origin}/join-bus/${bus.id}?seats=${seats}&join=true`
      const referedBy = params.get('referedBy')
      loginWithRedirect({
        appState: referedBy ? `${url}&referedBy=${referedBy}` : url,
      })
    }
  }

  const handleBack = () => {
    if (step == 0) {
      setSelectedBus()
    }
    if (step == 1) {
      Mixpanel.track('Botón Atrás PassengerDetails')
      clearTimeout(timeoutRef)
      setStep(0)
    }
    if (step == 2) {
      Mixpanel.track('Botón Atrás SeatsSelection')
      setStep(1)
    }
    if (step == 3) {
      Mixpanel.track('Botón Atrás VerifyBus')
      setStep(2)
    }
  }

  return (
    <>
      <div>
        <IconButton onClick={handleBack} style={{ paddingTop: 10, paddingLeft: 10 }} sx={{ color: '#f25c45' }} >
          <ArrowBackIosNew />
        </IconButton>
      </div>
      {
        step == 0 ?
          loading || loadingAuth ?
            <div className='h-full flex justify-center items-center'>
              <LoadingIndicator />
            </div>
            :
            responseOk ?
              <div className='h-full overflow-auto flex-col flex justify-between'>
                <div className='w-full flex flex-col mt-4 text-2xl gap-4 sm:items-center'>
                  <Company bus={bus} />
                  <InfoContainer bus={bus} seatsRemaining={data.seats_remaining} departureDate={departureDate} departureTime={departureTime} arrivalTime={arrivalTime} />
                </div>
                <ButtonContainer supportUrl={supportUrl} handleSubmit={handleSubmit} />
              </div>
              :
              <SearchError />
          :
          step == 1 ?
            <PassengerDetails
              busInfo={data}
              bus={bus}
              seats={seats}
              setPassengerDetails={setPassengerDetails}
              setStep={setStep}
              setTransaction={setTransaction}
              setSelectedSeats={setSelectedSeats}
              passengerDetails={passengerDetails}
            />
            :
            step == 2 ?
              <BusSeatSelectionScreen
                seats={selectedSeats}
                setSeats={setSelectedSeats}
                busId={bus.id}
                transaction={transaction}
                setStep={setStep}
                price={bus.price}
              />
              :
              <VerifyBus
                passengerDetails={passengerDetails}
                bus={bus}
                transaction={transaction}
                departureDate={departureDate + ', ' + departureTime + 'hs'}
              />
      }
    </>
  )
}


const InfoContainer = ({ bus, departureDate, departureTime, arrivalTime, seatsRemaining }) => {
  const origin = bus.Journey.origin + ', ' + bus.Journey.country
  const destination = bus.Journey.destination + ', ' + bus.Journey.country
  const seats = seatsRemaining == 0 ? 'No hay asientos disponibles' : `${seatsRemaining} ${seatsRemaining == 1 ? 'asiento disponible' : 'asientos disponibles'}`

  return (
    <div className='w-full flex flex-col mt-4 text-2xl gap-4 sm:items-center'>
      <div className='pl-5 tall:pl-7'>
        <Trip
          start={origin}
          end={destination}
          originName={origin}
          destinationName={destination}
          departureDate={departureDate}
          departureTime={departureTime}
          arrivalTime={arrivalTime}
          dateStyle={{ fontWeight: '500', fontSize: 22, marginBottom: 5 }}
        />
      </div>
      <div className='flex gap-2 text-xl mb-4 pl-6'>
        <People style={{ color: '#d65944', fontSize: 30 }} />
        <div className='flex flex-col justify-center font-thin font-[1.3rem]'>{seats}</div>
      </div>
      <div className='flex gap-2 text-1xl mb-2 pl-6'>
        <p className='text-primary'>{getCurrency(bus.currency)}</p>
        <p className='flex flex-row font-thin text-[1.3rem]'>{bus.price - bus.discount}</p>
        {bus.discount > 0 && <p className='flex flex-row font-thin text-[1rem] text-[#9ca3af] line-through'>{getCurrency(bus.currency)} {bus.price}</p>}
      </div>
    </div>
  )
}

const ButtonContainer = ({ supportUrl, handleSubmit }) => {
  return (
    <div className='flex flex-col pl-6 pr-6 sm:justify-center sm:items-center'>
      <div className='w-full mt-2 flex flex-col items-center gap-0' >
        <a
          className='text-xl mb-2 mt-3'
          style={{ color: '#f25c54' }}
          target='_blank'
          href={supportUrl}
        >
          Soporte
        </a>
      </div>
      <Button
        name={'Continuar'}
        style={{ marginTop: '10px', marginBottom: '6px', zIndex: 300 }}
        className='h-11 tall:h-14'
        onClick={handleSubmit}
      />
      <div>
        <div className='text-sm sm:text-center mb-2'>Al continuar aceptas nuestros <a
          style={{ color: '#f25c54' }}
          onClick={() => {
            window.open('https://www.viatik.com.uy/terminos', '_blank')
          }}
        >
          Términos y condiciones
        </a>
        </div>
      </div>
    </div>
  )
}

export default JoinMode