import Alert from '../../commons/Alert'
import SearchError from '../../commons/SearchError'
import { uri } from '../../config/Api'
import { useEffect, useState } from 'react'
import { useFetch } from '../../hooks'
import LoadingIndicator from '../../commons/LoadingIndicator'
import BusInfo from '../../commons/BusInfo'

const ShowBus = ({ busToShow, seats }) => {
  const { loading, responseOk, data } = useFetch(`${uri}/buses/${busToShow}`)
  const [visibleError, setVisibleError] = useState(true)
  const [bus, setBus] = useState()

  useEffect(() => {
    if (responseOk) {
      setBus(data)
    } else if (!loading) {
      setBus({})
    }
  }, [loading])

  return (
    <>
      {
        loading || !bus ?
          <Alert
            visible={loading}
            hideButton={loading}
            fullscreen
          >
            <div className='flex flex-col items-center'>
              <LoadingIndicator />
            </div>
          </Alert>
          :
          <>
            {
              Object.keys(bus).length > 0 ?
                <BusInfo
                  bus={bus}
                  setSelectedBus={setBus}
                  seats={seats}
                  join={true}
                />
                :
                <Alert
                  visible={visibleError}
                  onClose={() => {
                    setVisibleError(false)
                  }}
                >
                  <SearchError />
                </Alert>
            }
          </>
      }

    </>
  )
}

export default ShowBus
