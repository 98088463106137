import { Dialog, Slide } from '@mui/material'
import { forwardRef } from 'react'

export default ({ children, open, onClose }) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      {children}
    </Dialog>
  )
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
