import { useAuth0 } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'

export default () => {
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded')
  const embeddedAccessToken = params.get('embedded-access-token')

  const auth0 = useAuth0()
  if (!embedded) {
    return auth0
  } else {
    const decodeUser = () => {
      try {
        return jwtDecode(embeddedAccessToken)
      } catch (error) {
        return null
      }
    }
    return {
      isAuthenticated: true,
      user: decodeUser(),
      getAccessTokenSilently: async () => await embeddedAccessToken,
      loginWithRedirect: () => { },
      loginWithPopup: () => { },
      logout: () => { },
    }
  }
}