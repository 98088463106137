import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  timelineItemClasses
} from '@mui/lab'
import { Typography, createTheme, ThemeProvider } from '@mui/material'
import { useEffect, useState } from 'react'

const theme = createTheme({
  typography: {
    fontFamily: {
      Poppins: 'Poppins, sans-serif'
    }
  }
})

const Trip = ({
  start,
  end,
  originName,
  destinationName,
  departureDate,
  departureTime,
  arrivalTime,
  dateStyle,
  noTime
}) => {
  const originCity = originName.split(',')[0]
  const destinationCity = destinationName.split(',')[0]
  const originDepartment = originName.split(',')[1]
  const destinationDepartment = destinationName.split(',')[1]
  const startCity = start.split(',')[0]
  const endCity = end.split(',')[0]
  const startDepartment = start.split(',')[1]
  const endDepartment = end.split(',')[1]
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Typography variant='h6' style={{ marginTop: 10, marginBottom: 20, ...dateStyle }}>
        {departureDate}
      </Typography>
      <Timeline
        sx={
          noTime
            ? [
                {
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0
                  }
                },
                {
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0,
                    padding: 0
                  },
                  paddingLeft: 0,
                  justifyContent: 'flex-start'
                }
              ]
            : {
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: width < 360 ? 0.3 : width < 640 ? 0.18 : 0.2
                },
                paddingLeft: 0,
                justifyContent: 'flex-start'
              }
        }
      >
        {originName.includes(start) && destinationName.includes(end) ? (
          <>
            <Item
              title={originCity}
              subtitle={originDepartment}
              activeDot
              activeLine
              time={departureTime}
            />
            <Item
              title={destinationCity}
              subtitle={destinationDepartment}
              last
              activeDot
              time={arrivalTime}
            />
          </>
        ) : originName.includes(start) ? (
          <>
            <Item
              title={originCity}
              subtitle={originDepartment}
              activeDot
              activeLine
              time={departureTime}
            />
            <Item title={endCity} subtitle={endDepartment} activeDot time={arrivalTime} />
            <Item title={destinationCity} subtitle={destinationDepartment} last />
          </>
        ) : destinationName.includes(end) ? (
          <>
            <Item title={originCity} subtitle={originDepartment} />
            <Item
              title={startCity}
              subtitle={startDepartment}
              activeDot
              activeLine
              time={departureTime}
            />
            <Item title={endCity} subtitle={endDepartment} activeDot last time={arrivalTime} />
          </>
        ) : (
          <>
            <Item title={originCity} subtitle={originDepartment} />
            <Item
              title={startCity}
              subtitle={startDepartment}
              activeDot
              activeLine
              time={departureTime}
            />
            <Item title={endCity} subtitle={endDepartment} activeDot time={arrivalTime} />
            <Item title={destinationCity} subtitle={destinationDepartment} last />
          </>
        )}
      </Timeline>
    </ThemeProvider>
  )
}

const Item = ({ title, subtitle, last, activeDot, time, activeLine }) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        color='textSecondary'
        variant='body2'
        align='left'
        sx={{ paddingLeft: 0, marginTop: '-10px', textAlign: 'left', paddingRight: 1 }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 300 }}>{time}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={[activeDot && { backgroundColor: '#d65944' }, { margin: 0 }]} />
        {!last && <TimelineConnector sx={activeLine && { backgroundColor: '#d65944' }} />}
      </TimelineSeparator>
      <TimelineContent sx={{ paddingRight: 0, paddingLeft: 1, marginTop: '-14px' }}>
        <Typography variant={activeDot ? 'h6' : 'subtitle1'} component='span' sx={{ fontSize: 18 }}>
          {title}
        </Typography>
        <Typography
          variant={activeDot ? 'subtitle1' : 'body2'}
          sx={{ color: 'grey', fontSize: 14, fontWeight: 300 }}
        >
          {subtitle}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}

export default Trip
