import { CloseRounded } from "@mui/icons-material"
import { Divider, IconButton } from "@mui/material"
import { getCurrency } from "../../utils"


const PriceDetails = ({ data, currency, setVisible }) => {
  const total = data.ticket_price + data.boarding_fee + data.fee - data.discount
  const totalWithoutDiscount = data.ticket_price + data.boarding_fee + data.fee
  const currencySymbol = getCurrency(currency)

  return (
    <>
      <div className='flex justify-end'>
        <IconButton onClick={() => setVisible(false)} sx={{ color: '#f25c45' }} >
          <CloseRounded sx={{ fontSize: 30 }} />
        </IconButton>
      </div>
      <div className='w-full flex flex-col mt-4 gap-8 px-8'>
        <p className='text-2xl text-center'>Detalles del precio</p>
        <div className='flex flex-col gap-6 text-lg font-thin'>
          <div className='flex flex-row justify-between gap-5 mb-2'>
            <p>Pasajes</p>
            <p>{currencySymbol} {data.ticket_price}</p>
          </div>
          {
            data.boarding_fee > 0 &&
            <div className='flex flex-row justify-between gap-5 mb-2'>
              <p>Tasa de embarque</p>
              <p>{currencySymbol} {data.boarding_fee}</p>
            </div>
          }
          <div className='flex flex-row justify-between items-end gap-5 mb-2'>
            <p>Gastos de gestión</p>
            <div>
              {data.discount > 0 && <p className='text-[#9ca3af] line-through'>{currencySymbol} {data.fee}</p>}
              <p>{currencySymbol} {data.fee - data.discount}</p>
            </div>
          </div>
          <Divider />
          <div className='flex flex-row justify-between items-end gap-5 mt-2 mb-2'>
            <p className='text-primary'>Precio Total</p>
            <div>
              {data.discount > 0 && <p className='text-[#9ca3af] line-through'>{currencySymbol} {totalWithoutDiscount}</p>}
              <p>{currencySymbol} {total}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceDetails