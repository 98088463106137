import styles from "../../UserInfo/styles"
import stylePublished from '../../StylePublished/index.js'
import styleDate from '../../StyleDate/index.js'

const ProfileRecord = ({ userInfo }) => {
  return (
    <div style={styles.infoContainer}>
      {userInfo?.verified && <p style={styles.extraInfoText}>Usuario verificado</p>}
      <p style={styles.extraInfoText}>{stylePublished(userInfo.published_lobbies)}</p>
      <p style={styles.extraInfoText}>{userInfo?.shared_distance} kilometros compartidos</p>
      <p style={styles.extraInfoText}>{styleDate(userInfo?.registered)}</p>
    </div>
  )
}

export default ProfileRecord
