import styles from '../../UserInfo/styles'
import PreferencesViewMode from '../../UserInfo/Preferences'


const ProfileAboutMe = ({ userInfo, aboutMe, preferences }) => {
  return (
    <>
      <div style={styles.biographyContainer}>
        <p style={styles.infoText}>Sobre Mí</p>
        <div>
          {
            userInfo.biography ?
              <p style={styles.biographyText}>{aboutMe}</p>
              :
              <p style={styles.noBiographyText}>Aún no hay una descripción</p>
          }
        </div>
      </div>
      <PreferencesViewMode preferences={preferences} />
    </>
  )
}


export default ProfileAboutMe
