import { uri } from '../../../../config/Api'
import SearchError from '../../../../commons/SearchError'
import { useAuthorizedFetch } from '../../../../hooks'
import Alert from '../../../../commons/Alert'
import { useEffect } from 'react'
import { Mixpanel } from '../../../../Mixpanel'
import LoadingIndicator from '../../../../commons/LoadingIndicator'

const DeleteLobby = ({ visible, setVisible, id, closeLobbyInfo, setLobbies, lobbies, reason }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/lobbies/cancel/${id}`, {
    method: 'POST',
    body: JSON.stringify({
      reason: reason
    })
  })

  useEffect(() => {
    if (response.ok) {
      Mixpanel.track('Lobby Borrada con exito', {
        'LobbyId': id,
        'Motivo': reason
      })
      setLobbies(lobbies.filter(lobby => lobby._id != id))
    } else if (response.ok == false) {
      Mixpanel.track('Error al Borrar lobby', {
        'LobbyId': id
      })
    }
  }, [loading])

  return (
    <Alert
      visible={visible}
      hideButton={loading}
      onClose={() => {
        setVisible(false)
        closeLobbyInfo()
      }}
    >
      {
        loading ?
          <div className='flex justify-center items-center'>
            <LoadingIndicator />
          </div>
          :
          response.ok ?
            <div>Viaje eliminado con éxito!</div>
            :
            <SearchError />
      }
    </Alert>
  )
}

export default DeleteLobby  