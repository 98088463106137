import Lottie from 'lottie-react'
import Button from '../../commons/Button'
import { useEffect } from 'react'
import { calculateAspectRatio } from '../../utils'

const Success = ({ title, message, onSubmit, height, width }) => {

  useEffect(() => {
    window.ReactNativeWebView?.postMessage('bus-success')
  }, [])

  return (
    <div style={calculateAspectRatio(width, height) <= 0.7 ? { backgroundColor: '#f8ece9', height: height } : { backgroundColor: '#fff', height: height }} >
      <div className='text-center'>
        {
          calculateAspectRatio(width, height) <= 0.7 ?
            <Lottie
              animationData={require('../../assets/animations/bus_animation.json')}
              autoplay
              loop
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              style={{ height: '100%' }}
            />
            :
            <div className='flex justify-center'>
              <img src={require('../../assets/img/buses.png')} alt='bus' className='w-80' />
            </div>
        }
        <div className='flex flex-col px-6 gap-6'>
          <p className='text-2xl font-bold text-primary text-center'>{title}</p>
          <p className='text-lg text-center'>{message}</p>
        </div>
      </div>
      <div className='w-full bottom-5 absolute z-30 text-center'>
        <Button
          name='Continuar'
          onClick={onSubmit}
          style={{ margin: 'auto', fontSize: 16 }}
        />
      </div>
    </div>
  )
}

export default Success