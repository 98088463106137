const styles = {
  container: {
    paddingTop: 30,
    height: '100%',
    overflow: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
  },
  firstSubcontainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 30,
    color: '#333333',
    marginBottom: 30,
  },
  starsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  star: {
    fontSize: 20,
    marginLeft: 5,
    color: '#9ca3af',
    textAlign: 'center',
  },
  divider: {
    marginTop: 25,
    marginBottom: 25,
    height: 1,
    backgroundColor: '#9ca3af',
  },
  opinionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  opinionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  opinionName: {
    fontSize: 22,
    marginLeft: 10,
    color: '#333333',
  },
  opinionTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 17,
  },
  opinion: {
    fontSize: 20,
    marginBottom: 7,
  },
  opinionText: {
    fontSize: 16,
    textAlign: 'justify',
    marginBottom: 10,
  },
  opinionDate: {
    fontSize: 14,
    color: '#9ca3af',
  },
}

export default styles