import { useState, useEffect, useRef } from 'react'
import AutocompleteBox from '../../commons/AutocompleteBox'
import { Application, DatePicker } from 'react-rainbow-components'
import TextField from '@mui/material/TextField'
import TimePicker from '../../commons/TimePicker'
import MenuItem from '@mui/material/MenuItem'
import { ThemeProvider, InputAdornment } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { isoDateToWeekday, muiInputTheme, toDate } from '../../utils'
import { CalendarTodayRounded, ExpandLessRounded, PeopleAltRounded } from '@mui/icons-material'
import ToggleSwitch from '../../commons/ToggleSwitch'

const SearchForm = ({ setSearchData, selection, initialValues, dateType }) => {
  const [openDatePicker, setOpenDatePicker] = useState(true)
  const [hideSearchForm, setHideSearchForm] = useState(false)
  const [searchFormHeight, setSearchFormHeight] = useState()
  const searchFormRef = useRef(null)
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded') || localStorage.getItem('embedded')

  const rainbowTheme = {
    rainbow: {
      palette: {
        brand: '#d65944'
      }
    }
  }

  if (!initialValues) {
    initialValues = {}
    initialValues.origin = null
    initialValues.destination = null
    initialValues.originName = { formatted_address: '' }
    initialValues.destinationName = { formatted_address: '' }
    initialValues.selectedDate = today()
    initialValues.data = 1
    if (selection != 'seats') {
      const now = new Date()
      now.setHours(now.getHours() + 1)
      initialValues.data = now.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      })
    }
  }
  const [origin, setOrigin] = useState(initialValues.origin)
  const [destination, setDestination] = useState(initialValues.destination)
  const [destinationName, setDestinationName] = useState(initialValues.destinationName)
  const [originName, setOriginName] = useState(initialValues.originName)
  const [selectedDate, setSelectedDate] = useState(initialValues.selectedDate)
  const [data, setData] = useState(initialValues.data)
  useEffect(() => {
    if (initialValues.seats) {
      //Porque cuando hacemos logins se manda el parametro seats en vez de data, y a veces se manda data vacio
      setData(initialValues.seats)
    }
  }, [])
  useEffect(() => {
    if (selection != 'seats') {
      setSearchData({
        origin,
        destination,
        selectedDate,
        selectedTime: data,
        originName,
        destinationName,
        data
      })
    } else {
      setSearchData({
        origin,
        destination,
        selectedDate,
        seats: data,
        originName,
        destinationName,
        data
      })
    }
  }, [origin, destination, selectedDate, data])

  const [textOrigin, setTextOrigin] = useState(initialValues.originName.formatted_address)
  const [textDestination, setTextDestination] = useState(
    initialValues.destinationName.formatted_address
  )

  useEffect(() => {
    if (!textOrigin) {
      setOrigin(null)
      setOriginName({})
    }
    if (!textDestination) {
      setDestination(null)
      setDestinationName({})
    }
  }, [textOrigin, textDestination])

  const checkInvert = () => {
    const auxOrigin = origin
    const auxOriginName = originName
    const auxTextOrigin = textOrigin
    if (auxOrigin && destination) {
      setOrigin(destination)
      setOriginName(destinationName)
      setTextOrigin(textDestination)
      setDestination(auxOrigin)
      setDestinationName(auxOriginName)
      setTextDestination(auxTextOrigin)
    } else if (!destination && auxOrigin) {
      setDestination(auxOrigin)
      setDestinationName(auxOriginName)
      setTextDestination(auxTextOrigin)
      setOrigin(null)
      setOriginName({})
      setTextOrigin('')
    } else if (!auxOrigin && destination) {
      setOrigin(destination)
      setOriginName(destinationName)
      setTextOrigin(textDestination)
      setDestination(null)
      setDestinationName({})
      setTextDestination('')
    }
  }

  useEffect(() => {
    if (!searchFormRef.current) return
    const heightObserver = new ResizeObserver(() => {
      if (embedded) {
        setSearchFormHeight(searchFormRef.current?.clientHeight)
      } else {
        setSearchFormHeight(searchFormRef.current?.clientHeight + 63)
      }
    })
    heightObserver.observe(searchFormRef.current)
    return () => heightObserver.disconnect()
  }, [])

  const [toggled, setToggled] = useState(false)

  return (
    <>
      <div className='bg-transparent'>
        <div
          className={`bg-white max-w-2xl z-30 m-auto p-5 pt-4 pb-3
          grid ${
            hideSearchForm ? 'grid-rows-1' : 'grid-rows-3'
          } gap-2.5 shadow-xl rounded-b-2xl transtion duration-300 ease`}
          ref={searchFormRef}
        >
          {hideSearchForm ? (
            <>
              <div
                onClick={() => setHideSearchForm(false)}
                className={`cursor-pointer text-primary ${
                  originName.locality && destinationName.locality && 'text-black'
                }`}
              >
                <p className='text-base text-center'>
                  {originName.locality && destinationName.locality
                    ? originName.locality + ' → ' + destinationName.locality
                    : selection === 'seats'
                    ? 'Buscá tu próximo viaje'
                    : 'Publicá tu próximo viaje'}
                </p>
                {originName.locality && destinationName.locality && (
                  <p className='text-base text-center text-[#9ca3af]'>
                    {isoDateToWeekday(selectedDate)}
                  </p>
                )}
              </div>
            </>
          ) : (
            <ThemeProvider theme={muiInputTheme}>
              <AutocompleteBox
                placeholder='Lugar de salida'
                setLocation={setOrigin}
                locationName={originName}
                setLocationName={setOriginName}
                text={textOrigin}
                setText={setTextOrigin}
                adornment={
                  <div onClick={checkInvert} className={`absolute right-4 top-4`}>
                    <ImportExportIcon sx={{ color: '#f25c45' }} />
                  </div>
                }
              />
              <AutocompleteBox
                placeholder='Destino'
                setLocation={setDestination}
                locationName={destinationName}
                setLocationName={setDestinationName}
                text={textDestination}
                setText={setTextDestination}
                adornment={null}
              />
              <div className='flex justify-between gap-5'>
                <Application theme={rainbowTheme}>
                  {openDatePicker && (
                    <DatePicker
                      minDate={new Date()}
                      id='datePicker-1'
                      value={toDate(selectedDate)}
                      onChange={value => {
                        setSelectedDate(value.toISOString().replace(/T.*$/, 'T00:00:00.000Z'))
                        setOpenDatePicker(false)
                        setOpenDatePicker(true)
                      }}
                      label='Fecha'
                      formatStyle='medium'
                      placeholder='Seleccionar fecha'
                      hideLabel
                      style={{ paddingTop: 8 }}
                      selectionType={dateType}
                      borderRadius='semi-rounded'
                      icon={<CalendarTodayRounded sx={{ color: '#000' }} />}
                    />
                  )}
                </Application>
                {selection === 'seats' ? (
                  <TextField
                    id='selected-seats'
                    select
                    value={data}
                    onChange={evt => setData(evt.target.value)}
                    style={{
                      width: 120,
                      paddingBottom: -10,
                      justifyContent: 'center'
                    }}
                    size='small'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <PeopleAltRounded sx={{ color: '#000', fontSize: 20 }} />
                        </InputAdornment>
                      )
                    }}
                  >
                    {[1, 2, 3, 4].map(x => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <div className='flex flex-col justify-center'>
                    <TimePicker
                      data={data}
                      setData={setData}
                      style={{
                        borderRadius: 10,
                        fontSize: 16,
                        borderColor: 'rgba(164,167,181,1)',
                        borderWidth: 1
                      }}
                    />
                  </div>
                )}
              </div>
            </ThemeProvider>
          )}
        </div>
      </div>
      <div
        onClick={() => setHideSearchForm(!hideSearchForm)}
        style={{ top: searchFormHeight }}
        className={`md:hidden bg-white w-[3rem] item-center justify-center flex rounded-b-md shadow cursor-pointer left-0 right-0 mx-auto absolute`}
      >
        <ExpandLessRounded
          sx={{
            color: '#d65944',
            fontSize: 30,
            transform: hideSearchForm ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease-in-out'
          }}
        />
      </div>
    </>
  )
}

const today = () => {
  const date = new Date(new Date().getTime() - 3 * 1000 * 60 * 60)
  return date.toISOString().replace(/T.*$/, 'T00:00:00.000Z')
}

export default SearchForm
