import Avatar from '../../commons/Avatar';
import { Mixpanel } from '../../Mixpanel';


const Profile = ({ setAlertValue, img, name, isAuthenticated, user }) => {
  return (
    <div
      className='rounded-full flex justify-center mt-5 mb-3'
      onClick={() => {
        Mixpanel.track('Boton Menu', {
          'Autenticado': isAuthenticated,
          'Usuario': user
        })
        if (isAuthenticated) {
          setAlertValue(true)
        }
      }}
    >
      {
        img ?
          <Avatar alt={name} src={img} sx={{ width: 120, height: 120 }} />
          :
          <img
            src={require('../../../src/assets/img/profile.png')}
            width='120px'
            height='120px'
            className='rounded-full'
          />
      }
    </div>
  )
}

export default Profile
