import { createTheme } from '@mui/material'

export default createTheme({
  typography: {
    fontFamily: {
      Poppins: 'Poppins, sans-serif'
    }
  },
  shape: {
    borderRadius: 10
  },
  palette: {
    primary: {
      main: '#d65944'
    },
    secondary: {
      main: '#d65944'
    }
  }
})
