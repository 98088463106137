import Alert from '../../commons/Alert'
import LobbyInfo from '../../commons/LobbyInfo'
import SearchError from '../../commons/SearchError'
import apiConfig from '../../config/Api'
import { useState } from 'react'
import { useFetch } from '../../hooks'
import LoadingIndicator from '../../commons/LoadingIndicator'

const ShowLobby = ({ lobbyToShow, seats, start, end }) => {
  const [visible, setVisible] = useState(true)
  const [visibleError, setVisibleError] = useState(true)
  const { loading, data } = useFetch(`${apiConfig.uri}/lobbies/id/${lobbyToShow}?start=${start}&end=${end}`)

  return (
    <>
      {
        loading ?
          <Alert
            visible={loading}
            hideButton={loading}
            fullscreen
          >
            <div className='flex flex-col items-center'>
              <LoadingIndicator />
            </div>
          </Alert>
          :
          <>
            {
              data?._id ? //Si no dio error
                <LobbyInfo
                  seats={seats}
                  visible={visible}
                  setVisible={setVisible}
                  lobby={data}
                  type='join'
                  start={start}
                  end={end}
                />
                :
                <Alert
                  visible={visibleError}
                  onClose={() => {
                    setVisibleError(false)
                  }}
                >
                  <SearchError />
                </Alert>
            }
          </>
      }

    </>
  )
}

export default ShowLobby
