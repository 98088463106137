import { Divider, TextField, IconButton } from '@mui/material'
import { useState, useCallback, useEffect } from 'react'
import { uri } from '../../config/Api'
import debounce from 'lodash.debounce'
import { ChevronRight, ArrowBackIosNew } from '@mui/icons-material'
import LoadingIndicator from '../LoadingIndicator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default ({ placeholder, setText, text, handleBack }) => {
  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] = useState(false)
  const [placePredictions, setPlacePredictions] = useState([])

  const onSelected = details => {
    if (details) {
      setText(formatAddress(styleAddress(details.formatted_address)))
      window.ReactNativeWebView?.postMessage('!locationInput')
      handleBack(
        {
          lat: details.geometry.location.lat,
          lng: details.geometry.location.lng
        },
        {
          formatted_address: styleAddress(details.formatted_address),
          locality: details.address_components.filter(x => x.types.includes('locality'))[0]
            .long_name,
          country_code: details.address_components.filter(x => x.types.includes('country'))[0]
            .short_name
        }
      )
    }
  }

  const getPlacePredictions = input => {
    fetch(`${uri}/locations?input=${removeAccents(input)}`)
      .then(res => res.json())
      .then(data => {
        setIsPlacePredictionsLoading(false)
        setPlacePredictions(data)
      })
      .catch(err => {
        console.log(err)
        setIsPlacePredictionsLoading(false)
        setPlacePredictions([{ formatted_address: 'Error al buscar ubicaciones' }])
      })
  }

  const debouncedChangeHandler = useCallback(debounce(getPlacePredictions, 500), [])

  const styleAddress = address => {
    //remove numbers
    address = address?.replace(/(\d+)/g, '')
    return address?.trim()
  }

  const removeAccents = str => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  const handleDelete = () => {
    setText('')
    setPlacePredictions([])
  }

  useEffect(() => {
    window.ReactNativeWebView?.postMessage('locationInput')
  }, [])

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row relative pr-4 mb-4'>
        <IconButton
          onClick={() => {
            window.ReactNativeWebView?.postMessage('!locationInput')
            handleBack()
          }}
          sx={{ color: '#f25c45' }}
        >
          <ArrowBackIosNew />
        </IconButton>
        <TextField
          autoFocus
          value={text}
          onChange={e => {
            const value = e.target.value
            setText(value)
            if (value.length != 0) {
              setIsPlacePredictionsLoading(true)
              debouncedChangeHandler(value.trim())
            } else {
              setPlacePredictions([])
            }
          }}
          placeholder={placeholder}
          fullWidth
          variant='filled'
          InputProps={{
            disableUnderline: true,
            style: { borderRadius: 10 },
            inputProps: {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20,
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500
              }
            }
          }}
        />
        <Adornment loading={isPlacePredictionsLoading} text={text} handleDelete={handleDelete} />
      </div>
      {!isPlacePredictionsLoading &&
        placePredictions.length > 0 &&
        placePredictions
          .filter(item => item?.address_components)
          .map((item, number) => (
            <div className='flex flex-col px-4'>
              <div
                className='flex items-center w-full cursor-pointer hover:bg-light_gray focus:bg-light_gray rounded-lg p-4 gap-4'
                onClick={() => onSelected(item)}
              >
                <div className='flex flex-col items-start text-start'>
                  <label className='text-base text-text cursor-pointer'>
                    {item.address_components && item.address_components[0].long_name}
                  </label>
                  <label className='text-sm text-gray cursor-pointer'>
                    {item.address_components && item?.address_components[2].long_name}
                  </label>
                </div>
                <ChevronRight className='ml-auto h-6 w-6 text-light_coral cursor-pointer' />
              </div>
              {number !== placePredictions.length - 1 && <Divider />}
            </div>
          ))}
    </div>
  )
}

const formatAddress = address => {
  const formattedAddress = address?.split(',')
  if (address?.match(/Uruguay$/)) {
    return formattedAddress[0]
  } else {
    return formattedAddress[0] + ', ' + formattedAddress[2]
  }
}

const Adornment = ({ loading, text, handleDelete }) => {
  if (loading) {
    return (
      <div className={`absolute right-8 top-[3px] cursor-pointer`}>
        <LoadingIndicator size={14} type={'bounce'} color={'#f25c45'} />
      </div>
    )
  } else if (text.length > 0) {
    return (
      <div className={`absolute right-6 top-[1px] cursor-pointer p-2`} onClick={handleDelete}>
        <FontAwesomeIcon icon={faTimes} className='text-light_coral' />
      </div>
    )
  }
}
