import { useEffect } from 'react'
import CreationSteps from '../components/CreationSteps'
import { useAuth } from '../hooks'

const CreateLobby = ({ initialValues }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: `${window.location.href}?`
      })
    }
  }, [isAuthenticated])

  return <>{isAuthenticated && <CreationSteps initialValues={initialValues} />}</>
}

export default CreateLobby
