import { Close } from "@mui/icons-material"
import { useState } from "react"
import { Mixpanel } from "../../Mixpanel"

export default () => {
    const params = new URLSearchParams(window.location.search)
    const embedded = params.get('embedded') || localStorage.getItem('embedded')
    const [hideBanner, setHideBanner] = useState(false)
    const userAgent = navigator.userAgent || window.opera
    const platform = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream ? 'ios' : /android/i.test(userAgent) ? 'android' : 'web'

    const handleClick = () => {
        Mixpanel.track('Click Banner Descargar App', {
            'Plataforma': platform
        })
        if (platform === 'ios') {
            window.location = 'https://apps.apple.com/app/id1645625062'
        } else if (platform === 'android') {
            window.location = 'https://play.google.com/store/apps/details?id=com.viatik.app'
        }
    }

    if (embedded) return null

    return (
        <>
            <div className={`flex w-full h-8 text-white text-xs sm:text-base items-center justify-between bg-gradient-to-r from-primary via-light_coral to-orange-500 pr-2 ${hideBanner && 'hidden'}`}>
                <div onClick={handleClick} className='w-6' />
                <div onClick={handleClick} className='items-center justify-center text-center flex'>
                    {
                        platform === 'ios' ? <AppStoreLogo />
                            : platform === 'android' ? <PlayStoreLogo />
                                :
                                <>
                                    <AppStoreLogo />
                                    <PlayStoreLogo />
                                </>
                    }
                    <p>Descargar la app de Viatik</p>
                </div>
                <div className='text-sm cursor-pointer' onClick={() => setHideBanner(true)} >
                    <Close fontSize='small' />
                </div>
            </div>
        </>
    )
}

const AppStoreLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-4 mr-2'>
            <path
                d="M255.9 120.9l9.1-15.7c5.6-9.8 18.1-13.1 27.9-7.5 9.8 5.6 13.1 18.1 7.5 27.9l-87.5 151.5h63.3c20.5 0 32 24.1 23.1 40.8H113.8c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4h52l66.6-115.4-20.8-36.1c-5.6-9.8-2.3-22.2 7.5-27.9 9.8-5.6 22.2-2.3 27.9 7.5l8.9 15.7zm-78.7 218l-19.6 34c-5.6 9.8-18.1 13.1-27.9 7.5-9.8-5.6-13.1-18.1-7.5-27.9l14.6-25.2c16.4-5.1 29.8-1.2 40.4 11.6zm168.9-61.7h53.1c11.3 0 20.4 9.1 20.4 20.4 0 11.3-9.1 20.4-20.4 20.4h-29.5l19.9 34.5c5.6 9.8 2.3 22.2-7.5 27.9-9.8 5.6-22.2 2.3-27.9-7.5-33.5-58.1-58.7-101.6-75.4-130.6-17.1-29.5-4.9-59.1 7.2-69.1 13.4 23 33.4 57.7 60.1 104zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z"
                fill="#ffffff"
            />
        </svg>
    )
}

const PlayStoreLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-4 mr-2'>
            <path
                d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                fill="#ffffff"
            />
        </svg>
    )
}

