import { useState } from 'react'
import Modal from 'react-modal'
import QRCode from 'react-qr-code'
import qrModalStyles from './qrModalStyles'
import { Mixpanel } from '../../Mixpanel'

const BusTicket = ({ passenger, boardingStation }) => {
  const [isPressed, setIsPressed] = useState(false)
  const [qrModal, setQrModal] = useState(false)

  const handleMouseDown = () => {
    setIsPressed(true)
  }

  const handleMouseUp = () => {
    setIsPressed(false)
  }

  const handleClick = () => {
    Mixpanel.track('Botón Agrandar QR')
    setQrModal(true)
  }

  return (
    <>
      <div
        className={`flex flex-row gap-4 bg-primary text-[#fff] justify-center text-base h-38 mb-7 mx-6 pb-4 pt-3 px-4 cursor-pointer rounded-xl shadow-lg ${isPressed && 'opacity-50'}`}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={() => setIsPressed(false)}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
      >
        <div className='flex items-center'>
          <QRCode value={passenger.qr_code} size={120} bgColor='#d65944' fgColor='#fff' />
        </div>
        <div className='flex flex-col justify-between w-full'>
          <p>{boardingStation}</p>
          <p>Boleto: {passenger.ticket_id}</p>
          <p>Asiento {passenger.seat}</p>
        </div>
      </div>
      <Modal isOpen={qrModal} onRequestClose={() => setQrModal(false)} style={qrModalStyles}>
        <QRCode value={passenger.qr_code} size={230} bgColor='#d65944' fgColor='#fff' />
      </Modal>
    </>
  )
}

export default BusTicket