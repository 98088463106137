import Modal from 'react-modal'
import Button from '../../commons/Button'
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks'
import ScheduleAlert from '../ScheduleAlert'
import { Mixpanel } from '../../Mixpanel'
import styles from './styles'

const ScheduleModal = ({ visible, setVisible, searchData, initialValues }) => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth()
  const [scheduleAlert, setScheduleAlert] = useState(false)
  const params = new URLSearchParams(window.location.search)


  const handleSubmit = () => {
    if (isAuthenticated) {
      setScheduleAlert(true)
    } else {
      if (!searchData?.origin) {
        loginWithRedirect({
          appState: `${window.location.origin
            }/?navigation=SearchLobby&searchData=${JSON.stringify(
              initialValues
            )}&schedule=true`,
        })
      } else {
        loginWithRedirect({
          appState: `${window.location.origin
            }/?navigation=SearchLobby&searchData=${JSON.stringify(
              searchData
            )}&schedule=true`,
        })
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated && params.get('schedule')) {
      Mixpanel.track('Auth0 a agendar', {
        'Origin': searchData.originName,
        'Destination': searchData.destinationName,
        'Fecha': searchData.selectedDate,
        'Autenticado': isAuthenticated,
        'Usuario': user,
      })
      handleSubmit()
    }
  }, [])

  const onClose = () => {
    Mixpanel.track('Cancelar Empty', {
      'Origin': searchData.originName,
      'Destination': searchData.destinationName,
      'Fecha': searchData.selectedDate,
      'Autenticado': isAuthenticated,
      'Usuario': user,
    })
    setScheduleAlert(false)
    setVisible(false)
  }

  return (
    <Modal
      isOpen={visible}
      style={styles}
      contentLabel='Empty'
    >
      <div className='flex flex-col justify-center pl-6 pr-6'>
        <div className='mb-3'>
          <h2 className='text-center text-2xl'>
            Estamos buscando un conductor que coincida contigo
          </h2>
          <br />
          <p className='text-center text-lg'>
            Puedes recibir una notificación en el caso que encontremos un viaje 🚗
          </p>
        </div>
        <div className='flex flex-col w-full mt-3 gap-4'>
          {
            scheduleAlert ?
              <ScheduleAlert
                searchData={searchData}
                setScheduleAlert={setScheduleAlert}
              />
              :
              <Button
                name={'Notificarme'}
                style={{ marginBottom: 15, zIndex: 300, width: '100%' }}
                onClick={() => {
                  Mixpanel.track('Boton Agendar', {
                    'Origin': searchData.originName,
                    'Destination': searchData.destinationName,
                    'Fecha': searchData.selectedDate,
                    'Autenticado': isAuthenticated,
                    'Usuario': user,
                  })
                  handleSubmit()
                }}
              />
          }
          <Button
            name={'Volver'}
            style={{
              marginBottom: 15,
              zIndex: 300,
              backgroundColor: 'gray',
              width: '100%',
            }}
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ScheduleModal