import styles from '../../UserInfo/styles'
import { ChevronRight, StarOutlined } from '@mui/icons-material'
import level from '../../Level/index.js'

const ProfileReview = ({ userInfo, onClick }) => {
  return (
    <div style={styles.secondSubcontainer}>
      <div style={styles.likesContainer} onClick={userInfo.reviews > 0 ? onClick : () => {}}>
        <StarOutlined sx={{ color: '#9ca3af', fontSize: 24 }} />
        <div style={styles.likesSubcontainer}>
          <p style={styles.likesText}>{userInfo.stars?.toFixed(1)} - {userInfo.reviews} opiniones</p>
          {userInfo.reviews > 0 && <ChevronRight sx={{ color: '#9ca3af', fontSize: 24 }} />}
        </div>
      </div>
      <div style={styles.levelContainer} >
        <p style={styles.levelText}>{level(userInfo.level)}</p>
        <p style={styles.subtitle}>Nivel de exp.</p>
      </div>
    </div>
  )
}

export default ProfileReview
