import Locations from './Locations'
import Info from './Info'

const BusCard = ({ bus, full }) => {
  return (
    <>
      <Locations
        originName={bus.Journey.origin}
        destinationName={bus.Journey.destination}
        style={{
          color: '#c65944',
          fontSize: '1.5rem'
        }}
        ownerName={bus.Company_info.name}
        ownerPicture={bus.Company_info.picture}
        full={full}
        bus
        type={bus.Journey.type}
      />
      <Info
        time={bus.Journey.departure_time}
        price={bus.price}
        full={full}
        discount={bus.discount}
        currency={bus.currency}
      />
    </>
  )
}

export default BusCard