import { useAuth } from '../../hooks/'
import { useFetch } from '../../hooks/'
import { uri } from '../../config/Api'
import { useState, useEffect } from 'react'
import auth0Config from '../../config/Auth0'
import { ThemeProvider } from '@mui/material'
import theme from '../ProfileInfo/theme.js'
import Modal from 'react-modal'
import handleClose from '../ProfileInfo/events/handleClose.js'
import customStyles from '../ProfileInfo/styles/customStyles.js'
import {
  Divider,
} from '@mui/material'
import ProfilePicture from '../../commons/ProfilePicture/index.js'
import styles from '../UserInfo/styles.js'
import SectionDivider from '../../commons/SectionDivider/index.js'
import EditPicture from '../ProfileInfo/EditPicture.js'
import EditProfile from '../ProfileInfo/EditProfile.js'
import EditProfileButton from '../Profile/EditProfileButton'
import ProfileView from './ProfileView'
import ProfileReview from './ProfileReview'
import ProfileAboutMe from './ProfileAboutMe'
import ProfileRecord from './ProfileRecord'
import EditProfileName from '../Profile/EditProfileName'
import EditProfileGender from '../Profile/EditProfileGender'
import EditProfilePhone from '../Profile/EditProfilePhone'
import EditProfileBirthday from '../Profile/EditProfileBirthday'
import EditProfileMail from '../Profile/EditProfileMail'
import EditProfileAboutMe from '../Profile/EditProfileAboutMe'
import EditProfileSave from '../Profile/EditProfileSave'
import EditProfilePreferences from '../Profile/EditProfilePreferences'
import UserReviews from '../UserReviews'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { Mixpanel } from '../../Mixpanel'

const ProfileInfo = ({ visible, setVisible, setProfileImg }) => {
  var { user, logout } = useAuth()
  const [image, setImage] = useState(user.picture)
  const [phoneNumber, setPhoneNumber] = useState(user[auth0Config.namespace + 'phone_number'])
  const [firstName, setFirstName] = useState(user[auth0Config.namespace + 'first_name'])
  const [lastName, setLastName] = useState(user[auth0Config.namespace + 'last_name'])
  const [gender, setGender] = useState(user[auth0Config.namespace + 'gender'])
  const [genderSelection, setGenderSelection] = useState(user[auth0Config.namespace + 'gender'])
  const [selectedDate, setSelectedDate] = useState(
    user[auth0Config.namespace + 'birthdate'] ?
      new Date(user[auth0Config.namespace + 'birthdate'])
      :
      user[auth0Config.namespace + 'birthdate']
  )
  const [aboutMe, setAboutMe] = useState(user[auth0Config.namespace + 'biography'])
  const [preferences, setPreferences] = useState(user[auth0Config.namespace + 'preferences'])
  const [edit, setEdit] = useState(false)
  const [edited, setEdited] = useState(false)
  const [pictureEdited, setPictureEdited] = useState(false)
  const age = (((new Date())?.getTime() - (new Date(selectedDate))?.getTime()) / (365 * 24 * 60 * 60 * 1000))
  const { responseOk, data, loading } = useFetch(`${uri}/users/id/${user.sub}`)
  const [userInfo, setUserInfo] = useState({})
  const [showReviews, setShowReviews] = useState(false)
  const [biographyError, setBiographyError] = useState(false)

  useEffect(() => {
    if (responseOk) {
      setUserInfo(data)
    }
  }, [loading])

  return (
    <ThemeProvider theme={theme}>
      <Modal
        isOpen={visible}
        onRequestClose={() => handleClose(user, setVisible)}
        style={customStyles}
      >
        {
          loading ?
            <div className='flex justify-center'>
              <LoadingIndicator />
            </div>
            :
            <>
              <EditProfileButton user={user} edit={edit} setEdit={setEdit} disabled={edit} setVisible={setVisible} />
              <div style={styles.container}>
                <div style={styles.firstSubcontainer}>
                  <ProfilePicture src={image} firstName={firstName} setImage={setImage} setPictureEdited={setPictureEdited} />
                  {
                    !edit &&
                    <>
                      <ProfileView firstName={firstName} lastName={lastName} gender={gender} age={calculateAge(selectedDate)} phoneNumber={phoneNumber} verified={data?.verified} />
                      <SectionDivider />
                      <ProfileReview userInfo={userInfo} onClick={() => setShowReviews(true)} />
                      <SectionDivider />
                      <ProfileAboutMe userInfo={userInfo} aboutMe={aboutMe} preferences={preferences} />
                      <SectionDivider />
                      <ProfileRecord userInfo={userInfo} />
                      <SectionDivider />
                      <p
                        onClick={() => {
                          Mixpanel.track('Boton Cerrar Sesion')
                          logout({ returnTo: window.origin })
                        }}
                        className='text-error font-bold hover:text-light_coral text-base cursor-pointer pb-5'
                      >
                        Cerrar sesión
                      </p>
                    </>
                  }
                </div>
                <>
                  {
                    edit &&
                    <div className='flex flex-col justify-center items-center'>
                      <div className='flex flex-col gap-6 px-6'>
                        <EditProfileName firstName={firstName} lastName={lastName} setFirstName={setFirstName} setLastName={setLastName} edit={edit} />
                        <EditProfileBirthday edit={edit} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                        <EditProfilePhone phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                        <EditProfileAboutMe edit={edit} age={age} gender={gender} aboutMe={aboutMe} setAboutMe={setAboutMe} biographyError={biographyError} />
                        <EditProfileGender genderSelection={genderSelection} setGender={setGender} setGenderSelection={setGenderSelection} />
                        <EditProfilePreferences preferences={preferences} setPreferences={setPreferences} edit={edit} gender={gender} />
                        <EditProfileSave edit={edit} setEdited={setEdited} setEdit={setEdit} firstName={firstName} user={user} aboutMe={aboutMe} preferences={preferences} setBiographyError={setBiographyError} />
                      </div>
                    </div>
                  }
                </>
              </div>

              {
                edited ?
                  <EditProfile
                    visible={edited}
                    setVisible={setEdited}
                    userInfo={{
                      phoneNumber,
                      firstName,
                      lastName,
                      selectedDate,
                      aboutMe,
                      preferences,
                      gender,
                    }}
                  />
                  :
                  <></>
              }
              {pictureEdited ? (
                <EditPicture
                  visible={pictureEdited}
                  setVisible={setPictureEdited}
                  picture={image}
                  setProfileImg={setProfileImg}
                />
              ) : (
                <></>
              )}
            </>
        }
      </Modal>
      {
        showReviews &&
        <UserReviews user={user.sub} onClose={() => setShowReviews(false)} userInfo={userInfo} />
      }
    </ThemeProvider>
  )
}

const calculateAge = (birthday) => {
  var ageDifMs = Date.now() - birthday.getTime()
  var ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export default ProfileInfo
