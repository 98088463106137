import Button from '../../../commons/Button/index.js'
import { containsContactInfo } from '../../../utils'
import handleEdit from '../../ProfileInfo/events/handleEdit.js'

const EditProfileSave = ({ edit, setEdited, setEdit, firstName, user, aboutMe, preferences, setBiographyError }) => {
  const handleSubmit = () => {
    if (containsContactInfo(aboutMe)) {
      setBiographyError(true)
    } else {
      handleEdit(edit, setEdited, setEdit, firstName, user, user.picture, aboutMe, preferences)
    }
  }

  return (
    <Button
      name={'Guardar'}
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        zIndex: 300,
      }}
      onClick={handleSubmit}
    />
  )
}

export default EditProfileSave
