import { useEffect, useState } from 'react'
import HourMinutes from './HourMinutes'

const TimePicker = ({ setData, data, style }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
  }, [])

  const handleOpen = () => {
    setOpenModal(true)
  }

  return (
    <>
      <div
        onClick={handleOpen}
        className='flex flex-row items-center gap-3 border-solid border-[#A4A7B5] border-[1px] rounded-[20px] p-[8px] px-3 text-[1rem] leading-[1.4375em]'
        style={style}
      >
        {data}
      </div>
      {openModal && (
        <HourMinutes time={data} setTime={setData} open={openModal} setOpen={setOpenModal} />
      )}
    </>
  )
}

export default TimePicker
