import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons'
import { TextField, ThemeProvider } from '@mui/material'
import { containsContactInfo, muiInputTheme } from '../../utils'
import { WarningAmberRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'

export default ({ sendMessage, disabled, channel }) => {
  const hideHeader = new URLSearchParams(window.location.search).get('hide-header')
  const [message, setMessage] = useState('')

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey === false && message.trim().length > 0 && !isMobile()) {
      sendMessage(message)
      if (!containsContactInfo(message)) setMessage('')
      e.preventDefault()
    }
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  }

  return (
    <>
      {disabled ? (
        <div
          className={`flex flex-row items-center justify-center gap-3 mt-3 px-4 ${
            hideHeader ? 'pb-4' : 'pb-11'
          }`}
        ></div>
      ) : (
        <>
          <div
            className={`flex flex-row items-center justify-center px-3 pt-1 pb-2 gap-2 bg-light_gray ${
              (!channel || channel?.type == 'lobbygroup') && 'hidden'
            }`}
          >
            <WarningAmberRounded sx={{ color: '#ef4444' }} />
            <p className='text-xs text-error'>
              Evita pagar fuera de la plataforma. Perderás todas las garantías, te volves vulnerable
              ante fraudes y puede resultar en la suspensión permanente de tu cuenta.
            </p>
          </div>
          <div
            className={`flex flex-row items-center gap-3 mt-3 px-4 ${
              hideHeader && channel?.lobby_id
                ? 'pb-20'
                : channel?.lobby_id
                ? 'pb-[9rem]'
                : hideHeader
                ? 'pb-4'
                : 'pb-20'
            }`}
          >
            <FontAwesomeIcon icon={faPlus} size='lg' color='white' />
            <ThemeProvider theme={muiInputTheme}>
              <TextField
                fullWidth
                multiline
                maxRows={4}
                InputProps={{ style: { padding: 10 } }}
                value={message}
                onChange={e => setMessage(e.target.value)}
                disabled={disabled}
                onKeyDown={handleKeyDown}
              />
            </ThemeProvider>
            <div
              onClick={() => {
                if (message.trim().length == 0) return
                sendMessage(message)
                if (!containsContactInfo(message)) setMessage('')
              }}
              className={`flex items-center justify-center p-2 w-10 h-10 rounded-[50%] ${
                message.trim().length > 0 ? 'bg-light_coral focus:bg-primary' : 'bg-gray'
              } cursor-pointer`}
            >
              <FontAwesomeIcon icon={faPaperPlane} color='white' />
            </div>
          </div>
        </>
      )}
    </>
  )
}
