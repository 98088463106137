import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useAuth, useFetch } from '../../hooks/'
import auth0Config from '../../config/Auth0'
import Modal from 'react-modal'
import Button from '../Button'
import Alert from '../Alert'
import Join from './Join'
import UserInfo from '../../components/UserInfo'
import { IconButton } from '@mui/material'
import Avatar from '../Avatar'
import { People, ChevronRight, ArrowBackIosNew, QuestionAnswerRounded } from '@mui/icons-material'
import Create from './Create'
import { Mixpanel } from '../../Mixpanel'
import appConfig, { DIAS, MESES } from '../../config/App'
import Trip from '../../components/Trip'
import VerifyLobby from '../../components/VerifyLobby'
import { uri } from '../../config/Api'
import LoadingIndicator from '../LoadingIndicator'
import styles from './styles'
import AskDriver from '../../components/AskDriver'
import { getCurrency } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons'
import { faComments } from '@fortawesome/free-regular-svg-icons'

const LobbyInfo = ({ visible, setVisible, lobby, seats, type, returnLobby, start, end }) => {
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded') || localStorage.getItem('embedded')
  const { isAuthenticated, loginWithRedirect, loginWithPopup, user } = useAuth()
  const [join, setJoin] = useState(false)
  const [create, setCreate] = useState(false)
  const [sameUserAlertValue, setSameUserAlertValue] = useState(false)
  const [showUserInfo, setShowUserInfo] = useState(false)
  const [seatsAvailableAlert, setSeatsAvailableAlert] = useState(false)
  const [verifyLobby, setVerifyLobby] = useState(false)
  const [askDriver, setAskDriver] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [scrollHeight, setScrollHeight] = useState(0)
  const buttonRef = useRef(null)
  const headerRef = useRef(null)
  const lobbyVacia = Object.keys(lobby).length === 0

  useEffect(() => {
    if (type == 'join') {
      Mixpanel.track('LobbyInfo Join', {
        Lobby: lobby,
        Usuario: user
      })
    } else {
      setLoading(false)
      Mixpanel.track('LobbyInfo Create', {
        Lobby: lobby,
        Usuario: user
      })
    }
  }, [])

  useEffect(() => {
    if (params.get('join')) {
      setVerifyLobby(true)
    }
  }, [])

  useEffect(() => {
    if (params.get('askDriver')) {
      setAskDriver(true)
    }
  }, [])

  useEffect(() => {
    if (buttonRef.current && headerRef.current) {
      setScrollHeight(
        window.innerHeight - buttonRef.current.clientHeight - headerRef.current.clientHeight
      )
    }
  }, [loading])

  useEffect(() => {
    const listner = window.addEventListener('resize', () => {
      if (!buttonRef.current || !headerRef.current) return
      setScrollHeight(
        window.innerHeight - buttonRef.current.clientHeight - headerRef.current.clientHeight
      )
    })

    return () => window.removeEventListener('resize', listner)
  }, [loading])

  if (lobbyVacia) return <></>

  let joinData
  if (type == 'join') {
    joinData = {
      start: lobby.Journey.start
        ? lobby.Journey.start.coordinates.toString()
        : lobby.Journey.origin.coordinates.toString(),
      end: lobby.Journey.end
        ? lobby.Journey.end.coordinates.toString()
        : lobby.Journey.destination.coordinates.toString(),
      seats: parseInt(seats)
    }
  }

  const origin_name = lobby.Journey.start_name
    ? lobby.Journey.start_name.formatted_address
    : lobby.Journey.origin_name.formatted_address
  const destination_name = lobby.Journey.end_name
    ? lobby.Journey.end_name.formatted_address
    : lobby.Journey.destination_name.formatted_address
  const departureDateTime = new Date(
    new Date(lobby.Journey.departure_time).getTime() + 3 * 60 * 60 * 1000
  )
  const departureTime = lobby.Journey.departure_time.substr(
    lobby.Journey.departure_time.indexOf('T') + 1,
    5
  )
  let arrivalTime
  if (type == 'join') {
    arrivalTime = lobby.Journey.estimated_time.substr(
      lobby.Journey.estimated_time.indexOf('T') + 1,
      5
    )
  }

  const departureDate =
    DIAS[departureDateTime.getDay()] +
    departureDateTime.getDate() +
    ' de ' +
    MESES[departureDateTime.getMonth()]

  const today = new Date(new Date().getTime() - 3 * 60 * 60 * 1000).toISOString()
  const late = lobby.Journey.departure_time <= today

  const handleJoin = () => {
    if (!isAuthenticated) {
      const url = `${window.location.origin}/join/${lobby._id}?seats=${seats}&join=true`
      const referedBy = params.get('referedBy')
      loginWithRedirect({
        appState: referedBy ? `${url}&referedBy=${referedBy}` : url
      })
    } else if (isAuthenticated && lobby.Owner_info.id === user.sub) {
      Mixpanel.track('SameUser Unirse', {
        Usuario: user,
        Lobby: lobby
      })
      setSameUserAlertValue(true)
    } else if (isAuthenticated && seats > lobby.seats_remaining) {
      Mixpanel.track('Asientos extra Unirse', {
        Usuario: user,
        Lobby: lobby
      })
      setSeatsAvailableAlert(true)
    } else if (isAuthenticated) {
      setVerifyLobby(true)
    }
  }

  const handleCreate = () => {
    if (isAuthenticated) {
      setCreate(true)
    } else {
      loginWithPopup().then(() => setCreate(true))
    }
  }

  const handleClose = () => {
    setVisible(false)
    setJoin(false)
    setCreate(false)
  }

  return (
    <>
      {create ? (
        <>
          <Create lobby={lobby} handleClose={() => setCreate(false)} />
          {returnLobby && (
            <Create
              lobby={returnLobby}
              handleClose={() => setCreate(false)}
              returnLobby={returnLobby}
            />
          )}
        </>
      ) : (
        <Modal
          isOpen={visible}
          onRequestClose={() => {
            if (create) {
              if (!embedded) {
                window.location.href = appConfig.website
              }
            } else {
              setVisible(false)
              setJoin(false)
            }
          }}
          style={styles}
        >
          {loading ? (
            <div className='w-full h-full flex justify-center items-center'>
              <LoadingIndicator />
            </div>
          ) : isAuthenticated && join && user[auth0Config.namespace + 'registered'] ? (
            <Join user={user} id={lobby._id} joinData={joinData} handleClose={handleClose} />
          ) : askDriver ? (
            <AskDriver
              lobby={lobby._id}
              start={lobby.Journey.start_name.locality}
              end={lobby.Journey.end_name.locality}
              driver={lobby.Owner_info}
              goBack={() => setAskDriver(false)}
            />
          ) : (
            <>
              <div>
                <IconButton
                  ref={headerRef}
                  onClick={handleClose}
                  style={{ paddingTop: 20, paddingLeft: 25 }}
                  sx={{ color: '#f25c45' }}
                >
                  <ArrowBackIosNew />
                </IconButton>
              </div>
              <div
                className='overflow-auto flex-col flex justify-between'
                style={{
                  height: scrollHeight
                }}
              >
                <div className='w-full flex flex-col mt-4 text-2xl gap-4 sm:items-center'>
                  <div
                    className={`w-full flex justify-between px-10 gap-4 items-center text-xl text-primary hover:bg-gray-100 sm:justify-center ${
                      type === 'join' && 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (type === 'create') return
                      Mixpanel.track('Click conductor')
                      setShowUserInfo(true)
                    }}
                  >
                    <Driver lobby={data ?? lobby} type={type} />
                  </div>
                  {type == 'join' && data.Owner_info.verified && (
                    <div className='px-10'>
                      <div className='flex flex-row items-center text-base mt-4 mb-6 gap-2 text-gray'>
                        <FontAwesomeIcon icon={faCheckCircle} className='text-light_coral' />
                        Perfil verificado con CI
                      </div>
                      <div className='w-full h-[0.5px] bg-gray' />
                    </div>
                  )}
                  {type == 'join' && (
                    <div
                      className='flex flex-row items-center text-base text-light_coral my-2 gap-2 px-10'
                      onClick={() => {
                        Mixpanel.track('Boton preguntar al conductor')
                        if (!isAuthenticated) {
                          loginWithRedirect({
                            appState: `${window.location.origin}/join/${lobby._id}?seats=${seats}&askDriver=true`
                          })
                        } else {
                          setAskDriver(true)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faComments} />
                      <p>Hacele una pregunta a {lobby.Owner_info.first_name}</p>
                    </div>
                  )}
                  <div className='w-full h-[30px] bg-light_gray' />
                  <div className='w-full flex flex-col text-lg sm:items-center'>
                    <div className='px-10'>
                      <Trip
                        start={origin_name}
                        end={destination_name}
                        originName={lobby.Journey.origin_name.formatted_address}
                        destinationName={lobby.Journey.destination_name.formatted_address}
                        departureDate={departureDate}
                        departureTime={departureTime}
                        arrivalTime={arrivalTime}
                        dateStyle={{ fontWeight: '500', fontSize: 20 }}
                      />
                    </div>
                    <div className='w-full h-[7px] mb-8 bg-light_gray' />
                    <div className='flex gap-2 text-lg mb-8 px-10'>
                      <People style={{ color: '#d65944', fontSize: 26 }} />
                      {lobby.seats_remaining ? (
                        <div className='flex flex-col justify-center font-thin'>
                          {lobby.seats_remaining == 0
                            ? 'No hay asientos disponibles'
                            : `${lobby.seats_remaining} ${
                                lobby.seats_remaining == 1
                                  ? ' asiento disponible'
                                  : ' asientos disponibles'
                              }`}
                        </div>
                      ) : (
                        <div className='flex flex-col justify-center font-thin text-lg'>
                          {lobby.seats_available}{' '}
                          {lobby.seats_available == 1
                            ? ' asiento disponible'
                            : ' asientos disponibles'}
                        </div>
                      )}
                    </div>
                    <div className='flex gap-2 text-lg mb-8 px-10'>
                      <p className='text-primary'>{getCurrency(lobby.currency)}</p>
                      <p className='flex flex-row font-thin'>{lobby.price}</p>
                      <p className='text-primary font-thin'>por asiento</p>
                    </div>
                    {lobby.comment && (
                      <div className='flex flex-col text-lg mb-8 px-10'>
                        <div className='w-full h-[0.5px] bg-gray' />
                        <p className='font-thin text-gray mt-8'>{lobby.comment}</p>
                      </div>
                    )}
                    {lobby.Owner_info.car && (
                      <div className='flex flex-col text-lg mb-8 px-10'>
                        <div className='w-full h-[0.5px] bg-gray' />
                        <p className='mr-5 mb-1 font-thin mt-8'>{lobby.Owner_info.car.model}</p>
                        <p className='mr-5 text-base font-thin text-gray'>
                          {lobby.Owner_info.car.color}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className='h-[20%]' />
                </div>
                <div>
                  <div className='w-full mt-2 flex flex-col items-center gap-0'>
                    {type === 'join' ? (
                      <a
                        className='text-lg mb-2 mt-3'
                        style={{ color: '#f25c54' }}
                        href={`https://api.whatsapp.com/send/?phone=+59898415699&text=Hola,%20del%20viaje%20@${lobby._id}%20tengo%20la%20siguiente%20duda:%20`}
                        target='_blank'
                      >
                        Soporte
                      </a>
                    ) : (
                      <a
                        className='text-lg mb-2'
                        style={{ color: '#f25c54' }}
                        href={`https://api.whatsapp.com/send/?phone=+59898415699&text=Hola,%20tengo%20una%20duda%20creando%20mi%20viaje`}
                        target='_blank'
                      >
                        Soporte
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div
                className='flex flex-col px-10 sm:justify-center sm:items-center py-3 fixed sm:relative bottom-0 w-full bg-white'
                ref={buttonRef}
              >
                <Button
                  disabled={late}
                  visible={visible}
                  name={type === 'join' ? 'Reservar' : 'Confirmar'}
                  style={{ marginBottom: '10px', zIndex: 300 }}
                  onClick={() => {
                    if (type === 'join') {
                      Mixpanel.track('Boton unirse')
                      handleJoin()
                    } else {
                      Mixpanel.track('Boton crear')
                      handleCreate()
                    }
                  }}
                />
                <div>
                  <div className='text-xs text-center mb-2'>
                    Al {type === 'join' ? 'unirte' : 'crear un viaje'} aceptas nuestros{' '}
                    <a
                      style={{ color: '#f25c54' }}
                      onClick={() => {
                        window.open('https://www.viatik.com.uy/terminos', '_blank')
                      }}
                    >
                      Términos y condiciones
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
      <Alert visible={sameUserAlertValue} onClose={() => setSameUserAlertValue(false)}>
        <p>
          No puedes unirte a un viaje que tu creaste. Para hacer cambios toca tu foto de perfil en
          la parte superior y dirigete a "Mis viajes".
        </p>
        <a
          className='text-xl mb-2'
          style={{ color: '#f25c54' }}
          href={`https://api.whatsapp.com/send/?phone=+59898415699&text=Hola,%20de%20mi%20trayecto%20@${lobby._id}%20tengo%20la%20siguiente%20duda:%20`}
          target='_blank'
        >
          ¿Dudas?
        </a>
      </Alert>
      <Alert visible={seatsAvailableAlert} onClose={() => setSeatsAvailableAlert(false)}>
        <p>
          No hay suficientes asientos disponibles en este viaje. Intente reduciendo la cantidad de
          asientos o buscando otro viaje.
        </p>
      </Alert>
      {verifyLobby && (
        <VerifyLobby
          goBack={() => setVerifyLobby(false)}
          joinData={joinData}
          id={lobby._id}
          lobby={lobby}
        />
      )}
      {showUserInfo && (
        <UserInfo
          user={lobby.Owner_info.id}
          visible={showUserInfo}
          onClose={() => setShowUserInfo(false)}
        />
      )}
      {type == 'join' && (
        <JoinLobbyInfo
          lobbyId={lobby._id}
          start={start}
          end={end}
          setLoading={setLoading}
          setData={setData}
        />
      )}
    </>
  )
}

const JoinLobbyInfo = ({ lobbyId, start, end, setLoading, setData }) => {
  const { loading, data } = useFetch(`${uri}/lobbies/id/${lobbyId}?start=${start}&end=${end}`)

  useEffect(() => {
    if (!loading) {
      setLoading(false)
      setData(data)
    }
  }, [loading])
}

const Driver = ({ lobby, type }) => {
  return (
    <>
      <div className='flex flex-col justify-center gap-2'>
        {lobby?.Owner_info.first_name}
        {lobby?.Owner_info?.reviews > 0 && (
          <div className='flex flex-row items-center gap-2'>
            <FontAwesomeIcon icon={faStar} className='text-gray mb-1' size='xs' />
            <p
              style={{
                fontSize: 14,
                fontWeight: '500',
                color: '#9ca3af',
                lineHeight: '1.8'
              }}
            >
              {lobby?.Owner_info?.stars?.toFixed(1)} - {lobby?.Owner_info?.reviews}{' '}
              {lobby?.Owner_info?.reviews > 1 ? 'opiniones' : 'opinión'}
            </p>
          </div>
        )}
      </div>
      <div className='flex flex-row gap-3 items-center'>
        <Avatar
          alt={lobby.Owner_info.first_name}
          src={lobby.Owner_info.picture}
          sx={{ width: 60, height: 60 }}
          verified={lobby.Owner_info.verified}
          badgeSize={20}
        />
        {type === 'join' && <ChevronRight sx={{ fontSize: 34 }} />}
      </div>
    </>
  )
}

export default LobbyInfo
