import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: {
      Poppins: 'Poppins, sans-serif',
    }
  },
  shape: {
    borderRadius: 30,
  },
})

export default theme