const seat = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 44,
  height: 44,
  borderWidth: 2,
  borderColor: '#9ca3af',
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  marginLeft: 4,
  marginRight: 4,
}

export const styles = {
  layout: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 2,
    borderColor: '#9ca3af',
    borderRadius: 40,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 5,
    width: 240,
  },
  driver: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
  },
  seats: {
    display: 'flex',
    flexDirection: 'column',
  },
  seatRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  seatPair: {
    display: 'flex',
    flexDirection: 'row',
  },
  seat: {
    ...seat,
    cursor: 'pointer',
  },
  seatNumber: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#9ca3af',
  },
  selectedSeat: {
    ...seat,
    backgroundColor: '#d65944',
    borderColor: '#d65944',
    cursor: 'pointer',
  },
  selectedSeatNumber: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#fff',
  },
  occupiedSeat: {
    ...seat,
    backgroundColor: '#f3f4f6',
    borderColor: '#f3f4f6',
  },
  emptySeat: {
    ...seat,
    backgroundColor: '#fff',
    borderColor: '#fff',
  },
  containerDifferential: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  seatNumberDifferential: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#fff',
    position: 'absolute',
    marginTop: 4,
  },
  selectedSeatNumberDifferential: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#000',
    position: 'absolute',
    marginTop: 4,
  },
}

