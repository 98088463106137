import NextButton from './NextButton'
import { toDate } from '../../utils'
import { Application, Calendar } from 'react-rainbow-components'

export default ({ date, setDate, handleSelect }) => {
  return (
    <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
      <div className='sm:w-1/2'>
        <p className='text-start mb-6'>¿En qué fecha viajás?</p>
        <Application theme={rainbowTheme}>
          <Calendar
            minDate={toDate()}
            value={date}
            onChange={date => {
              setDate(date)
              handleSelect()
            }}
            locale='es'
          />
        </Application>
        {date && <NextButton onSubmit={handleSelect} />}
      </div>
    </div>
  )
}

const rainbowTheme = {
  rainbow: {
    palette: {
      brand: '#f25c45'
    }
  }
}
