import Avatar from '../../commons/Avatar'
import { Mixpanel } from '../../Mixpanel'
import GroupImage from '../GroupImage'
import LoadingIndicator from '../../commons/LoadingIndicator'
import SearchError from '../../commons/SearchError'
import { replaceAll } from '../../utils'

export default ({ setSelectedChannel, selectedChannel, channels, user }) => {
  const onSelect = channel => {
    if (selectedChannel?._id == channel._id) return
    Mixpanel.track('Chat Abierto', { Chat: channel._id })
    setSelectedChannel(null)
    setTimeout(() => {
      window.history.pushState({}, '', '/messages/' + channel._id + window.location.search)
      setSelectedChannel(channel)
    }, 100)
  }

  return (
    <div className='flex flex-col gap-2 md:w-1/2 overflow-auto h-[95%] md:h-[92%]'>
      {!channels ? (
        <div className='flex h-full justify-center items-center'>
          <LoadingIndicator />
        </div>
      ) : channels.length == 0 ? (
        !user ? (
          <div className='flex flex-col h-full justify-center items-center text-center px-5'>
            <SearchError />
          </div>
        ) : (
          <div className='flex flex-col h-full justify-center items-center px-5'>
            <img src={require('../../assets/img/empty-chat.png')} alt='vacio' className='w-full' />
            <p className='text-lg text-gray text-center'>No tenés mensajes en este momento</p>
          </div>
        )
      ) : (
        channels?.map(channel => (
          <ChannelPreview
            userId={user}
            key={channel._id}
            channel={channel}
            onSelect={() => onSelect(channel)}
            selected={selectedChannel?._id == channel._id}
          />
        ))
      )}
    </div>
  )
}

const ChannelPreview = ({ userId, channel, onSelect, selected }) => {
  const unreadMessages = userId
    ? channel.messages
        .filter(message => message.user_id != userId)
        .filter(message => !message.read_by?.includes(userId)).length
    : 0
  const directMessage = channel.users.find(user => user.user_id != userId)

  return (
    <div
      className={`flex flex-row items-center justify-between cursor-pointer hover:bg-light_gray p-3 rounded-xl mx-3 ${
        selected && 'bg-light_gray'
      }`}
      onClick={onSelect}
    >
      <div className='flex flex-row gap-4 items-center w-[93%]'>
        {channel.type !== 'lobbygroup' ? (
          <Avatar sx={{ width: 47, height: 47 }} src={directMessage?.picture} />
        ) : (
          <GroupImage
            images={channel.users.filter(user => user.active).map(user => user.picture)}
          />
        )}
        <div className='flex flex-col max-h-[3rem] w-[80%]'>
          <p className='text-base overflow-hidden block whitespace-nowrap text-ellipsis'>
            {channel.name ?? directMessage?.name}
          </p>
          <p className='text-sm text-gray pr-3 overflow-hidden block whitespace-nowrap text-ellipsis'>
            {lastMessage(channel, userId)}
          </p>
        </div>
      </div>
      <div className='w-[7%]'>
        {unreadMessages > 0 ? (
          <div className='flex text-white text-xs items-center justify-center bg-primary rounded-[50%] w-6 h-6 p-3'>
            {unreadMessages}
          </div>
        ) : (
          <div className='w-6 h-6' />
        )}
      </div>
    </div>
  )
}

const lastMessage = (channel, userId) => {
  const lastMessage = channel.messages[channel.messages.length - 1]

  if (!lastMessage) return ''

  const imageOnly = !lastMessage.text && lastMessage.image
  const imageAndText = lastMessage.text && lastMessage.image

  if (lastMessage.user_id == userId) {
    if (imageOnly) {
      return '📷 Imagen'
    } else if (imageAndText) {
      return `📷 ${formatText(lastMessage.text)}`
    } else {
      return formatText(lastMessage.text)
    }
  } else {
    if (imageOnly) {
      return `${lastMessage.user.name}: 📷 Imagen`
    } else if (imageAndText) {
      return `${lastMessage.user.name}: 📷 ${formatText(lastMessage.text)}`
    } else {
      return `${lastMessage.user.name}: ${formatText(lastMessage.text)}`
    }
  }
}

const formatText = text => {
  let formattedText = replaceAll(replaceAll(text, '\n', ' '), '*', '')

  return formattedText
}
