import { useState, useEffect } from 'react'
import Locations from './Locations'
import { CalendarMonth } from '@mui/icons-material'
import { CardContainer } from './styles'
import { Mixpanel } from '../.././../Mixpanel'

const Card = ({ lobby, setSelected, setVisible, user }) => {
  const [origin, setOrigin] = useState(lobby.Journey.origin_name.locality)
  const [destination, setDestination] = useState(lobby.Journey.destination_name.locality)

  useEffect(() => {
    lobby.companions.forEach(companion => {
      if (companion.user_id === user && companion.start) {
        lobby.Journey.stops.forEach(stop => {
          if (JSON.stringify(companion.start.coordinates) == JSON.stringify(stop.location.coordinates)) {
            setOrigin(stop.location_name)
          }
          if (JSON.stringify(companion.end.coordinates) == JSON.stringify(stop.location.coordinates)) {
            setDestination(stop.location_name)
          }
        })
      }
    })
  }, [])

  const Info = ({ time, name }) => {
    const today = new Date((new Date()).getTime() - 3 * 60 * 60 * 1000).toISOString()
    const full = (lobby.seats_remaining == 0)
    const inProgress = (today >= lobby.Journey.departure_time && today <= lobby.Journey.estimated_time)
    const finished = (today >= lobby.Journey.estimated_time)

    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'end' }}
        onClick={() =>
          Mixpanel.track('Card apretada', {
            'Lobby': lobby
          })
        }
      >
        <div style={{ display: 'flex', gap: '1' }} >
          <p className='flex flex-col justify-center text-base'>
            {time.substr(time.indexOf('-') + 4, 2) + '/' + time.substr(time.indexOf('-') + 1, 2)}
          </p>
          <CalendarMonth fontSize='medium' style={{ color: '#c65944' }} />
        </div>
        <p className='text-base'>{name}</p>
        {
          finished ?
            <div className='flex gap-1 text-red-600 text-sm'>
              REALIZADO
            </div>
            :
            <></>
        }
        {
          inProgress ?
            <div className='flex gap-1 text-green-600 text-sm'>
              ACTIVO
            </div>
            :
            <></>
        }
      </div>
    )
  }

  return (
    <CardContainer
      onClick={() => {
        setSelected(lobby)
        setVisible(true)
      }}
    >
      <Locations
        originName={origin}
        destinationName={destination}
      />
      <Info
        time={lobby.Journey.departure_time}
        name={lobby.Owner_info.first_name}
      />
    </CardContainer>
  )
}

const sumarArray = (arr) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i += 1) {
    sum += arr[i]
  }
  return sum
}

export default Card