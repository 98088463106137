import { Mixpanel } from "../../../Mixpanel"

const handleClose = (user, setVisible) => {
  Mixpanel.track('Cerrar Perfil', {
    'Usuario': user,
  })
  window.history.pushState({}, '', '/')
  setVisible(false)
}


export default handleClose
