import { useState } from 'react'
import BusCard from './BusCard'
import LobbyCard from './LobbyCard'

const Card = ({ item, onPress }) => {
  const [isPressed, setIsPressed] = useState(false)
  const full = item.seats_remaining === 0

  const handleMouseDown = () => {
    if (!full) {
      setIsPressed(true)
    }
  }

  const handleMouseUp = () => {
    if (!full) {
      setIsPressed(false)
    }
  }

  const handleClick = () => {
    if (!full) {
      onPress(item)
    }
  }

  return (
    <div
      className={`flex justify-between text-lg h-60 mb-7 mx-4 pt-5 pb-5 px-4
                lg:mx-60 lg:pl-12 lg:pr-12 cursor-pointer rounded-xl shadow-lg 
                ${isPressed && 'opacity-50'}
                ${full && 'cursor-auto'}`
      }
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => setIsPressed(false)}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
    >
      {
        item.Owner_info ?
          <LobbyCard lobby={item} full={full} />
          :
          <BusCard bus={item} full={full}/>
      }
    </div>
  )
}

export default Card