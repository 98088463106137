import { useFetch } from '../../hooks'
import { uri } from '../../config/Api'
import { useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { Mixpanel } from '../../Mixpanel'
import styles from './styles'
import ProfileView from '../ProfileInfo/ProfileView'
import ProfileReview from '../ProfileInfo/ProfileReview'
import ProfileAboutMe from '../ProfileInfo/ProfileAboutMe'
import ProfileRecord from '../ProfileInfo/ProfileRecord'
import UserReviews from '../UserReviews'
import Avatar from '../../commons/Avatar'
import LoadingIndicator from '../../commons/LoadingIndicator'
import Modal from 'react-modal'
import customStyles from './customStyles'

const UserInfo = ({ user, visible, onClose }) => {
  const { responseOk, data, loading } = useFetch(`${uri}/users/id/${user}`)
  const [userInfo, setUserInfo] = useState({})
  const [showReviews, setShowReviews] = useState(false)

  useEffect(() => {
    Mixpanel.track('UserInfo', {
      'Perfil de Usuario': user
    })
  }, [])

  useEffect(() => {
    if (responseOk) {
      setUserInfo(data)
    }
  }, [loading])

  return (
    <>
      <Modal isOpen={visible} style={customStyles} key={user}>
        {loading ? (
          <div className='flex h-full justify-center items-center'>
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <div>
              <IconButton style={{ paddingTop: 10 }} onClick={onClose} sx={{ color: '#e65944' }}>
                <ArrowBackIos />
              </IconButton>
            </div>
            <div style={styles.container}>
              <div style={styles.firstSubcontainer}>
                <Avatar
                  src={userInfo.picture}
                  sx={{ width: 100, height: 100 }}
                  verified={userInfo.verified === true}
                  badgeSize={30}
                />
                <ProfileView
                  firstName={userInfo.first_name}
                  lastName={userInfo.last_name}
                  gender={userInfo.gender}
                  age={userInfo.age}
                  phoneNumber={userInfo.phone_number}
                />
                <SectionDivider />
                {userInfo.reviews > 0 && (
                  <>
                    <ProfileReview userInfo={userInfo} onClick={() => setShowReviews(true)} />
                    <SectionDivider />
                  </>
                )}
                <ProfileAboutMe
                  userInfo={userInfo}
                  aboutMe={userInfo.biography}
                  preferences={userInfo.preferences}
                />
                <SectionDivider />
                <ProfileRecord userInfo={userInfo} />
              </div>
            </div>
          </>
        )}
      </Modal>
      {showReviews && (
        <UserReviews user={user} onClose={() => setShowReviews(false)} userInfo={userInfo} />
      )}
    </>
  )
}

const SectionDivider = () => {
  return <div style={styles.divider} />
}

export default UserInfo
