import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Button from '../../../commons/Button'
import {
  Divider,
  IconButton,
  TextField,
  MenuItem,
  createTheme,
  ThemeProvider,
  Typography,
} from '@mui/material'
import Avatar from '../../../commons/Avatar'
import {
  People,
  EditOutlined,
  ChevronRight,
  ArrowRightAltRounded,
  ArrowBackIos,
  PhoneIphoneRounded,
  StarOutlined,
} from '@mui/icons-material'
import Alert from '../../../commons/Alert'
import { useAuth } from '../../../hooks/'
import Spinner from '../../../commons/Spinner'
import TimePicker from '../../../commons/TimePicker'
import EditLobby from './EditLobby'
import DeleteLobby from './DeleteLobby'
import LeaveLobby from './LeaveLobby'
import { useAuthorizedFetch } from '../../../hooks'
import { uri } from '../../../config/Api'
import SearchError from '../../../commons/SearchError'
import UserInfo from '../../UserInfo'
import { Mixpanel } from '../../../Mixpanel'
import Trip from '../../Trip'
import { DIAS, MESES } from '../../../config/App'
import LeaveLobbyScreen from './LeaveLobbyScreen'
import LeaveLobbyReasonScreen from './LeaveLobbyReasonScreen'
import DeleteLobbyScreen from './DeleteLobbyScreen'
import DeleteLobbyReasonScreen from './DeleteLobbyReasonScreen'
import LoadingIndicator from '../../../commons/LoadingIndicator'
import { containsContactInfo, getCurrency } from '../../../utils'

const theme = createTheme({
  typography: {
    fontFamily: {
      Poppins: 'Poppins, sans-serif',
    }
  },
  shape: {
    borderRadius: 20,
  },
})

const LobbyInfo = ({ setVisible, setLobbies, lobbies, lobby }) => {
  const { loading, response } = useAuthorizedFetch(
    `${uri}/users/me/lobbies/${lobby._id}`
  )

  const { user } = useAuth()
  const [lobbyInfo, setLobbyInfo] = useState({})
  const [editAlert, setEditAlert] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [leaveAlert, setLeaveAlert] = useState(false)
  const [deleteLobby, setDeleteLobby] = useState(0)
  const [leaveLobby, setLeaveLobby] = useState(false)
  const [selectedCompanion, setSelectedCompanion] = useState({})
  const [edit, setEdit] = useState(false)
  const [price, setPrice] = useState(lobby.price)
  const [comment, setComment] = useState(lobby.comment)
  const [newTime, setNewTime] = useState('')
  const [estimatedTime, setEstimatedTime] = useState('')
  const [showUserInfo, setShowUserInfo] = useState(false)
  const [showCompanionInfo, setShowCompanionInfo] = useState(false)
  const [origin, setOrigin] = useState(lobby.Journey.start_name ? lobby.Journey.start_name.formatted_address : lobby.Journey.origin_name.formatted_address)
  const [destination, setDestination] = useState(lobby.Journey.end_name ? lobby.Journey.end_name.formatted_address : lobby.Journey.destination_name.formatted_address)
  const [companionLobby, setCompanionLobby] = useState(false)
  const [seats, setSeats] = useState(lobby.seats_available)
  const [deleteSelectedReason, setDeleteSelectedReason] = useState('')
  const [leaveSelectedReason, setLeaveSelectedReason] = useState('')
  const [commentError, setCommentError] = useState(false)

  const departureDateTime = new Date(lobby.Journey.departure_time)
  const departureTime = lobby.Journey.departure_time.substr(
    lobby.Journey.departure_time.indexOf('T') + 1,
    5
  )

  const [time, setTime] = useState(departureTime)

  const departureDatetimeToShow = new Date(
    new Date(lobby.Journey.departure_time).getTime() + 3 * 60 * 60 * 1000
  )

  const [arrivalTime, setArrivalTime] = useState(
    lobby.Journey.estimated_time.substr(
      lobby.Journey.estimated_time.indexOf('T') + 1,
      5
    )
  )
  const departureDate =
    DIAS[departureDatetimeToShow.getDay()] + ' ' +
    departureDatetimeToShow.getDate() +
    ' de ' +
    MESES[departureDatetimeToShow.getMonth()]
  const today = new Date()

  const late = today.getTime() > departureDateTime.getTime() + 3 * 60 * 60 * 1000

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 0,
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  }

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setLobbyInfo(data)
        data.companions.forEach(companion => {
          if (companion.user_id === user.sub) {
            setCompanionLobby(true)
            setOrigin(companion.start_name)
            setDestination(companion.end_name)
            setNewTime(companion.departure_time)
            setEstimatedTime(companion.estimated_time)
            setArrivalTime(companion.estimated_time.substr(companion.estimated_time.indexOf('T') + 1, 5))
            setSeats(companion.seats)
            if (companion.price) {
              setPrice(companion.price)
              if (companion.fee) {
                setPrice(companion.price + companion.fee)
              }
            }
          }
        })
      })
    }
  }, [loading])

  const handleTimeChange = () => {
    let timeDifference =
      new Date(lobby.Journey.estimated_time).getTime() -
      new Date(lobby.Journey.departure_time).getTime()
    let newDepartureTime = new Date(lobby.Journey.departure_time)
    newDepartureTime.setHours(time.substr(0, 2))
    newDepartureTime.setMinutes(time.substr(3, 2))
    let newArrivalTime = new Date(
      newDepartureTime.getTime() + timeDifference - 180 * 60 * 1000
    ).toISOString()
    setArrivalTime(newArrivalTime.substr(newArrivalTime.indexOf('T') + 1, 5))
    newDepartureTime = new Date(newDepartureTime.getTime() - 3 * 60 * 60 * 1000)
    setNewTime(newDepartureTime.toISOString())
    setEstimatedTime(newArrivalTime)
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleEdit = () => {
    if (containsContactInfo(comment)) {
      setCommentError(true)
    } else {
      setCommentError(false)
      setEdit(false)
      handleTimeChange()
      setEditAlert(true)
    }
  }

  const handleDelete = () => {
    Mixpanel.track('Boton Cancelar Lobby', {
      'Lobby': lobby
    })
    setDeleteLobby(1)
  }

  const handleLeaveLobby = () => {
    Mixpanel.track('Boton Abandonar Lobby', {
      'Lobby': lobby
    })
    setLeaveLobby(1)
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        isOpen={true}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel='Example Modal'
      >
        {
          loading || (response.ok && Object.keys(lobbyInfo).length == 0) ?
            <div className='h-full flex justify-center items-center'>
              <LoadingIndicator />
            </div>
            : response.ok ?
              <>
                <div className='w-full justify-between flex'>
                  <IconButton onClick={handleClose} style={{ paddingTop: 10, paddingLeft: 22 }} sx={{ color: '#f25c45' }} >
                    <ArrowBackIos />
                  </IconButton>
                  <div className='flex gap-2'>
                    {
                      user.sub === lobby.Owner_info.id && !late &&
                      <IconButton onClick={() => {
                        Mixpanel.track('Boton Editar Lobby', {
                          'Lobby': lobby
                        })
                        setEdit(true)
                      }}
                        disabled={edit}
                        style={{ paddingTop: 10, paddingRight: 22 }}
                        sx={{ color: '#d65944' }}
                      >
                        <EditOutlined sx={{ fontSize: 28 }} />
                      </IconButton>
                    }
                  </div>
                </div>
                <div className='overflow-auto'>
                  <div className='w-full flex flex-col items-center text-2xl pl-6'>
                    <div
                      className='w-full flex gap-4 items-center text-2xl hover:bg-gray-100 cursor-pointer sm:justify-center mt-4'
                      onClick={() => setShowUserInfo(true)}
                    >
                      <Avatar
                        alt={lobby.Owner_info.first_name}
                        src={lobby.Owner_info.picture}
                        sx={{ width: 100, height: 100 }}
                        verified={lobby.Owner_info.verified}
                        badgeSize={30}
                      />
                      <div className='flex flex-col justify-center gap-2'>
                        <div
                          style={{
                            fontSize: 22,
                            color: '#c65944',
                            fontWeight: 'bold',
                            lineHeight: '0'
                          }}
                        >
                          {lobby.Owner_info.first_name}
                          <ChevronRight sx={{ fontSize: 26 }} />
                        </div>
                        {
                          lobbyInfo.Owner_info.reviews > 0 &&
                          <div className='flex flex-row gap-1'>
                            <StarOutlined sx={{ color: '#9ca3af', fontSize: 20 }} />
                            <p style={{
                              fontSize: 14,
                              fontWeight: '500',
                              color: '#9ca3af',
                              lineHeight: '1.8',
                            }}>
                              {lobbyInfo.Owner_info.stars?.toFixed(1)} - {lobbyInfo.Owner_info.reviews} {lobbyInfo.Owner_info.reviews > 1 ? 'opiniones' : 'opinión'}
                            </p>
                          </div>
                        }
                        {
                          lobbyInfo.Owner_info.phone_number &&
                          <div className='flex flex-row gap-1'>
                            <PhoneIphoneRounded sx={{ color: '#9ca3af', fontSize: 20 }} />
                            <p style={{
                              fontSize: 14,
                              fontWeight: '500',
                              color: '#9ca3af',
                              lineHeight: '1.8',
                            }}>{lobbyInfo.Owner_info.phone_number}</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='w-full flex flex-col text-2xl gap-4 mt-10 sm:items-center'>
                    {
                      edit ?
                        <>
                          <div className='flex flex-col gap-7 pl-6'>
                            <Typography variant='h5'>
                              {departureDate}
                            </Typography>
                            <div className='flex flex-col text-lg gap-2 items-center'>
                              <p className='text-primary text-2xl font-thin text-xl'>Horario de salida</p>
                              <TimePicker data={time} setData={setTime} />
                            </div>
                            <div className='flex flex-col text-lg gap-2 items-center'>
                              <p className='text-primary text-2xl font-thin text-xl'>Asientos disponibles</p>
                              <TextField
                                id='selected-seats'
                                select
                                value={seats}
                                onChange={evt => setSeats(evt.target.value)}
                                style={{ width: 90, paddingBottom: -10 }}
                              >
                                {[1, 2, 3, 4].map(x => (
                                  <MenuItem key={x} value={x}>
                                    {x}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div className='flex flex-col text-lg gap-2 items-center'>
                              <p className='text-primary text-2xl font-thin text-xl'>Precio por asiento</p>
                              <Spinner
                                setNumber={setPrice}
                                step={10}
                                number={price}
                                label='Precio'
                                max={
                                  lobbyInfo.recommended_price +
                                  lobbyInfo.recommended_price / 3
                                }
                                min={
                                  lobbyInfo.recommended_price -
                                  lobbyInfo.recommended_price / 2
                                }
                                style={{ marginTop: 8, marginLeft: 40, fontSize: 30 }}
                              />
                            </div>
                            <div className='flex flex-col text-lg gap-2 pr-8'>
                              <p className='text-primary text-2xl font-thin text-xl'>Sobre el viaje</p>
                              <TextField
                                placeholder='Comentario'
                                multiline
                                size='large'
                                value={comment}
                                minRows={3}
                                onChange={evt => {
                                  if (evt.target.value.length >= 100) {
                                    setComment(comment)
                                  } else {
                                    setComment(evt.target.value)
                                  }
                                }}
                              />
                              {commentError && <p className='text-xs text-[#ef4444] px-1'>No puede contener información de contacto</p>}
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className='pl-6'>
                            <Trip
                              start={origin}
                              end={destination}
                              originName={lobby.Journey.origin_name.formatted_address}
                              destinationName={lobby.Journey.destination_name.formatted_address}
                              departureDate={departureDate}
                              departureTime={departureTime}
                              arrivalTime={arrivalTime}
                              dateStyle={{ fontWeight: '500', fontSize: 22 }}
                            />
                          </div>
                          <div className='flex gap-2 text-xl mb-4 mt-4 pl-6'>
                            <People style={{ color: '#d65944', fontSize: 30 }} />
                            <div className='flex flex-col justify-center font-thin font-[1.3rem]'>
                              {
                                companionLobby ?
                                  seats > 1 ?
                                    seats + ' asientos reservados'
                                    :
                                    '1 asiento reservado'
                                  :
                                  seats > 1 ?
                                    seats + ' asientos totales'
                                    :
                                    '1 asiento total'
                              }
                            </div>
                          </div>
                          <div className='flex gap-2 text-1xl mb-2 pl-6'>
                            <p className='text-primary'>{getCurrency(lobby.currency)}</p>
                            <div className='flex flex-row font-thin text-[1.3rem]'>{price}</div>
                            <p className='text-primary font-thin text-[1.3rem]'> por asiento</p>
                          </div>
                          {
                            comment &&
                            <div className='flex flex-col gap-2 text-xl mt-3'>
                              <Divider style={{ borderBottomWidth: 7, borderColor: '#f3f4f6' }} />
                              <p className='mt-3 font-thin pl-6'>Sobre el viaje</p>
                              <p className='mr-5 mb-3 font-thin text-[#9ca3af] pl-6'>{comment}</p>
                              <Divider style={{ borderBottomWidth: 7, borderColor: '#f3f4f6' }} />
                            </div>
                          }
                          <div className='mt-5 pl-6'>
                            <div className='text-2xl mb-3'>Acompañantes:</div>
                            {
                              lobby.companions.length >= 1 ?
                                <div className='ml-4'>
                                  {lobbyInfo.companions.map(companion => (
                                    <div className='mb-2'>
                                      <div
                                        className='w-full flex text-2xl gap-4 py-2 rounded-lg sm:items-center hover:bg-gray-100 cursor-pointer'
                                        onClick={() => {
                                          Mixpanel.track('Companion apretado', {
                                            'Companion': companion
                                          })
                                          setSelectedCompanion(companion)
                                          setShowCompanionInfo(true)
                                        }}
                                      >
                                        <div className='flex gap-2 text-xl'>
                                          <div className='flex gap-1 items-center'>
                                            <Avatar
                                              src={companion.picture}
                                              sx={{ width: 70, height: 70 }}
                                              verified={companion.verified}
                                              badgeSize={20}
                                            />
                                          </div>
                                          <div>
                                            <div className='cursor-pointer flex flex-row gap-2 text-primary'>
                                              {companion.first_name}
                                              <div>-</div>
                                              <People
                                                style={{ color: '#d65944', marginTop: 3 }}
                                                fontSize='medium'
                                              />
                                              <div className='flex flex-col justify-center'>
                                                {companion.seats}
                                              </div>
                                            </div>
                                            {
                                              companion.phone_number &&
                                              <div className='flex gap-1 text-base sm:items-center text-gray-400'>
                                                <PhoneIphoneRounded fontSize='medium' /> {companion.phone_number}
                                              </div>
                                            }
                                            <div className='flex gap-1 text-base sm:items-center text-gray-400'>
                                              {companion.start_name} <ArrowRightAltRounded fontSize='medium' /> {companion.end_name}
                                            </div>
                                            {
                                              lobby.Owner_info.id === user.sub &&
                                              <div className='flex gap-1 text-lg sm:items-center text-gray-400'>
                                                {getCurrency(lobby.currency)} {companion.price}
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                :
                                <div className='bg-gray-100 rounded-lg p-5 mb-4 mr-6 text-sm text-center'>
                                  Aún no se ha sumado ningún acompañante
                                </div>
                            }
                          </div>
                        </>
                    }
                    <div className='flex flex-col pr-6 pl-6 w-full sm:w-[50%]'>
                      {
                        edit &&
                        <Button
                          name={'Guardar'}
                          style={{
                            marginTop: '10px',
                            marginBottom: '6px',
                            zIndex: 300,
                          }}
                          onClick={handleEdit}
                        />
                      }
                    </div>
                    <a
                      className='flex justify-center text-blue-600 text-xl mb-1'
                      style={{ color: '#f25c45' }}
                      href={`https://api.whatsapp.com/send/?phone=+59898415699&text=Hola,%20soy%20parte%20del%20trayecto%20@${lobby._id}%20y%20tengo%20una%20consulta:%20`}
                      target='_blank'
                    >
                      Soporte
                    </a>
                    {
                      !late &&
                      <p
                        className='flex justify-center text-xl mb-5 text-[#ef4444] cursor-pointer'
                        onClick={() => {
                          if (user.sub === lobby.Owner_info.id) handleDelete()
                          else handleLeaveLobby()
                        }}
                      >
                        {user.sub === lobby.Owner_info.id ? 'Cancelar viaje' : 'Abandonar viaje'}
                      </p>
                    }
                  </div>
                </div>
              </>
              :
              <div className='h-full'>
                <div className='w-full flex pt-2'>
                  <IconButton onClick={handleClose} style={{ paddingTop: '0' }} sx={{ color: '#e65944' }} >
                    <ArrowBackIos />
                  </IconButton>
                </div>
                <Divider />
                <div className='flex flex-col text-center pr-6 pl-6 justify-center h-4/5 overflow-auto'>
                  <SearchError />
                </div>
              </div>
        }
      </Modal>
      {
        editAlert &&
        <EditLobby
          visible={editAlert}
          setVisible={setEditAlert}
          data={{ newTime, price, seats, estimatedTime, comment }}
          lobby={lobby}
        />
      }
      {
        leaveLobby != 0 &&
        <>
          {
            leaveLobby == 1 &&
            <LeaveLobbyReasonScreen handleSubmit={() => setLeaveLobby(2)} selectedReason={leaveSelectedReason} setSelectedReason={setLeaveSelectedReason} onBack={() => setLeaveLobby(0)} />
          }
          {
            leaveLobby == 2 &&
            <LeaveLobbyScreen setLeaveLobbyAlert={setLeaveLobby} leaveLobbyAlert={leaveLobby} setLeaveLobby={setLeaveAlert} leaveLobby={leaveAlert} free={lobbyInfo?.currency !== 'UYU' || lobbyInfo?.price === 0} />
          }
        </>
      }
      {
        deleteLobby != 0 &&
          lobby.companions.length > 0 ?
          <>
            {
              deleteLobby == 1 &&
              <DeleteLobbyReasonScreen handleSubmit={() => setDeleteLobby(2)} selectedReason={deleteSelectedReason} setSelectedReason={setDeleteSelectedReason} onBack={() => setDeleteLobby(0)} />
            }
            {
              deleteLobby == 2 &&
              <DeleteLobbyScreen setDeleteLobbyAlert={setDeleteLobby} deleteLobbyAlert={deleteLobby} setDeleteLobby={setDeleteAlert} deleteLobby={deleteAlert} free={lobbyInfo?.currency !== 'UYU' || lobbyInfo?.price === 0} />
            }
          </>
          :
          <Alert
            visible={deleteLobby}
            setVisible={setDeleteLobby}
            onSubmit={() => setDeleteAlert(true)}
            onClose={() => setDeleteLobby(0)}
          >
            <p className='text-center text-xl'>
              ¿Estás seguro de que quieres cancelar este viaje?
            </p>
          </Alert>
      }
      {
        leaveAlert &&
        <LeaveLobby
          visible={leaveAlert}
          setVisible={setLeaveAlert}
          id={lobby._id}
          closeLobbyInfo={() => setVisible(false)}
          setLobbies={setLobbies}
          lobbies={lobbies}
          setLeaveLobby={setLeaveAlert}
          reason={leaveSelectedReason}
        />
      }
      {
        deleteAlert &&
        <DeleteLobby
          visible={deleteAlert}
          setVisible={setDeleteAlert}
          id={lobby._id}
          closeLobbyInfo={() => setVisible(false)}
          setLobbies={setLobbies}
          lobbies={lobbies}
          reason={deleteSelectedReason}
        />
      }
      {
        showUserInfo &&
        <UserInfo
          user={lobby.Owner_info.id}
          onClose={() => setShowUserInfo(false)}
          visible={showUserInfo}
        />
      }
      {
        showCompanionInfo &&
        <UserInfo
          user={selectedCompanion.user_id}
          onClose={() => setShowCompanionInfo(false)}
          visible={showCompanionInfo}
        />
      }
    </ThemeProvider >
  )
}

export default LobbyInfo
