import { uri } from '../../../../config/Api'
import { useAuthorizedFetch } from '../../../../hooks'
import { useEffect, useState } from 'react'
import { Mixpanel } from '../../../../Mixpanel'
import Alert from '../../../../commons/Alert'

const LeaveLobby = ({ id, closeLobbyInfo, setLobbies, lobbies, setLeaveLobby, reason }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/lobbies/${id}/leave`, {
    method: 'DELETE',
    body: JSON.stringify({
      reason: reason
    })
  })
  const [errorAlert, setErrorAlert] = useState(false)

  useEffect(() => {
    if (response.ok) {
      closeLobbyInfo()
      Mixpanel.track('Lobby Abandonada con exito', {
        'LobbyId': id,
        'Motivo': reason
      })
      setLobbies(lobbies.filter(lobby => lobby._id != id))
    } else if (response.ok == false) {
      setErrorAlert(true)
      Mixpanel.track('Error al Abandonar lobby', {
        'LobbyId': id,
        'Motivo': reason
      })
    }
  }, [loading])

  return (
    <Alert
      visible={errorAlert}
      onClose={() => {
        setErrorAlert(false)
        setLeaveLobby(false)
      }}
    >
      <p>Ha ocurrido un error, porfavor revise su conexión e intenta nuevamente y si el error persiste contáctate con nosotros</p>
    </Alert>
  )
}

export default LeaveLobby  