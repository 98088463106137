const styles = {
  modal: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    overlay: {
      zIndex: 200,
      backgroundColor: 'rgba(0,0,0,0)',
    }
  },
  selectorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  seatsButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    paddingBottom: 30,
    paddingTop: 30,
    borderTopColor: '#f3f4f6',
    borderTopWidth: 2,
    backgroundColor: '#fff',
  },
}

export default styles