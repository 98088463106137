import { TextField } from '@mui/material'

const EditProfileAboutMe = ({ edit, age, gender, aboutMe, setAboutMe, biographyError }) => {

  return (
    <div className='flex flex-col text-lg gap-2'>
      <p className='text-primary text-2xl font-thin text-xl'>Sobre mí</p>
      <TextField
        placeholder={
          edit ?
            !age ?
              'Ejemplo: Trabajo en una empresa de tecnología, me gusta el rock y el fútbol'
              :
              age < 25 ?
                'Ejemplo: Soy estudiante de medicina, me gusta el fútbol y viajar'
                : age >= 25 && age < 40 ?
                  'Ejemplo: Trabajo en una empresa de tecnología, me gusta el rock y el fútbol'
                  : gender == 'Hombre' ?
                    'Ejemplo: Soy padre de familia, viajo para trabajar y visitar a mis hijos'
                    :
                    'Ejemplo: Trabajo en una empresa de tecnología, viajo para visitar a mi familia'
            :
            'Acerca de mí'
        }
        multiline
        size='large'
        value={aboutMe}
        minRows={3}
        onChange={evt => {
          if (evt.target.value.length >= 180) {
            setAboutMe(aboutMe)
          } else {
            setAboutMe(evt.target.value)
          }
        }}
      />
      {biographyError && <p className='text-xs text-[#ef4444] px-2'>No puede contener información de contacto</p>}
    </div>
  )
}

export default EditProfileAboutMe
