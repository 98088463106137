import { IconButton } from '@mui/material'
import Avatar from '../../commons/Avatar'
import GroupImage from '../GroupImage'
import { ArrowBackIos, ArrowForwardIosRounded } from '@mui/icons-material'
import { Mixpanel } from '../../Mixpanel'

export default ({
  selectedChannel,
  setSelectedChannel,
  setVisible,
  embedded,
  userId,
  device,
  setUserInfo
}) => {
  return (
    <>
      {device == 'mobile' ? (
        <MobileHeader
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          setVisible={setVisible}
          embedded={embedded}
          userId={userId}
          setUserInfo={setUserInfo}
        />
      ) : (
        <DesktopHeader
          selectedChannel={selectedChannel}
          setVisible={setVisible}
          embedded={embedded}
          userId={userId}
          setUserInfo={setUserInfo}
        />
      )}
    </>
  )
}

const MobileHeader = ({
  selectedChannel,
  setSelectedChannel,
  setVisible,
  embedded,
  userId,
  setUserInfo
}) => {
  const directMessage = selectedChannel?.users.find(user => user.user_id != userId)

  const openUserInfo = () => {
    if (selectedChannel.type === 'lobbygroup') return
    Mixpanel.track('Click usuario en chat', {
      Usuario: directMessage
    })
    if (embedded) {
      window.ReactNativeWebView?.postMessage(`user_info_${directMessage.user_id}`)
    } else {
      setUserInfo(directMessage.user_id)
      window.history.pushState({}, '', `/user/${directMessage.user_id}` + window.location.search)
    }
  }

  return (
    <div
      className={`flex flex-row gap-2 items-center w-full p-3 min-h-[4.5rem] ${
        embedded && 'min-h-fit'
      }`}
    >
      {(!embedded || selectedChannel) && (
        <BackButton
          setVisible={setVisible}
          setSelectedChannel={setSelectedChannel}
          selectedChannel={selectedChannel}
        />
      )}
      {selectedChannel ? (
        <div onClick={openUserInfo} className='flex items-center gap-3 cursor-pointer'>
          {selectedChannel.type !== 'lobbygroup' ? (
            <Avatar
              sx={{ width: 50, height: 50 }}
              src={directMessage.picture}
              alt={directMessage.name?.[0]}
            />
          ) : (
            <GroupImage
              images={selectedChannel.users.filter(user => user.active).map(user => user.picture)}
            />
          )}
          <div className='flex flex-col gap-1'>
            <p className='text-base text-primary'>{selectedChannel.name ?? directMessage.name}</p>
            {selectedChannel.type === 'direct-message' && (
              <div className='flex flex-row gap-1 items-center'>
                <p className='text-xs text-primary'>Ver perfil</p>
                <ArrowForwardIosRounded sx={{ color: '#d65944', fontSize: 10 }} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <p className={`text-primary text-xl font-bold ${embedded && 'pl-3'}`}>Mensajes</p>
      )}
    </div>
  )
}

const DesktopHeader = ({ setVisible, selectedChannel, embedded, userId, setUserInfo }) => {
  const directMessage = selectedChannel?.users.find(user => user.user_id != userId)

  const openUserInfo = () => {
    if (selectedChannel.type === 'lobbygroup') return
    Mixpanel.track('Click usuario en chat', {
      Usuario: directMessage
    })
    if (embedded) {
      window.ReactNativeWebView?.postMessage(`user_info_${directMessage.user_id}`)
    } else {
      setUserInfo(directMessage.user_id)
      window.history.pushState({}, '', `/user/${directMessage.user_id}` + window.location.search)
    }
  }

  return (
    <div className='flex flex-row items-center justify-between w-full p-3 min-h-[4.6rem]'>
      <div className='flex flex-row gap-4 w-1/3'>
        {!embedded && <BackButton setVisible={setVisible} />}
        <p className='font-bold text-xl text-primary'>Mensajes</p>
      </div>
      {selectedChannel && (
        <div className='flex items-center gap-4 w-2/3 cursor-pointer' onClick={openUserInfo}>
          {selectedChannel.type !== 'lobbygroup' ? (
            <Avatar
              sx={{ width: 50, height: 50 }}
              src={directMessage.picture}
              alt={directMessage.name?.[0]}
            />
          ) : (
            <GroupImage
              images={selectedChannel.users.filter(user => user.active).map(user => user.picture)}
            />
          )}
          <div className='flex flex-col gap-1'>
            <p className='text-lg text-primary'>{selectedChannel.name ?? directMessage.name}</p>
            {selectedChannel.type === 'direct-message' && (
              <div className='flex flex-row gap-1 items-center'>
                <p className='text-sm text-primary'>Ver perfil</p>
                <ArrowForwardIosRounded sx={{ color: '#d65944', fontSize: 12 }} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const BackButton = ({ setVisible, setSelectedChannel, selectedChannel }) => {
  return (
    <IconButton
      onClick={() => {
        if (selectedChannel) {
          window.history.pushState({}, '', '/messages' + window.location.search)
          setSelectedChannel(null)
        } else {
          window.history.pushState({}, '', '/')
          setVisible(false)
        }
      }}
      sx={{ color: '#d65944', paddingTop: 0, paddingBottom: 0 }}
    >
      <ArrowBackIos />
    </IconButton>
  )
}
