import { faComments } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default () => {
  return (
    <div className='flex flex-col items-center justify-center bg-light_gray w-full rounded-tl-xl gap-4'>
      <FontAwesomeIcon icon={faComments} size='8x' className='text-gray' />
      <p className='text-center text-gray text-base'>Seleccioná un chat para empezar a hablar</p>
    </div>
  )
}