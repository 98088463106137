import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/'
import { useAuthorizedFetch } from '../../hooks'
import Modal from 'react-modal'
import LobbyCard from './LobbyCard'
import { uri } from '../../config/Api'
import LobbyInfo from './LobbyInfo'
import SearchError from '../../commons/SearchError'
import { IconButton } from '@mui/material'
import { Mixpanel } from '../../Mixpanel'
import { ArrowBackIosNew } from '@mui/icons-material'
import LoadingIndicator from '../../commons/LoadingIndicator'

const UserLobbies = ({ visible, setVisible, initialLobby }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/users/me/lobbies`)
  const { user } = useAuth()
  const [lobbies, setLobbies] = useState([])
  const [selectedLobby, setSelectedLobby] = useState({})
  const [lobbyInfoVisible, setLobbyInfoVisible] = useState(false)
  const today = (new Date((new Date()).getTime() - 3 * 60 * 60 * 1000)).toISOString()

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setLobbies(data.filter(lobby => today < lobby.Journey.estimated_time || lobby.companions.length > 0).reverse())
        Mixpanel.track('Lobbies de Usuario', {
          'Usuario': user,
          'Cantidad Lobbies': data.length
        })
        if (initialLobby) {
          const lobby = data.find(lobby => lobby._id == initialLobby || lobby._old_id == initialLobby)
          if (lobby) {
            setSelectedLobby(lobby)
            setLobbyInfoVisible(true)
          }
        }
      })
    }
  }, [loading])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingRight: '1.6rem',
      paddingLeft: 0,
      paddingBottom: 0,
      paddingTop: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 0,
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  }

  return (
    <>
      <Modal
        isOpen={visible}
        style={customStyles}
      >
        <div className='flex flex-row justify-between items-center pt-2 pl-2'>
          <IconButton
            onClick={() => {
              window.history.pushState({}, '', '/')
              setVisible(false)
            }}
            sx={{ color: '#f25c45', paddingTop: 0, paddingBottom: 0 }} >
            <ArrowBackIosNew />
          </IconButton>
          <div className='flex text-2xl text-primary'>Mis Viajes</div>
          <div className='w-8' />
        </div>
        <>
          {
            loading ?
              <div className='h-full flex justify-center items-center'>
                <LoadingIndicator />
              </div>
              :
              response.ok ?
                <>
                  <div className='h-full overflow-auto pl-[1.6rem]'>
                    {
                      lobbies.length === 0 ?
                        <div className='flex flex-col items-center justify-center h-full'>
                          <img src={require('../../assets/img/imagen_lobbies.png')} alt='empty' className='w-80' />
                          <p className='text-center text-gray-500 text-lg'>Empezá a viajar y verás tus viajes aquí</p>
                        </div>
                        :
                        <>
                          {
                            lobbies?.map(lobby => (
                              <LobbyCard
                                user={user.sub}
                                lobby={lobby}
                                setSelected={setSelectedLobby}
                                setVisible={setLobbyInfoVisible}
                              />
                            ))
                          }
                        </>
                    }
                  </div>
                </>
                :
                <SearchError />
          }
        </>
      </Modal>
      {
        lobbyInfoVisible ?
          <LobbyInfo
            setVisible={setLobbyInfoVisible}
            setLobbies={setLobbies}
            lobbies={lobbies}
            lobby={selectedLobby}
          />
          :
          <></>
      }
    </>
  )
}

export default UserLobbies
