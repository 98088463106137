import { IconButton } from '@mui/material'
import Company from './Company'
import { ArrowBackIosNew } from '@mui/icons-material'
import Trip from '../../components/Trip'
import { DIAS, MESES } from '../../config/App'
import { toDate } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import BusTicket from './BusTicket'


const ViewMode = ({ bus, setSelectedBus }) => {
  const departureDateTime = toDate(bus.Journey.departure_time)
  const departureDate = DIAS[departureDateTime.getDay()] + departureDateTime.getDate() + ' de ' + MESES[departureDateTime.getMonth()]
  const departureTime = bus.Journey.departure_time?.substr(bus.Journey.departure_time?.indexOf('T') + 1, 5)
  const arrivalTime = bus.Journey.arrival_time?.substr(bus.Journey.arrival_time?.indexOf('T') + 1, 5)
  const supportUrl = `https://api.whatsapp.com/send/?phone=+59896001717&text=Hola, tengo una consulta sobre el viaje ${bus.Journey.origin} - ${bus.Journey.destination} del ${departureDate} a las ${departureTime}hs`

  return (
    <>
      <div>
        <IconButton onClick={() => setSelectedBus(false)} style={{ paddingTop: 10, paddingLeft: 10 }} sx={{ color: '#f25c45' }} >
          <ArrowBackIosNew />
        </IconButton>
      </div>
      <div className='h-full overflow-auto flex-col flex justify-between'>
        <div className='w-full flex flex-col mt-4 text-2xl gap-4 sm:items-center'>
          <Company bus={bus} />
          <div className='pl-5 tall:pl-7'>
            <Trip
              start={bus.Journey.origin}
              end={bus.Journey.destination}
              originName={bus.Journey.origin}
              destinationName={bus.Journey.destination}
              departureDate={departureDate}
              departureTime={departureTime}
              arrivalTime={arrivalTime}
              dateStyle={{ fontWeight: '500', fontSize: 22, marginBottom: 5 }}
            />
          </div>
          <div className='flex flex-row gap-3 mb-8 px-5 tall:px-7'>
            <FontAwesomeIcon icon={faBus} className='text-primary text-2xl' />
            <p className='text-xl font-bold'>Servicio {bus.service}</p>
          </div>
          {
            bus.passengers.map((passenger, index) => (
              <BusTicket passenger={passenger} boardingStation={bus.Journey.boarding_station.name} key={index} />
            ))
          }
        </div>
        <div className='w-full mt-2 flex flex-col items-center gap-0' >
          <a
            className='text-xl mb-4 mt-3'
            style={{ color: '#f25c54' }}
            target='_blank'
            href={supportUrl}
          >
            Soporte
          </a>
        </div>
      </div>
    </>

  )
}

export default ViewMode