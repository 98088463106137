import { uri } from '../../config/Api'
import { useAuthorizedFetch } from '../../hooks'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Divider } from '@mui/material'
import { ArrowForwardIosRounded, ChevronRight, Lock } from '@mui/icons-material'
import Trip from '../Trip'
import Button from '../../commons/Button'
import SearchError from '../../commons/SearchError'
import PaySafeScreen from '../PaySafeScreen'
import { Mixpanel } from '../../Mixpanel'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { getCurrency, replaceAll } from '../../utils'
import styles from './styles'
import Icon from '@mdi/react'
import { mdiBusStop } from '@mdi/js'
import PriceDetails from './PriceDetails'

const VerifyBus = ({ passengerDetails, bus, transaction, departureDate }) => {
  const [data, setData] = useState()
  const [totalPrice, setTotalPrice] = useState()
  const [priceWithoutDiscount, setPriceWithoutDiscount] = useState()
  const [paySafeScreen, setPaySafeScreen] = useState(false)
  const [priceDetailsScreen, setPriceDetailsScreen] = useState(false)
  const [seats, setSeats] = useState([])

  const { loading, response } = useAuthorizedFetch(`${uri}/buses/request-payment/${transaction}`, {
    method: 'POST',
    body: JSON.stringify({
      ...passengerDetails,
      back_url: `${window.location.origin}/?navigation=BusVerifyPayment&company=${replaceAll(bus.Company_info.name, ' ', '')}&transaction_id=${transaction}`,
    })
  })

  const handlePress = () => {
    Mixpanel.track('Botón VerifyBus', {
      'Bus Id': bus.id,
      'Transaction': transaction,
      'Name': passengerDetails.full_name,
      'Id Number': passengerDetails.id_number,
      'Boarding Station': passengerDetails.boarding_station,
      'Passengers': data.seats.length,
      'Seats': data.seats,
      'Price': totalPrice,
    })
    window.location.href = data.payment_url
  }

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setData(data)
        setTotalPrice(data.ticket_price + data.boarding_fee + data.fee - data.discount)
        setPriceWithoutDiscount(data.ticket_price + data.boarding_fee + data.fee)
        setSeats(data.seats)
      })
    } else if (response.ok === false) {
      setData('Request error')
    }
  }, [loading])

  return (
    <>
      {
        loading || !data ?
          <div className='flex flex-col items-center justify-center h-full'>
            <LoadingIndicator />
          </div>
          :
          response.ok && data ?
            <div className='h-full overflow-auto flex-col flex justify-between'>
              <div className='w-full flex flex-col mt-4 text-xl gap-4 sm:items-center'>
                <p className='text-3xl pl-7 mb-3 pr-5'>Verifique los datos del viaje</p>
                <div className='pl-5 narrow:pl-7'>
                  <Trip
                    dateStyle={{ marginBottom: 15 }}
                    start={bus.Journey.origin}
                    end={bus.Journey.destination}
                    originName={bus.Journey.origin}
                    destinationName={bus.Journey.destination}
                    departureDate={departureDate}
                    departureTime={''}
                    arrivalTime={''}
                    noTime
                  />
                </div>
                <div className='flex flex-row gap-3 items-center mb-5 px-5 narrow:px-7'>
                  <Icon path={mdiBusStop} color='#d65944' size={1.5} />
                  <p className='text-lg'>{passengerDetails.boarding_station.name}</p>
                </div>
                <Divider style={{ borderBottomWidth: 7, borderColor: '#f3f4f6' }} />
                <div className='flex flex-row justify-between gap-5 text-sm narrow:text-base my-2 px-5 font-thin narrow:px-7'>
                  <div className='flex flex-row gap-5'>
                    <p className='text-primary'>{data.seats.length > 1 ? 'Asientos: ' : 'Asiento: '}</p>
                    <p>{seats.join(' - ')}</p>
                  </div>
                </div>
                <Divider style={{ borderBottomWidth: 7, borderColor: '#f3f4f6' }} />
                <div
                  className='flex flex-row justify-between gap-5 text-sm narrow:text-base my-2 px-5 font-thin narrow:px-7 cursor-pointer'
                  onClick={() => {
                    Mixpanel.track('BusPriceDetails')
                    setPriceDetailsScreen(true)
                  }}>
                  <p className='text-primary'>Precio total: </p>
                  <div className='flex flex-row gap-2'>
                    <p>{getCurrency(bus.currency)} {totalPrice}</p>
                    {data.discount > 0 && <p className='text-sm text-[#9ca3af] line-through'>{getCurrency(bus.currency)} {priceWithoutDiscount}</p>}
                  </div>
                  <ArrowForwardIosRounded className='text-primary' />
                </div>
                <Divider style={{ borderBottomWidth: 7, borderColor: '#f3f4f6' }} />
              </div>
              <div className='flex flex-col pr-6 pl-6 items-center mb-5 mt-5'>
                <div
                  className='flex flex-row gap-1 text-base text-[#9ca3af] mb-3 cursor-pointer'
                  onClick={() => {
                    Mixpanel.track('BusPaySafe')
                    setPaySafeScreen(true)
                  }}>
                  <Lock />
                  <p>Pago seguro</p>
                  <ChevronRight />
                </div>
                <Button
                  name={'Ir a pagar'}
                  onClick={handlePress}
                />
              </div>
            </div>
            :
            <SearchError />
      }
      <Modal isOpen={paySafeScreen} style={styles.modal}>
        <PaySafeScreen setVisible={setPaySafeScreen} type='bus' />
      </Modal>
      <Modal isOpen={priceDetailsScreen} style={styles.modal}>
        <PriceDetails data={data} setVisible={setPriceDetailsScreen} />
      </Modal>
    </>
  )
}

export default VerifyBus