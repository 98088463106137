import theme from './theme'
import { ThemeProvider, Radio, IconButton, Divider } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import NextButton from './NextButton'

const PaymentMethod = ({ setPaymentMethod, paymentMethod, onBack, handleSubmit }) => {
  const paymentMethods = [
    { name: 'BROU', key: 'brou' },
    { name: 'Midinero', key: 'midinero' },
    { name: 'Itau', key: 'itau' },
    { name: 'Santander', key: 'santander' },
    { name: 'Prex', key: 'prex' },
    { name: 'Otro banco', key: 'other' }
  ]

  const isSafari = () => {
    const userAgent = window.navigator.userAgent
    return /Safari/.test(userAgent) && !/Chrome/.test(userAgent) && /iPhone/.test(userAgent)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='flex flex-col h-[92vh] overflow-auto px-6 text-2xl pt-6 text-xl text-start'>
        <div>
          <div className='text-2xl text-start mb-6'>Pago seguro</div>
          <div className='text-base text-gray '>
            Elige de que forma te enviaremos tu dinero una vez terminado el viaje
          </div>
        </div>
        <div style={{ height: `calc(${isSafari() ? '89vh' : '100vh'} - 210px)` }}>
          <div className='tall:flex tall:flex-col tall:gap-5 mt-10'>
            {paymentMethods.map(x => (
              <>
                <div
                  onClick={() => setPaymentMethod(x.key)}
                  className='flex flex-row mt-5 mb-5 items-center cursor-pointer tall:mb-0 tall:mt-0 ml-[-10px]'
                >
                  <Radio
                    checked={paymentMethod == x.key}
                    key={x.key}
                    value={x.key}
                    sx={{ '&.Mui-checked': { color: '#f25c45' } }}
                    size='small'
                  />
                  <p className='text-base'>{x.name}</p>
                </div>
                <Divider />
              </>
            ))}
          </div>
        </div>
        <NextButton onSubmit={handleSubmit} disabled={paymentMethod == null} />
      </div>
    </ThemeProvider>
  )
}

export default PaymentMethod
