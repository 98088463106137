import { Divider, TextField } from '@mui/material'
import Button from '../../commons/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import ValidationError from './ValidationError'
import { Mixpanel } from '../../Mixpanel'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'

const FirstStep = ({ initialValues, name, lastName, phoneNumber, setName, setLastName, setPhoneNumber, onSubmit }) => {
  const schema = yup.object({
    name: yup.string().required('*Requerido').max(20, 'Nombre muy largo'),
    last_name: yup.string().required('*Requerido').max(20, 'Apellido muy largo'),
    phone_number: yup.string().required('*Requerido').test('phone_number', 'Número inválido', () => {
      return isValidPhoneNumber(phoneNumber)
    }),
  })

  return (
    <div className='h-full flex flex-col'>
      <div className='text-center text-xl'>
        <div className='text-1xl text-center pr-4'>
          Necesitamos los siguientes datos para continuar
        </div>
        <div className='text-1xl text-center font-bold pr-4'>1/3</div>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {form => (
            <form onSubmit={form.handleSubmit} onChange={form.handleChange}>
              <div className='flex flex-col mt-2 pr-4'>
                <p>Nombre</p>
                <TextField
                  value={name}
                  placeholder='Ej: Juan'
                  name='name'
                  size='small'
                  onChange={evt => setName(evt.target.value)}
                />
                <ValidationError name='name' />
              </div>
              <div className='flex flex-col mt-2 pr-4'>
                <p>Apellido</p>
                <TextField
                  value={lastName}
                  placeholder='Ej: Perez'
                  name='last_name'
                  size='small'
                  onChange={evt => setLastName(evt.target.value)}
                />
                <ValidationError name='last_name' />
              </div>
              <div className='flex flex-col mt-2 pr-4'>
                <p>Celular</p>
                <PhoneInput
                  international
                  placeholder='Teléfono'
                  onChange={setPhoneNumber}
                  value={phoneNumber}
                  defaultCountry='UY'
                  name='phone_number'
                  className='border border-gray-300 rounded-md w-[100%] p-1'
                />
                <ValidationError name='phone_number' />
              </div>
              <div className='flex flex-col mt-10 pr-4'>
                <Divider />
                <Button
                  id='submit'
                  type='submit'
                  name='Siguiente'
                  onClick={() =>
                    Mixpanel.track('Boton registro 1er paso', {
                      'Nombre': name,
                      'Apellido': lastName,
                      'Celular': phoneNumber,
                    })
                  }
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    zIndex: 300,
                  }}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default FirstStep
