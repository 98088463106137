import { Mixpanel } from "../../../Mixpanel"



const handleEdit = (edit, setEdited, setEdit, firstName, user, picture, aboutMe, preferences) => {
  if (edit) {
    Mixpanel.track('Boton Guardar Perfil', {
      'Nombre': firstName,
      'Imagen': picture,
      'Biografia': aboutMe,
      'Preferencias': preferences,
      'Usuario': user
    })
    setEdited(true)
  }
  setEdit(false)
}

export default handleEdit
