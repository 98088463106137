import { ArrowBackIosNew } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export default ({ handleBack }) => {
  return (
    <div className='flex'>
      <IconButton onClick={handleBack} sx={{ color: '#d65944', paddingLeft: 3 }}>
        <ArrowBackIosNew />
      </IconButton>
    </div>
  )
}
