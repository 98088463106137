const modalStyles = {
  content: {
    margin: 'auto',
    padding: 30,
    height: 320,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 10,
    border: 'none',
    overflow: 'hidden',
    backgroundColor: '#fff'
  },
  overlay: {
    zIndex: 150,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
}

export default modalStyles
