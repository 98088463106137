import { TextField } from '@mui/material'


const EditProfileName = ({ firstName, setFirstName, lastName, setLastName, edit }) => {
  return (
    <div className='flex flex-col mt-5 gap-5'>
      <div className='flex flex-col text-lg gap-2'>
        <p className='text-primary text-2xl font-thin text-xl'>Nombre</p>
        <TextField
          placeholder='Nombre'
          size='small'
          value={firstName}
          disabled={!edit}
          onChange={e => setFirstName(e.target.value)}
        />
      </div>
      <div className='flex flex-col text-lg gap-2'>
        <p className='text-primary text-2xl font-thin text-xl'>Apellido</p>
        <TextField
          placeholder='Apellido'
          size='small'
          value={lastName}
          disabled={!edit}
          onChange={e => setLastName(e.target.value)}
        />
      </div>
    </div>
  )
}


export default EditProfileName
